import ReactQuill from "react-quill"
import React from "react"

export const SimpleRichText = ({ value, handleChange, ...rest }: any) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  }

  const formats = ["bold", "italic", "underline", "strike", "list", "bullet"]

  return (
    <ReactQuill
      value={value} // Bind value to the editor
      onChange={handleChange} // Call handleChange when content changes
      modules={modules}
      formats={formats}
      {...rest}
    />
  )
}
