import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ApiResponse } from "../../../common/types/ApiResponse";
import httpRequest from "../../../config/config";
import { message } from "antd";

export enum ValueParams {
  ALL = "all",
  COURSES = "courses",
  WORKSHOPS = "workshops",
  PROGRAMS = "programs",
  EMPTY = "",
}

export enum KeyParams {
  CONTENT = "content",
  EXPAND = "expand",
}

export type Analytic = {
  enrolled: {
    course: number;
    program: number;
    total: number;
    unique: number;
    workshop: number;
  };
  learner_activities: {
    completed: number;
    in_progress: number;
    not_started: number;
    total: number;
  };
  learner_by_countries?: {
    name: string;
    total: number;
  }[];
  learner_feelings: {
    emoticon: string;
    name: string;
    response: number;
  }[];
  nps: Nps;
  details?: {
    courses?: {
      enrolled_learners: number;
      green_skill_id?: string;
      id: string;
      nps: Nps;
      response_rate: string;
      responses: number;
      title: string;
    }[];
    program_surveys?: {
      post_learning: number;
      pre_learning: number;
      response_post_learning: number;
      response_pre_learning: number;
      total_survey: number;
    };
    programs?: {
      enrolled_learners: number;
      program_live_id?: string;
      id: string;
      nps: Nps;
      response_rate: string;
      responses: number;
      title: string;
    }[];
    workshops?: {
      enrolled_learners: number;
      id: string;
      nps: Nps;
      response_rate: string;
      responses: number;
      title: string;
    }[];
    type: string;
  };
};

type Nps = {
  avg_rating_feel: number;
  avg_rating_score: number;
  points: number;
  score: {
    ercentage_of_detractors: string;
    percentage_of_passives: string;
    percentage_of_promoters: string;
    score: number;
    status: string;
    total_detractors: number;
    total_passives: number;
    total_promoters: number;
  };
  status: string;
  total_responded: number;
};

export const useAnalytic = (content = ValueParams.ALL, expand = "") => {
  const [params, setParams] = useState({
    content: content,
    expand: expand,
  });

  const handleChangeParams = (key: KeyParams, value: ValueParams) => {
    setParams((prev) => ({ ...prev, [key]: value }));
  };

  const { data, isLoading } = useQuery<ApiResponse<Analytic>>(
    ["analityc-admin", params],
    () => httpRequest.get("/school/dashboard/content-analytics", { params }),
    {
      retry: 3,
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message ||
            `An error occurred while fetching analytic}`
        );
      },
    }
  );

  const [emot, setEmot] = useState<
    | {
        emoticon: string;
        name: string;
        response: number;
      }
    | undefined
  >(undefined);

  const [country, setCountry] = useState<{
    country?: { name: string; total: number }[];
    other_country: number;
    total: number;
  }>({ country: undefined, other_country: 0, total: 0 });

  const [listCourse, setLisCourse] = useState<
    | {
        enrolled_learners: number;
        id: string;
        nps: Nps;
        response_rate: string;
        responses: number;
        title: string;
      }[]
    | undefined
  >(undefined);

  useEffect(() => {
    if (data?.data?.data) {
      setEmot(helperEmot(data?.data?.data.learner_feelings));
      if (data?.data?.data.learner_by_countries) {
        let tempCountry:
          | {
              name: string;
              total: number;
            }[]
          | undefined;
        let tempOther = 0;
        let total = 0;
        const temp = data?.data?.data.learner_by_countries.sort(
          (a, b) => b.total - a.total
        );
        for (let index = 0; index < temp.length; index++) {
          if (index < 3) {
            if (tempCountry) {
              tempCountry.push(temp[index]);
            } else {
              tempCountry = [temp[index]];
            }
          } else {
            tempOther += temp[index].total;
          }
          total += temp[index].total;
        }
        setCountry((prev) => ({
          country: tempCountry,
          other_country: tempOther,
          total: total,
        }));
      }
      if (data?.data?.data?.details) {
        const a = data?.data?.data?.details;
        let tempList:
          | {
              enrolled_learners: number;
              id: string;
              nps: Nps;
              response_rate: string;
              responses: number;
              title: string;
            }[]
          | undefined;

        if (a.courses) {
          for (let index = 0; index < a.courses.length; index++) {
            if (tempList) {
              tempList.push(a.courses[index]);
            } else {
              tempList = [a.courses[index]];
            }
          }
        }
        if (a.programs) {
          for (let index = 0; index < a.programs.length; index++) {
            if (tempList) {
              tempList.push(a.programs[index]);
            } else {
              tempList = [a.programs[index]];
            }
          }
        }
        if (a.workshops) {
          for (let index = 0; index < a.workshops.length; index++) {
            if (tempList) {
              tempList.push(a.workshops[index]);
            } else {
              tempList = [a.workshops[index]];
            }
          }
        }
        setLisCourse(tempList);
      }
    }
  }, [data?.data?.data]);

  return {
    data: data?.data?.data,
    isLoading,
    handleChangeParams,
    params,
    emot,
    country,
    listCourse,
  };
};

const helperEmot = (
  data: {
    emoticon: string;
    name: string;
    response: number;
  }[]
) => {
  let a:
    | {
        emoticon: string;
        name: string;
        response: number;
      }
    | undefined;

  for (let index = 0; index < data.length; index++) {
    if (a) {
      if (a.response < data[index].response) {
        a = data[index];
      }
    } else {
      a = data[index];
    }
  }
  return a;
};
