import React from 'react'
import { CalenderIcon } from '../../../assets/icon'
import { Tag } from 'antd'
import { RichContainerEditor } from '../components/ProgramCourseEditor/RichEditorCustom'
import { initialEditorCourseProgramStoreState, initialFormCourseProgramStoreState } from '../stores/storeProgramCourseEditor'
import CurriculumCard from '../components/CurriculumCard'

import { useHookProgramCourseForm } from '../hooks/useProgramCourse'
import dayjs from 'dayjs'

interface OverviewEditorProps {

}

const OverviewEditor: React.FC<OverviewEditorProps> = () => {

  const { programDetailData } = initialEditorCourseProgramStoreState()

  const { handleChangeOverview } = useHookProgramCourseForm()

  const { formData } = initialFormCourseProgramStoreState()

  return (
    <div className='p-4 bg-white space-y-7'>
      <Tag className='bg-secondaryYellow !text-brown px-2 py-1  border-none'>
        <div className='flex items-center gap-x-3'>
          <CalenderIcon className='!text-brown' />
          <p>Release Date: {dayjs(programDetailData?.release_date).format("DD MMM YYYY")}</p>
        </div>
      </Tag>

      <div className=''>
        <RichContainerEditor
          title="Description"
          placeholder="Write the description"
          showError={true}
          value={formData?.description}
          onChange={handleChangeOverview}
        />
      </div>

      <div className='py-2 px-4 bg-secondaryBlue shadow-custom-sm border rounded-xl'>
        <h3 className='font-extrabold' style={{ fontWeight: 1900 }}>Curriculum</h3>
      </div>

      <div>
        <div className='my-4' >
          <CurriculumCard course={programDetailData!} />
        </div>
      </div>
    </div>
  )
}

export default OverviewEditor
