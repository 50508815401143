import React from "react";
import { Layout, Dropdown, Tag, Drawer, Image } from "antd";
import { CloseOutlined } from "@ant-design/icons";
// import { useHeader } from "../hooks/useCustomHeader";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/skilledin3.png";
import avatar from "../../assets/images/avatar_1.png";
import { useUser } from "../../modules/onboarding/hooks/useUser";
import { useUpdateStage } from "../hooks/useUpdateStage";
import Profile from "../components/Profile";
import { useSchool } from "../../modules/onboarding/hooks/useSchool";

function CustomHeader({ isOnBoarding }) {
  const { user } = useUser();
  const { state: stage } = useUpdateStage();
  const { school } = useSchool();
  const { Header } = Layout;
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: <div>My Profile</div>,
      onClick: () => stage.setIsOpen(true),
    },
    {
      key: "2",
      label: <div>Settings</div>,
      disabled: true,
    },
  ];

  return (
    <Header className="h-16 px-6 py-6 flex items-center justify-between bg-[#E2EBFA] w-full z-20 top-0 fixed">
      {/* <div className='flex items-center gap-16'>
        <div
          className=' flex items-center cursor-pointer'
          onClick={() => navigate("/")}>
          <img
            src={logo}
            alt='logo'
            className='w-14 h-14 object-contain'
          />
          <Tag className='bg-secondaryYellow gap-3'>closed-Alpha 0.2.0</Tag>
        </div>
      </div> */}
      <div
        className="flex items-center gap-3 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <Image className=" h-full" src={logo} preview={false} width={53} />

        <Tag className="hidden md:flex bg-secondaryYellow text-brown border-brown border-[0.5px]">
          0.5.2
        </Tag>
      </div>

      {!isOnBoarding && (
        <div className="flex gap-x-5 justify-end items-center">
          {!user?.loading && (
            <>
              <p className=" capitalize">{school?.detail?.name}</p>
              <Dropdown menu={{ items }} placement="bottom" arrow>
                <img
                  src={`${
                    user?.data?.avatar
                      ? user?.data?.avatar + "?" + Date.now()
                      : avatar
                  }`}
                  alt="user"
                  className="h-12 w-12 rounded-full object-contain border border-primary cursor-pointer"
                />
              </Dropdown>
            </>
          )}
        </div>
      )}
      <Drawer
        title="My Instructor Profile"
        placement="right"
        closable={false}
        onClose={() => {
          stage.setIsOpen(false);
          stage.setIsEdit(false);
        }}
        open={stage.isOpen}
        width={"736px"}
        extra={
          <CloseOutlined
            className=" cursor-pointer"
            onClick={() => {
              stage.setIsOpen(false);
              stage.setIsEdit(false);
            }}
          />
        }
      >
        <Profile
          handleClose={() => {
            stage.setIsOpen(false);
            stage.setIsEdit(false);
          }}
          isEdit={stage.isEdit}
          handleEdit={() => stage.setIsEdit(true)}
          data={user}
          school={school?.detail}
        />
      </Drawer>
    </Header>
  );
}

export default CustomHeader;
