import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLevelCourse } from "../hooks/useLevelCourse";
import { Button, Input, Skeleton } from "antd";
import TabModuleContainer from "../components/TabModuleContainer";
import TabModuleAssesmentContainer from "../components/TabModuleAssesmentContainer";
import { CheckCircleOutlined } from "@ant-design/icons";
import FiveCircleRate from "../../../common/components/FiveCircle";
import { Breadcrumbs } from "../../../common/components/Breadcumbs";
import TabModuleContainerTwo from "../components/TabModuleContainerTwo";
import TabModuleContainerThree from "../components/TabModuleContainerThree";
import TabModuleContainerFour from "../components/TabModuleContainerFour";
import { HomeIcon } from "../../../assets/icon";

const EditLevelCourses = () => {
  const navigate = useNavigate();
  const { skill_id, level_id } = useParams();

  const {
    detailLevel,
    isDetailLoading,
    activeTab,
    handleChangeTab,
    handleBack,

    handleAddNewQuestion,
    handleRemoveQuestion,
    handleFormQuestionChange,
    handleAddNewOption,
    handleOptionValueChange,
    handleRemoveAnswerOption,
    handleOptionIsAnswerChange,
    schema,
    handleSubmitPublishLevel,
    register,
    handleSubmit,
    errors,
    onSubmit,

    handleInputChangeModule,
    handleLessonsChangeModule,
    handleAddLessonModule,
    handleDeleteLessonModule,

    moduleData,
    learningOutcame,
    setLearningOutcame,
    SaveChanges,
    isLoadingDataFromLS,
    contextHolder,
    setIsVideoToInProgress,
    moveLessonDown,
    moveLessonUp,
  } = useLevelCourse(skill_id, level_id, navigate);

  const isModuleFilledCompleted = (index) => {
    const item = moduleData[index];
    let isOverviewComplete;

    if (index === 0) {
      isOverviewComplete = Object?.values(item?.overview)?.every(
        (value) => value?.trim() !== "" || value?.recap !== ""
      );
    } else {
      isOverviewComplete = Object?.values(item?.overview)?.every(
        (value) => value?.trim() !== ""
      );
    }
    const areLessonsComplete = item?.lessons?.every(
      (lesson) =>
        lesson?.description?.trim() !== "" ||
        (lesson?.video?.status === "FINISHED" && lesson?.video?.dash)
    );
    const isConceptualSupportsComplete = Object?.values(
      item?.conceptual_supports
    )?.every((value) => value?.trim() !== "");

    return (
      isOverviewComplete && areLessonsComplete && isConceptualSupportsComplete
    );
  };

  const isAssessmentFilledCompleted =
    moduleData &&
    moduleData[4]?.tests?.every((item) => {
      const isQuestionComplete = item?.question?.trim() !== "";
      const areAnswersComplete =
        Array?.isArray(item?.answers) &&
        item?.answers?.every((answer) => answer?.answer?.trim() !== "");
      const hasCorrectAnswer =
        Array.isArray(item.answers) &&
        item.answers.some((answer) => answer.is_correct_answer === true);

      return isQuestionComplete && areAnswersComplete && hasCorrectAnswer;
    });

  const isQuestionMoreThanNine =
    moduleData &&
    moduleData[4]?.tests?.length > 9 &&
    moduleData[4]?.tests?.filter((ques) => ques?.is_deleted !== true).length >
      9;

  const tabs = [
    {
      label: "Module 1",
      component: (
        <TabModuleContainer
          moduleData={moduleData && moduleData[0]}
          handleInputChangeModule={handleInputChangeModule}
          handleLessonsChangeModule={handleLessonsChangeModule}
          handleAddLessonModule={handleAddLessonModule}
          handleDeleteLessonModule={handleDeleteLessonModule}
          register={register}
          errors={errors}
          moduleNumber={0}
          recapForm={false}
          setIsVideoToInProgress={setIsVideoToInProgress}
          moveLessonDown={moveLessonDown}
          moveLessonUp={moveLessonUp}
        />
      ),
      icon:
        moduleData && isModuleFilledCompleted(0) ? <CheckCircleOutlined /> : "",
    },
    {
      label: "Module 2",
      component: (
        <TabModuleContainerTwo
          moduleData={moduleData && moduleData[1]}
          handleInputChangeModule={handleInputChangeModule}
          handleLessonsChangeModule={handleLessonsChangeModule}
          handleAddLessonModule={handleAddLessonModule}
          handleDeleteLessonModule={handleDeleteLessonModule}
          register={register}
          errors={errors}
          moduleNumber={1}
          recapForm={true}
          setIsVideoToInProgress={setIsVideoToInProgress}
          moveLessonDown={moveLessonDown}
          moveLessonUp={moveLessonUp}
        />
      ),
      icon:
        moduleData && isModuleFilledCompleted(1) ? <CheckCircleOutlined /> : "",
    },
    {
      label: "Module 3",
      component: (
        <TabModuleContainerThree
          moduleData={moduleData && moduleData[2]}
          handleInputChangeModule={handleInputChangeModule}
          handleLessonsChangeModule={handleLessonsChangeModule}
          handleAddLessonModule={handleAddLessonModule}
          handleDeleteLessonModule={handleDeleteLessonModule}
          register={register}
          errors={errors}
          moduleNumber={2}
          recapForm={true}
          setIsVideoToInProgress={setIsVideoToInProgress}
          moveLessonDown={moveLessonDown}
          moveLessonUp={moveLessonUp}
        />
      ),
      icon:
        moduleData && isModuleFilledCompleted(2) ? <CheckCircleOutlined /> : "",
    },
    {
      label: "Module 4",
      component: (
        <TabModuleContainerFour
          moduleData={moduleData && moduleData[3]}
          handleInputChangeModule={handleInputChangeModule}
          handleLessonsChangeModule={handleLessonsChangeModule}
          handleAddLessonModule={handleAddLessonModule}
          handleDeleteLessonModule={handleDeleteLessonModule}
          register={register}
          errors={errors}
          moduleNumber={3}
          recapForm={true}
          setIsVideoToInProgress={setIsVideoToInProgress}
          moveLessonDown={moveLessonDown}
          moveLessonUp={moveLessonUp}
        />
      ),
      icon:
        moduleData && isModuleFilledCompleted(3) ? <CheckCircleOutlined /> : "",
    },
    {
      label: "Assessment",
      component: (
        <TabModuleAssesmentContainer
          moduleData={moduleData}
          // questionFields={questionFields}
          handleAddNewQuestion={handleAddNewQuestion}
          handleRemoveQuestion={handleRemoveQuestion}
          handleFormQuestionChange={handleFormQuestionChange}
          handleLessonsChangeModule={handleLessonsChangeModule}
          handleAddNewOption={handleAddNewOption}
          handleOptionValueChange={handleOptionValueChange}
          handleRemoveAnswerOption={handleRemoveAnswerOption}
          handleOptionIsAnswerChange={handleOptionIsAnswerChange}
          schema={schema}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          onSubmit={onSubmit}
          moduleNumber={4}
        />
      ),
      icon:
        moduleData && isAssessmentFilledCompleted && isQuestionMoreThanNine ? (
          <CheckCircleOutlined />
        ) : (
          ""
        ),
    },
  ];

  let isPublish;

  if (moduleData) {
    isPublish =
      isModuleFilledCompleted(0) &&
      isModuleFilledCompleted(1) &&
      isModuleFilledCompleted(2) &&
      isModuleFilledCompleted(3) &&
      isAssessmentFilledCompleted &&
      isQuestionMoreThanNine;
  }

  const items = [
    {
      title: (
        <div className={"mt-1"}>
          <HomeIcon />
        </div>
      ),
      path: "/",
    },
    {
      path: "/courses",
      title: "Skills I teach",
    },
    {
      path: "/course-name",
      title: detailLevel?.name,
    },
  ];

  return (
    <div className="">
      {contextHolder}
      <div className="mb-3 bg-white py-2 pb-7 px-7">
        <Breadcrumbs items={items} />
      </div>
      {isDetailLoading ? (
        <div className="bg-white z-10 px-6 py-2 pt-3 ">
          <Skeleton active />{" "}
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between bg-white z-10 px-6 py-2 pt-3 ">
            <div className="flex items-center gap-3 w-[71.67%] ">
              <ArrowLeftOutlined
                onClick={handleBack}
                className="text-backdrop text-lg -mt-3"
              />
              <span className=" text-2xl font-bold text-backdrop mb-3 ">
                {isDetailLoading ? <Skeleton active /> : detailLevel?.name}
              </span>
              <span className="-mt-2">
                <FiveCircleRate
                  circleSize={15}
                  disabled={true}
                  defaultValue={detailLevel?.proficiency}
                />
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <Button
                disabled={!isPublish}
                onClick={() => handleSubmitPublishLevel()}
              >
                Publish Course
              </Button>
              <Button
                onClick={() => SaveChanges()}
                type="primary"
                className="bg-primary"
              >
                Save Changes
              </Button>
            </div>
          </div>

          {/* description */}
          <div className="bg-white px-10 pb-7">{detailLevel?.description}</div>
        </>
      )}

      {isLoadingDataFromLS ? (
        <div className="mx-6 my-5 bg-white px-8 py-2">
          <Skeleton active />
        </div>
      ) : (
        <div className="mx-6 my-5 bg-white px-8 py-2">
          {/* learning outcome */}
          <div className="mt-6">
            <p className="mb-2 text-sm">Skill level title</p>

            {/*<RichText*/}
            {/*  className={`h-60 pb-[2.43rem] mb-1 font-default  font-normal `}*/}
            {/*  placeholder={*/}
            {/*    "Write an introduction to this module and lessons outline"*/}
            {/*  }*/}
            {/*  handleChange={(e) => setLearningOutcame(e)}*/}
            {/*  value={learningOutcame}*/}
            {/*/>*/}

            <Input
              placeholder="Give this module a name"
              allowClear
              onChange={(e) => setLearningOutcame(e.target.value)}
              className={`mb-1 font-default  font-normal`}
              value={learningOutcame}
            />
          </div>

          <div className="mt-3 bg-[#FFEFA0] px-10 py-5">
            <ul>
              <li className="leading-7 text-backdrop list-disc">
                To complete a module, you need to fill all required information.
              </li>
              <li className="leading-7 text-backdrop list-disc">
                To complete an assessment - you need to add at least 10
                multiple-choice questions.
              </li>
              <li className="leading-7 text-backdrop list-disc">
                When you complete all modules and the assessment (get green
                checkmark) - the Publish button will be unlocked.
              </li>
            </ul>
          </div>

          {/* module tabs */}
          <div className="mt-5">
            <div className="flex flex-col ">
              <div className="flex pb-2 relative">
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={`pr-12 py-3 relative cursor-pointer ${
                      index === activeTab ? "" : ""
                    }`}
                    onClick={() => handleChangeTab(index)}
                  >
                    <span
                      className={`absolute bottom-0 left-0 right-12 ${
                        index === activeTab
                          ? "border-b-[3px] border-primary"
                          : "border-b border-gray-300"
                      }`}
                      style={{
                        width: index === activeTab ? "auto" : "0",
                        transition: "width 0.3s ease-in-out",
                      }}
                    ></span>
                    <span
                      className={`absolute bottom-0 left-0 right-0 ${
                        index !== activeTab
                          ? "border-b border-gray-300 w-full"
                          : ""
                      }`}
                    ></span>
                    <div className="flex items-center gap-2">
                      <span
                        className={` ${
                          errors.hasOwnProperty(index)
                            ? "text-red-500"
                            : "text-primary"
                        }`}
                        // className={getClassNameForTabIcon(index, errors)}
                      >
                        {tab.icon}
                      </span>

                      <span
                        className={` ${
                          index === activeTab ? "text-primary" : ""
                        }`}
                      >
                        {tab.label}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="border-b border-gray-300 pb-3 -mt-[21px] inset-x-0 bottom-0 mb-6"></div>
              <div className="mb-10">{tabs[activeTab].component}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditLevelCourses;
