import React from "react";
import { HomeIcon, MoonIcon } from "../../../assets/icon";
import { PageContent } from "../../../common/layout/PageContent";
import { PageHeader } from "../../../common/layout/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnalytic, ValueParams } from "../hook/useAnalytic";
import { Progress, Skeleton, Tooltip, Typography } from "antd";
import { TeamOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { formatNumberWithThousandSeparator } from "../../../utils/fotmatNumber";

export const AnalyticDetail = () => {
  const breadcrumbItems = [
    {
      title: (
        <div className={"mt-1"}>
          <HomeIcon />
        </div>
      ),
      path: "/",
    },
    {
      title: "Admin",
      className: "text-gray-400",
    },
    {
      title: "Dashboard",
    },
    {
      title: "Overview",
      to: "/trainer-admin/school-dashboard/overview",
    },
    {
      title: "Analytics",
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const content = queryParams.get("content");
  const analytic = useAnalytic(
    content === "courses"
      ? ValueParams.COURSES
      : content === "workshops"
      ? ValueParams.WORKSHOPS
      : content === "programs"
      ? ValueParams.PROGRAMS
      : ValueParams.ALL,
    "details"
  );

  const feelOptions: Record<number, string> = {
    1: "😴",
    2: "😕",
    3: "😕",
    4: "🥰",
    5: "🔥",
  };

  return (
    <>
      <PageContent
        content={
          <div className="bg-white-custom rounded-lg p-7 mt-3">
            {analytic.isLoading ? (
              <Skeleton paragraph={{ rows: 9 }} title={false} />
            ) : analytic.data ? (
              <div>
                <div className=" grid grid-cols-8 gap-3">
                  <div
                    className=" rounded px-5 py-3 col-span-3 bg-[#FDFDFD]"
                    style={{ border: "1px solid #D9D9D9" }}
                  >
                    <div className=" font-semibold mb-5">Learner Activity</div>
                    <div className="flex gap-10">
                      <Progress
                        percent={
                          Math.floor(
                            ((analytic.data.learner_activities.completed +
                              analytic.data.learner_activities.in_progress) /
                              analytic.data.learner_activities.total) *
                              100 *
                              100
                          ) / 100
                        }
                        success={{
                          percent:
                            (analytic.data.learner_activities.completed /
                              analytic.data.learner_activities.total) *
                            100,
                        }}
                        type="circle"
                        strokeColor="#ff4d4f"
                        size={[190, 20]}
                        format={(percent) => (
                          <div className=" text-base">
                            <strong>{`${percent}%`}</strong> <br />
                            Completed
                          </div>
                        )}
                      />
                      <div className=" flex flex-col justify-between">
                        <div
                          className="px-3"
                          style={{ borderLeft: "2px solid #86C769" }}
                        >
                          <div>
                            {analytic.data.learner_activities.completed}
                          </div>
                          <div>Completed</div>
                        </div>
                        <div
                          className="px-3"
                          style={{ borderLeft: "2px solid #EF7574" }}
                        >
                          <div>
                            {analytic.data.learner_activities.in_progress}
                          </div>
                          <div>In Progress</div>
                        </div>
                        <div
                          className="px-3"
                          style={{ borderLeft: "2px solid #E2EBFA" }}
                        >
                          <div>
                            {analytic.data.learner_activities.not_started}
                          </div>
                          <div>Not Started</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className=" grid grid-cols-2 gap-3 mb-3">
                      <div
                        className=" rounded p-3 bg-[#FDFDFD]"
                        style={{ border: "1px solid #D9D9D9" }}
                      >
                        <div className=" flex gap-3">
                          <TeamOutlined />
                          <div>Enrolled</div>
                        </div>
                        <div className=" text-2xl font-semibold">
                          {formatNumberWithThousandSeparator(
                            analytic.data.enrolled.total
                          )}
                        </div>
                      </div>
                      <div
                        className=" rounded p-3 bg-[#FDFDFD]"
                        style={{ border: "1px solid #D9D9D9" }}
                      >
                        {analytic?.data?.nps?.total_responded > 0 ? (
                          <div>
                            <div>
                              <div>NPS :</div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              <div>NPS :</div>
                            </div>
                          </div>
                        )}
                        {analytic?.data?.nps?.total_responded > 0 ? (
                          <div className="flex gap-1 items-end">
                            <div className=" text-2xl font-semibold">
                              {analytic?.data?.nps?.total_responded > 0
                                ? Math.round(analytic?.data?.nps?.score.score)
                                : "-"}
                            </div>
                            <div className=" leading-7 capitalize">
                              {`(${
                                analytic?.data?.nps?.score?.status || "Unknown"
                              })`}
                            </div>
                          </div>
                        ) : (
                          <div className=" text-2xl font-semibold">-</div>
                        )}
                      </div>
                    </div>
                    <div
                      className="p-3 rounded bg-[#FDFDFD]"
                      style={{ border: "1px solid #D9D9D9" }}
                    >
                      <div>Average Feeling</div>
                      {analytic?.data?.nps?.total_responded > 0 ? (
                        <div className=" flex gap-3 items-center justify-center my-3">
                          <div className=" text-4xl font-semibold">
                            {feelOptions[
                              analytic.data?.nps?.avg_rating_feel || 1
                            ] || "-"}
                          </div>

                          <div className="">
                            <div className=" text-3xl">
                              {analytic?.data?.nps?.total_responded || "0"}
                            </div>
                            <div>responses</div>
                          </div>
                        </div>
                      ) : (
                        <div className=" flex flex-col gap-3 items-center my-3">
                          <MoonIcon />
                          <div className="mt-2">No ratings yet</div>
                        </div>
                      )}
                      {analytic?.data?.nps?.total_responded > 0 && (
                        <div className="mt-5 flex justify-around">
                          {analytic.data.learner_feelings.map((item, i) => (
                            <Tooltip
                              placement="top"
                              title={item.response}
                              key={i}
                              overlayInnerStyle={{ textAlign: "center" }}
                            >
                              <div
                                className={`text-4xl font-semibold ${
                                  feelOptions[
                                    analytic.data?.nps?.avg_rating_feel || 1
                                  ] === item.emoticon
                                    ? ""
                                    : "opacity-40"
                                }`}
                              >
                                {item.emoticon}
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className=" rounded px-3 py-3 col-span-2 bg-[#FDFDFD]"
                    style={{ border: "1px solid #D9D9D9" }}
                  >
                    <div className=" font-semibold mb-3">Country</div>
                    {analytic.country.total === 0 ? (
                      <div className=" flex flex-col gap-5 items-center mt-10">
                        <MoonIcon />
                        <div>No data</div>
                      </div>
                    ) : (
                      <>
                        {analytic.country.country?.map((item, i) => {
                          return (
                            <div
                              className="py-1 px-1.5 mb-1.5 rounded bg-[#FDFDFD] text-xs"
                              style={{ border: "1px solid #D9D9D9" }}
                              key={i}
                            >
                              <div className="mb-1">{item.name}</div>
                              <div className="flex gap-3 items-center">
                                <Progress
                                  style={{ margin: 0 }}
                                  percent={
                                    (item.total / analytic.country.total) * 100
                                  }
                                  size={[130, 25]}
                                  showInfo={false}
                                  strokeColor={"#86C769"}
                                />
                                <div className=" w-full">
                                  {`${item.total} learners`}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="mt-2 flex gap-9">
                          <div>Other countries</div>
                          <div>{`${analytic.country.other_country} learners`}</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={`grid gap-3 mt-3 ${
                    analytic.data.details?.program_surveys
                      ? "grid-cols-12"
                      : "grid-col-9"
                  }`}
                >
                  <div
                    className=" p-3 rounded col-span-9"
                    style={{ border: "1px solid #D9D9D9" }}
                  >
                    {analytic.listCourse ? (
                      <div className="">
                        <div className="grid grid-cols-7 p-3 gap-3">
                          <div className="col-span-2">Course Title</div>
                          <div className="text-center">Number of Learners</div>
                          <div className="col-span-2">NPS Score</div>
                          <div className="text-center">Responses</div>
                          <div className="">Responses Rate</div>
                        </div>
                        {analytic.listCourse.map((item, i) => (
                          <div
                            className="grid grid-cols-7 p-3 mb-2 rounded bg-[#FAFAFA] gap-3 items-center"
                            key={i}
                            style={{ border: "1px solid #D9D9D9" }}
                          >
                            <div className="col-span-2">
                              {item.title || "Unknown"}
                            </div>
                            <div className="text-center">
                              {item.enrolled_learners || 0}
                            </div>
                            <div className="col-span-2 grid grid-cols-6">
                              <div
                                className="text-center text-sm col-span-3"
                                style={{ borderBottom: "2px solid #EF7574" }}
                              >
                                {item.nps.score.score <= 0
                                  ? Math.round(item.nps.score.score)
                                  : ""}
                              </div>
                              <div
                                className="text-center text-sm col-span-1"
                                style={{ borderBottom: "2px solid #FAC61D" }}
                              >
                                {item.nps.score.score > 0 &&
                                item.nps.score.score <= 30
                                  ? Math.round(item.nps.score.score)
                                  : ""}
                              </div>
                              <div
                                className=" col-span-1 text-center text-sm"
                                style={{ borderBottom: "2px solid #9ed287" }}
                              >
                                {item.nps.score.score > 30 &&
                                item.nps.score.score <= 70
                                  ? Math.round(item.nps.score.score)
                                  : ""}
                              </div>
                              <div
                                className=" col-span-1 text-center text-sm"
                                style={{ borderBottom: "2px solid #78B35E" }}
                              >
                                {item.nps.score.score > 70
                                  ? Math.round(item.nps.score.score)
                                  : ""}
                              </div>
                            </div>
                            <div className="text-center">{item.responses}</div>
                            <div className="">{item.response_rate}</div>
                          </div>
                        ))}

                        {/* <div>body</div> */}
                      </div>
                    ) : (
                      <div className=" flex flex-col gap-5 items-center my-10">
                        <MoonIcon />
                        <div>No data</div>
                      </div>
                    )}
                  </div>
                  {analytic.data.details?.program_surveys && (
                    <div className=" col-span-3 rounded ">
                      <div
                        className="p-3 rounded"
                        style={{ border: "1px solid #D9D9D9" }}
                      >
                        <div className=" font-semibold">Programs</div>
                        <div className=" mb-5">Survey Completion Rates</div>
                        <div className="grid grid-cols-5 gap-3">
                          <Progress
                            className=" col-span-2"
                            type="circle"
                            percent={
                              Math.floor(
                                ((analytic.data.details.program_surveys
                                  .response_pre_learning +
                                  analytic.data.details.program_surveys
                                    .response_post_learning) /
                                  (analytic.data.details.program_surveys
                                    .post_learning +
                                    analytic.data.details.program_surveys
                                      .pre_learning)) *
                                  100 *
                                  100
                              ) / 100
                            }
                            strokeColor={"#86C769"}
                            // trailColor="#FAC61D"
                            size={[100, 20]}
                            format={(percent) => (
                              <div className=" text-xs">
                                <strong>{`${percent}%`}</strong>
                                <br />
                                Completed
                              </div>
                            )}
                          />
                          <div className=" col-span-3 ">
                            <div
                              className="px-3 mb-3"
                              // style={{ borderLeft: "2px solid #86C769" }}
                            >
                              <strong>Pre-Learning</strong>
                              <div className=" text-xs">
                                {`${analytic.data.details.program_surveys.response_pre_learning}/${analytic.data.details.program_surveys.pre_learning} Completed`}
                              </div>
                            </div>
                            <div
                              className="px-3"
                              // style={{ borderLeft: "2px solid #FAC61D" }}
                            >
                              <strong>Post-Learning</strong>
                              <div className=" text-xs">
                                {`${analytic.data.details.program_surveys.response_post_learning}/${analytic.data.details.program_surveys.post_learning} Completed`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className=" flex flex-col gap-5 items-center my-10">
                <MoonIcon />
                <div>No data</div>
              </div>
            )}
          </div>
        }
        header={
          <PageHeader
            breadcrumbItems={breadcrumbItems}
            title={
              <div>
                <Typography.Title className=" flex gap-3 items-center">
                  <ArrowLeftOutlined onClick={() => navigate(-1)} />
                  <div>{helperTitle(content || "none")}</div>
                </Typography.Title>
              </div>
            }
          />
        }
      />
    </>
  );
};

const helperTitle = (data: string) => {
  switch (data) {
    case "courses":
      return "Green Skills Analytics";
    case "programs":
      return "Programs Analytics";
    case "workshops":
      return "Workshops Analytics";
    default:
      return "All Courses Analytics";
  }
};
