/* eslint-disable no-unused-vars */
import { AxiosResponse, AxiosError } from "axios"
import { message } from "antd"
// eslint-disable next-line no-unused-vars
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from "react-query"
import instance from "../../config/config.js"
import { useMemo } from "react"

interface ApiResponse<T> {
  error: boolean
  code: number
  message: string
  data: T
}

interface Pagination {
  page: number
  total_page: number
  limit: number
}

interface ListData<T> {
  list: T[]
  total_data: number
  pagination: Pagination
}

interface UseFetchListOptions {
  queryParams?: Record<string, any>
  queryName?: string
  enabled?: boolean
}

export const useFetchList = <T>(
  endpoint: string,
  { queryParams, queryName, enabled = true }: UseFetchListOptions,
): UseQueryResult<ListData<T>, AxiosError> => {
  const fetchList = async (): Promise<ListData<T>> => {
    try {
      const response: AxiosResponse<ApiResponse<ListData<T>>> =
        await instance.get(endpoint, {
          params: queryParams,
        })
      return response.data.data
    } catch (error: any) {
      if (error.response) {
        console.error(
          `Server Error: ${error.response.status} - ${error.response.data}`,
        )
      } else if (error.request) {
        console.error("Network Error: No response received")
      } else {
        console.error(`Error: ${error.message}`)
      }
      throw error
    }
  }

  const queryKey = useMemo(
    () => queryName ?? [endpoint, queryParams],
    [endpoint, queryParams, queryName],
  )

  return useQuery<ListData<T>, AxiosError>(queryKey, fetchList, {
    enabled,
    onError: (error: any) => {
      message.error({
        content: `ERROR: ${error?.response?.data?.data} an error occurred during fetch`,
        key: "fetch-list-" + (queryName ?? [endpoint, queryParams].join("-")),
      })
    },
  })
}

export interface UseFetchListAPIV2Options {
  queryParams?: Record<string, any>
  queryName?: string
  enabled?: boolean
}

export const useFetchListAPIV2 = <T>(
  endpoint: string,
  { queryParams, queryName, enabled = false }: UseFetchListAPIV2Options,
): UseQueryResult<ListData<T>, AxiosError> => {
  const fetchList = async (): Promise<ListData<T>> => {
    try {
      const response: AxiosResponse<ApiResponse<ListData<T>>> =
        await instance.get(endpoint, {
          params: queryParams,
        })
      return response.data.data
    } catch (error: any) {
      if (error.response) {
        console.error(
          `Server Error: ${error.response.status} - ${error.response.data}`,
        )
      } else if (error.request) {
        console.error("Network Error: No response received")
      } else {
        console.error(`Error: ${error.message}`)
      }
      throw error
    }
  }

  const queryKey = useMemo(
    () => queryName ?? [endpoint, queryParams],
    [endpoint, queryParams, queryName],
  )

  return useQuery<ListData<T>, AxiosError>(queryKey, fetchList, {
    enabled,
    onError: (error: any) => {
      message.error({
        content: `ERROR: ${error?.response?.data?.data} an error occurred during fetch`,
        key: "fetch-list-" + queryName ?? [endpoint, queryParams],
      })
    },
  })
}

interface UseFetchDetailOptions {
  queryParams?: Record<string, any>
  queryName?: string
  enabled?: boolean
  showErrorMessage?: boolean
}

export const useFetchDetail = <T>(
  endpoint: string,
  {
    queryParams = {},
    queryName,
    enabled = true,
    showErrorMessage = true,
  }: UseFetchDetailOptions,
): UseQueryResult<T, AxiosError> => {
  const fetchDetail = async (): Promise<T> => {
    try {
      const response: AxiosResponse<ApiResponse<T>> = await instance.get(
        endpoint,
        {
          params: queryParams,
        },
      )
      return response.data.data
    } catch (error: any) {
      if (error.response) {
        console.error(
          `Server Error: ${error.response.status} - ${error.response.data}`,
        )
      } else if (error.request) {
        console.error("Network Error: No response received")
      } else {
        console.error(`Error: ${error.message}`)
      }
      throw error
    }
  }

  const queryKey = useMemo(
    () => queryName ?? [endpoint, queryParams],
    [endpoint, queryParams, queryName],
  )

  return useQuery<T, AxiosError>(queryKey, fetchDetail, {
    enabled,
    onError: (error: any) => {
      showErrorMessage &&
        message.error({
          content: `ERROR: ${error?.response?.data?.data} an error occurred during fetch`,
          key:
            "fetch-detail-" + (queryName ?? [endpoint, queryParams].join("-")),
        })
    },
  })
}


// V2 API
export const useFetchDetailAPIV2 = <T>(
  endpoint: string,
  queryParams?: Record<string, any>,
  queryName?: string,
): UseQueryResult<T, AxiosError> => {
  const fetchDetail = async () => {
    const response: AxiosResponse<ApiResponse<T>> =
      await instance.get(endpoint)
    return response.data.data
  }

  return useQuery<T, AxiosError>(queryName ?? endpoint, fetchDetail, {
    onError: (error: any) => {
      message.error({
        content: `ERROR: ${error?.response?.data?.data} an error occurred during fetch`,
        key: "fetch-list-" + queryName ?? [endpoint, queryParams],
      })
    },
  })
}


export const useWrite = <T, U>(
  endpoint: string,
  method: "post" | "put" | "patch" | "delete",
  onSuccess?: () => void,
  onError?: () => void,
): UseMutationResult<T, AxiosError, U, unknown> => {
  const mutation = useMutation<T, AxiosError, U, unknown>(
    async (data) => {
      try {
        let response: AxiosResponse<ApiResponse<T>>
        switch (method) {
          case "post":
            response = await instance.post(endpoint, data)
            break
          case "put":
            response = await instance.put(endpoint, data)
            break
          case "patch":
            response = await instance.patch(endpoint, data)
            break
          case "delete":
            response = await instance.delete(endpoint, { data })
            break
          default:
            throw new Error("Unsupported HTTP method")
        }
        return response.data.data
      } catch (error: any) {
        message.error({
          content: `ERROR: ${error?.response?.data?.data} an error occurred during ${method}`,
          key: endpoint,
        })
        throw error
      }
    },
    {
      onSuccess: () => {
        if (onSuccess) onSuccess()
      },
      onError: () => {
        if (onError) onError()
      },
    },
  )

  return mutation
}

export const useWriteV2 = <T, U>(
  getEndpoint: (data: U) => string,
  method: "post" | "put" | "patch" | "delete",
  onSuccess?: () => void,
  onError?: () => void,
): UseMutationResult<T, AxiosError, U, unknown> => {
  const mutation = useMutation<T, AxiosError, U, unknown>(
    async (data) => {
      const endpoint = getEndpoint(data)
      try {
        let response: AxiosResponse<any>
        switch (method) {
          case "post":
            response = await instance.post(endpoint, data)
            break
          case "put":
            response = await instance.put(endpoint, data)
            break
          case "patch":
            response = await instance.patch(endpoint, data)
            break
          case "delete":
            response = await instance.delete(endpoint, { data })
            break
          default:
            throw new Error("Unsupported HTTP method")
        }
        return response.data.data
      } catch (error: any) {
        message.error({
          content: `ERROR: ${error?.response?.data?.data} an error occurred during ${method}`,
          key: endpoint,
        })
        throw error
      }
    },
    {
      onSuccess: () => {
        if (onSuccess) onSuccess()
      },
      onError: () => {
        if (onError) onError()
      },
    },
  )

  return mutation
}
