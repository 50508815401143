import { create } from "zustand"

export interface InitPublicStore {
  isVisible: boolean
  onConfirm: (() => Promise<void>) | null 
  onCancel: (() => void) | null
  openModal: (onConfirm?: () => Promise<void>, onCancel?: () => void) => void 
  closeModal: () => void
}

export const initialPublicStoreState = create<InitPublicStore>((set) => ({
  isVisible: false,
  onConfirm: null,
  onCancel: null,

  openModal: (onConfirm, onCancel) => set({
    isVisible: true,
    onConfirm: onConfirm ?? null,  
    onCancel: onCancel ?? null,   
  }),

  closeModal: () => set({
    isVisible: false,
    onConfirm: null,
    onCancel: null,
  }),
}))
