import { CaretDownFilled, CaretUpFilled, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Empty, Input, Radio, Slider, SliderSingleProps, Switch } from 'antd'
import React, { useState } from 'react'
import { BasicContainer } from '../components/CourseEditor/BasicContainer'
import { useHookProgramCourseForm } from '../hooks/useProgramCourse'
import { RichContainerEditor } from '../components/ProgramCourseEditor/RichEditorCustom'

interface AssessmentEditorProps { }

const AssessmentEditor: React.FC<AssessmentEditorProps> = () => {
  const { formData, deleteQuestion, addAnswer, deleteAnswer,
    handleChangeAssessmentQuestion,
    handleChangeAssessmentAnswer,
    handleChangeAssessmentCorrectAnswer, reorderQuestion, updateTimer } = useHookProgramCourseForm()
  const [showTimer, setShowTimer] = useState<string>("")

  const marks: SliderSingleProps['marks'] = {
    0.5: '0.5',
    2: '2',
    4: '4',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: { label: <strong>10</strong> },

  }

  return (
    <div className="p-4 bg-white space-y-7">
      <div className="space-y-7 mt-2">

        <div className="space-y-5">
          {formData?.assessments?.length === 0 || formData?.assessments?.length === null ?
            <div className="py-10 px-4 bg-secondaryBlue  border rounded-xl">
              <Empty description="Add your first question" />
            </div> : formData?.assessments?.map((assessment, index) => {

              const currentIndex = formData.assessments ? formData.assessments.findIndex(a => a.id === assessment.id) : -1
              const isFirst = currentIndex === 0
              const isLast = formData.assessments ? currentIndex === formData.assessments.length - 1 : false

              return (
                <div key={assessment.id} className="space-y-7">
                  <div className="py-2 px-4 bg-secondaryBlue shadow-custom-sm border rounded-xl">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-5">
                        <div className='flex items-center'>
                          {/* Disable the button if it's the first item */}
                          <CaretUpFilled
                            onClick={() => !isFirst && formData.assessments && reorderQuestion(assessment.id, formData.assessments[currentIndex - 1]?.id)}
                            style={{ color: isFirst ? 'gray' : 'inherit', cursor: isFirst ? 'not-allowed' : 'pointer' }}
                          />

                          {/* Disable the button if it's the last item */}
                          <CaretDownFilled
                            onClick={() => !isLast && formData.assessments && reorderQuestion(assessment.id, formData.assessments[currentIndex + 1]?.id)}
                            style={{ color: isLast ? 'gray' : 'inherit', cursor: isLast ? 'not-allowed' : 'pointer' }}
                          />
                        </div>
                        <h5 className="font-bold">Question {index + 1}</h5>
                      </div>
                      <DeleteOutlined className="text-lg hover:text-red-500 cursor-pointer" onClick={() => deleteQuestion(assessment.id)} />
                    </div>
                  </div>

                  <RichContainerEditor
                    title="question"
                    placeholder="Write the question"
                    showError={true}
                    value={assessment?.question}
                    onChange={(value) => handleChangeAssessmentQuestion(assessment.id, value)}
                  />

                  <div className="grid grid-cols-12 gap-x-6">
                    <div className="col-span-9">
                      <BasicContainer className="">
                        <div className="flex items-center justify-between mb-12">
                          <h3>Answers</h3>
                          <PlusOutlined onClick={() => addAnswer(assessment.id)} />
                        </div>
                        {assessment?.answers?.map((answer, indexAnswer) => (
                          <div key={answer.id} className="grid grid-cols-12 items-center gap-x-5 mb-3">
                            <Radio checked={answer.is_correct_answer} onChange={(e) => handleChangeAssessmentCorrectAnswer(assessment?.id, indexAnswer)} />
                            <Input
                              className="col-span-10"
                              placeholder="Answer"
                              value={answer.answer}
                              onChange={e => handleChangeAssessmentAnswer(assessment.id, indexAnswer, e.target.value)}
                            />
                            <CloseOutlined disabled={formData?.assessments?.length === 1} className="text-lg hover:text-red-500 cursor-pointer" onClick={() => deleteAnswer(assessment.id, answer.id)} />
                          </div>
                        ))}
                      </BasicContainer>
                    </div>

                    <div className="col-span-3">
                      <BasicContainer>
                        <div className="flex items-center justify-between">
                          <Switch
                            // @ts-ignore
                            checked={showTimer === assessment.id || assessment?.timer}
                            onChange={() => {
                              if (showTimer === assessment.id) {
                                setShowTimer("")
                              } else {
                                setShowTimer(assessment?.id)
                              }
                            }} className={`${showTimer === assessment.id ? 'bg-primary' : 'bg-gray-300'}`} />
                          <h3>Timer</h3>
                        </div>
                        {showTimer === assessment?.id || assessment?.timer ? (
                          <div className='mt-12'>
                            <Slider
                              marks={marks}
                              max={10}
                              value={assessment?.timer || 0}
                              onChange={(value) => updateTimer(assessment.id, value)}
                              className='text-primary '
                            />
                          </div>
                        ) : null}
                      </BasicContainer>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default AssessmentEditor
