export const DETAIL_SURVEY_CONFIG = "detail-survey-config";
export const RESPONSES_SURVEY_CONFIG = "list-survey-response";

export interface SurveySkill {
  id: string;
  name: string;
}

export interface SurveyResponse {
  id: string;
  is_pre_learning: boolean;
  is_post_learning: boolean;
  time_estimate: number;
  survey_reason: string;
  questions: SurveyQuestion[];
}

export enum TypeQuestion {
  MULTIPLE_CHOICE = "multiple_choice",
  TRUE_OR_FALSE = "true_or_false",
  SINGLE_CHOICE = "single_choice",
}

export interface SurveyQuestion {
  id: string;
  name: string;
  skill_id: string | undefined;
  skill: {
    id: string;
    name: string;
  } | null;
  type: TypeQuestion;
  answers: string[];
  order?: number;
  created_at?: string;
  updated_at?: string;
}

export interface SurveyResponseList {
  id: string;
  name: string;
  type: TypeQuestion;
  skill_id: string;
  skill: string | null;
  order: number;
  answers: string[];
  created_at: string;
  updated_at: string;
  responses: {
    id: string;
    user_id: string;
    type: "pre" | "post";
    country: string | null;
    survey_question_id: string;
    answers: string[];
    program_live_id: string;
    created_at: string;
  }[];
}
