import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { initialPublicStoreState } from '../hooks/InitPublicStore.ts'

interface ModalConfirmProps {
  messageTitle?: string
  messageSubtitle?: string
  confirmButtonText?: string
  cancelButtonText?: string
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  messageTitle = 'Are you sure you want to proceed?',
  messageSubtitle = '',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
}) => {
  const { isVisible, onConfirm, onCancel, closeModal } = initialPublicStoreState()
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    closeModal()
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (onConfirm) {
      try {
        await onConfirm()
      } catch (error) {
        console.error('Error executing onConfirm:', error)
      }
    }
    setLoading(false)
    closeModal()
  }

  return (
    <Modal
      title=""
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      centered
    >
      <p className="font-bold text-center text-xl mb-4">{messageTitle}</p>
      <p className="text-center mb-10">{messageSubtitle}</p>

      <div className="flex justify-center mt-4 gap-x-6">
        <Button key="cancel" onClick={handleCancel} className="rounded bg-red-400 text-white px-10">
          {cancelButtonText}
        </Button>
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit} className="rounded bg-primary text-white px-10">
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalConfirm
