export const PROGRAM_TRAINER_ENDPOINT = {
  LIST_PROGRAMS: `school/programs/trainer`,
  DETAIL_PROGRAM: (id: string) => `school/programs/trainer/${id}`,
  UPDATE_PROGRAM: (id: string) => `school/programs/trainer/${id}`,
  UPLOAD_DOCS_PROGRAM: (id: string) => `school/programs/trainer/${id}/docs`,
  DELETE_DOCS_PROGRAM: (id: string, blob_name: string) => `school/programs/trainer/${id}/docs/${blob_name}`,
  ADD_VIDEO_LESSON: (id: string, video_file_name: string) => `school/programs/trainer/${id}/vod/${video_file_name}`,
  DELETE_VIDEO_LESSON: (id: string, video_file_name: string) => `school/programs/trainer/${id}/vod/${video_file_name}`,

  ISSUING_EMAIL: (id: string, issuing: boolean) => `school/programs/trainer/issuing-email/${id}/${issuing}`,
  PUBLISH: (id: string) => `school/programs/trainer/publish/${id}`,
}
