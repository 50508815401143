import { create } from "zustand"
import { ContentMenuItem, ProgramCourseFormData, ProgramCourseResponse, QueryParams } from "../types/responseProgramCourse"

export interface EditorCourseProgramStoreState {
  programData: ProgramCourseResponse[]
  setProgramData: (data: ProgramCourseResponse[]) => void
  queryParams: QueryParams
  setQueryParams: (updater: ((prev: QueryParams) => QueryParams) | QueryParams) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  error: boolean
  setError: (error: boolean) => void
  activeContent: string,
  setActiveContent: (content: string) => void
  childrenContent: React.ReactNode
  setChildrenContent: (children: React.ReactNode) => void


  // detail
  programDetailData: ProgramCourseResponse | undefined | null
  setProgramDetailData: (data: ProgramCourseResponse | null) => void
  loadingDetailPorgram: boolean
  setLoadingDetailProgram: (loading: boolean) => void
  errorDetailProgram: boolean
  setErrorDetailProgram: (error: boolean) => void
  contentMenu: ContentMenuItem[],
  setContentMenu: (content: any) => void,

  // builder
  openCollapseCourse: string
  setOpenCollapseCourse: (open: string) => void

}


export const initialEditorCourseProgramStoreState = create<EditorCourseProgramStoreState>((set) => ({
  programData: [],
  setProgramData: (data: ProgramCourseResponse[]) => set({ programData: data }),
  queryParams: {
    page: 1,
    limit: 10,
    total: 0,
    search: "",
  },
  setQueryParams: (updater) => set((state) => ({
    queryParams: typeof updater === "function" ? updater(state.queryParams) : updater,
  })),
  loading: false,
  setLoading: (loading: boolean) => set({ loading }),
  error: false,
  setError: (error: boolean) => set({ error }),
  activeContent: 'overview',
  setActiveContent: (content: string) => set({
    activeContent: content
  }),
  childrenContent: null,
  setChildrenContent: (children: React.ReactNode) => set({
    childrenContent: children
  }),


  //detail
  programDetailData: null,
  setProgramDetailData: (data: ProgramCourseResponse | null) => set({ programDetailData: data }),
  loadingDetailPorgram: false,
  setLoadingDetailProgram: (loading: boolean) => set({ loadingDetailPorgram: loading }),
  errorDetailProgram: false,
  setErrorDetailProgram: (error: boolean) => set({ errorDetailProgram: error }),
  contentMenu: [],
  setContentMenu: (content: ContentMenuItem[]) => set({ contentMenu: content }),

  // builder
  openCollapseCourse: "",
  setOpenCollapseCourse: (open: string) => set({ openCollapseCourse: open }),

}))


export interface FormCourseProgramStoreState {
  formData: ProgramCourseFormData
  setFormData: (data: ProgramCourseFormData) => void

  isAssessmentFilled: boolean
  setIsAssessmentFilled: (filled: boolean) => void

  moduleValidationState: Record<string, boolean>
  setModuleValidationState: (moduleId: string, isValid: boolean) => void

  assessmentValidationState: boolean
  setAssessmentValidationState: (isValid: boolean) => void

  isInitial: boolean
  setIsInitial: (a: boolean) => void

  isCheckValid: boolean
  setIsCheckValid: (a: boolean) => void

  loadingIssionEmail: boolean,
  setLoadingIssuingEmail: (a: boolean) => void

  allFilled: boolean
  setAllFilled: (a: boolean) => void

}

export const initialFormCourseProgramStoreState = create<FormCourseProgramStoreState>(set => ({
  formData: {
    id: '',
    assessments: [
      {
        id: '',
        question: '',
        timer: 0,
        answers: [{ id: '', answer: '', is_correct_answer: false }],
      },
    ],
    description: '',
    modules: [
      {
        id: '',
        description: '',
        lessons: [
          {
            id: '',
            material: '',
            documents: [],
          },
        ],
        recap_previous_module: '',
      },
    ],
  },
  setFormData: data => set(state => ({ formData: { ...state.formData, ...data } })),
  isAssessmentFilled: false,
  setIsAssessmentFilled: filled => set({ isAssessmentFilled: filled }),

  moduleValidationState: {},
  setModuleValidationState: (moduleId, isValid) =>
    set((state) => ({
      moduleValidationState: {
        ...state.moduleValidationState,
        [moduleId]: isValid, // Update the specific module's validation status
      },
    })),

  assessmentValidationState: false,
  setAssessmentValidationState: (isValid) =>
    set({ assessmentValidationState: isValid }),

  isInitial: false,
  setIsInitial: (a: boolean) => set({ isInitial: a }),

  isCheckValid: false,
  setIsCheckValid: (a: boolean) => set({ isCheckValid: a }),

  loadingIssionEmail: false,
  setLoadingIssuingEmail: (a: boolean) => set({ loadingIssionEmail: a }),

  allFilled: false,
  setAllFilled: (a: boolean) => set({ allFilled: a }),
}))
