import { useCallback, useEffect, useRef, useState } from "react";
import {
  useFetchDetail,
  useFetchList,
  useWrite,
  useWriteV2,
} from "../../../common/hooks/generic_rest.ts";
import {
  Course,
  DETAIL_PROGRAM_QUERY_NAME,
  Lesson,
  LIST_PROGRAM_QUERY_NAME,
  Module,
  ProgramResponse,
} from "../types/programResponse.ts";
import { SCHOOL_DASHBORAD_ENDPOINT } from "../apis/endpoint.ts";
import { initialProgramStoreState } from "../store/programStore.ts";
import { message } from "antd";
import { useFieldArray, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { initialPublicStoreState } from "../../../common/hooks/InitPublicStore.ts";

export const useProgramFetch = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  const {
    queryParams,
    setQueryParams,
    setProgramData,
    setLoading,
    setError,
    setOpenModalNewProgram,
    programDetailData,
    isEditAction,
    programData,
  } = initialProgramStoreState();

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
    refetch: refetchListData,
    isFetching,
  } = useFetchList<ProgramResponse>(
    `${SCHOOL_DASHBORAD_ENDPOINT.LIST_PROGRAMS}`,
    {
      queryParams,
      queryName: LIST_PROGRAM_QUERY_NAME,
      enabled: enabled,
    }
  );

  useEffect(() => {
    setLoading(listLoading || isFetching);
    setError(listError);
    if (!listLoading) {
      if (queryParams.page === 1) {
        setProgramData(listData?.list || []);
        setQueryParams({
          page: queryParams.page,
          limit: queryParams.limit,
          total: listData?.total_data || 0,
          search: queryParams.search,
        });
      } else if (
        programData?.[programData?.length - 1]?.id !==
        listData?.list?.[listData?.list?.length - 1]?.id
      ) {
        setProgramData([...programData, ...listData?.list!]);
        setQueryParams({
          page: queryParams.page,
          limit: queryParams.limit,
          total: listData?.total_data || 0,
          search: queryParams.search,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listData?.list,
    listData?.total_data,
    listError,
    listLoading,
    isFetching,
    // programData,
    queryParams.limit,
    queryParams.page,
    queryParams.search,
    setError,
    setLoading,
    setProgramData,
    setQueryParams,
  ]);

  useEffect(() => {
    refetchListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.limit, queryParams.page, queryParams.search]);

  const handleChangeParams = (newParams: any) => {
    setQueryParams({
      page: newParams.page,
      limit: newParams.limit,
      total: queryParams.total,
      search: queryParams.search,
    });
  };
  const observer = useRef<IntersectionObserver | null>(null);

  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          programData.length &&
          programData?.length < queryParams.total &&
          !listLoading
        ) {
          handleChangeParams({ page: queryParams.page + 1 });
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [programData?.length]
  );

  // Save new program
  const { mutate: saveData } = useWrite<any, any>(
    SCHOOL_DASHBORAD_ENDPOINT.CREATE_PROGRAMS,
    "post",
    () => {
      message.success("Add new program successfully");
      refetchListData();
      setValue("skills", []);
      setValue("title", "");
      setValue("description", "");
      setValue("is_issuing_certificate", false);
      setShowSkillInputs(false);
      setUnconfirmedSkills([]);
      setOpenModalNewProgram(false);
    },
    () => message.error("An error occurred")
  );

  const editData = useWriteV2<any, any>(
    // @ts-ignore
    (data) => {
      console.log("data object before mutation:", data);

      if (data?.id) {
        return SCHOOL_DASHBORAD_ENDPOINT.UPDATE_PROGRAM(data.id);
      } else {
        throw new Error("Data object does not have an id property");
      }
    },
    "patch",
    () => {
      queryClient.invalidateQueries(DETAIL_PROGRAM_QUERY_NAME);

      message.open({
        key: "edit-program-success",
        content: "Update program successfully",
        type: "success",
      });
    },
    () => {
      message.open({
        key: "edit-program-success",
        content: "Something went wrong",
        type: "error",
      });
    }
  );

  const handleSaveProgram = (data: any) => {
    if (isEditAction) {
      message.open({
        key: "edit-program-success",
        content: "Loading...",
        type: "loading",
      });

      editData.mutate(data);

      setValue("skills", []);
      setValue("title", "");
      setValue("description", "");
      setShowSkillInputs(false);
      setUnconfirmedSkills([]);
      setOpenModalNewProgram(false);
    } else {
      saveData(data);
    }
  };

  const defaultValues = {
    id: "",
    title: "",
    description: "",
    skills: [],
    is_issuing_certificate: false,
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({ defaultValues });

  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: "skills",
  });

  const [showSkillInputs, setShowSkillInputs] = useState(false);
  const [unconfirmedSkills, setUnconfirmedSkills] = useState<string[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const watchTitle = watch("title", "");
  const watchDescription = watch("description", "");

  const onSubmit = (data: any) => {
    handleSaveProgram(data);
  };

  const handleAddSkillField = () => {
    setUnconfirmedSkills([...unconfirmedSkills, ""]);
  };

  const handleConfirmSkill = (index: number) => {
    if (unconfirmedSkills[index].trim()) {
      append({ name: unconfirmedSkills[index] });
      setUnconfirmedSkills(unconfirmedSkills.filter((_, i) => i !== index));
    }
  };

  const handleCancelSkill = (index: number) => {
    setUnconfirmedSkills(unconfirmedSkills.filter((_, i) => i !== index));
  };

  const handleSkillInputChange = (index: number, value: string) => {
    const updatedSkills = [...unconfirmedSkills];
    updatedSkills[index] = value;
    setUnconfirmedSkills(updatedSkills);
  };

  useEffect(() => {
    const isFormValid =
      watchTitle.trim() && watchDescription.trim() && fields.length >= 4;
    setIsButtonDisabled(!isFormValid);
  }, [watchTitle, watchDescription, fields]);

  useEffect(() => {
    if (programDetailData && isEditAction) {
      setValue("title", programDetailData.title);
      setValue("description", programDetailData.description);
      // @ts-ignore
      setValue("skills", programDetailData.skills);
      setValue(
        "is_issuing_certificate",
        programDetailData.is_issuing_certificate
      );
      setValue("id", programDetailData.id);
    }
  }, [programDetailData, isEditAction, setOpenModalNewProgram, setValue]);

  return {
    handleSubmit,
    errors,
    control,
    handleAddSkillField,
    handleConfirmSkill,
    handleCancelSkill,
    handleSkillInputChange,
    showSkillInputs,
    setShowSkillInputs,
    unconfirmedSkills,
    isButtonDisabled,
    onSubmit,
    fields,
    remove,
    setValue,
    lastCardElementRef,
  };
};

export const useProgramFetchDetail = (id: string) => {
  const {
    setProgramDetailData,
    setLoadingDetailProgram,
    setErrorDetailProgram,
  } = initialProgramStoreState();

  const {
    data: detailProgramData,
    isLoading: IsDetailLoadingDetailProgram,
    isError: isDetailErrirDetailProgram,
    isFetching,
    // refetch: refetchDetailProgram,
  } = useFetchDetail<ProgramResponse>(
    `${SCHOOL_DASHBORAD_ENDPOINT.DETAIL_PROGRAM(id)}`,
    {
      queryName: DETAIL_PROGRAM_QUERY_NAME,
      showErrorMessage: true,
    }
  );

  useEffect(() => {
    setProgramDetailData(detailProgramData!);
    setLoadingDetailProgram(IsDetailLoadingDetailProgram || isFetching);
    setErrorDetailProgram(isDetailErrirDetailProgram);
  }, [
    detailProgramData,
    IsDetailLoadingDetailProgram,
    isDetailErrirDetailProgram,
    setProgramDetailData,
    setLoadingDetailProgram,
    setErrorDetailProgram,
    isFetching,
  ]);
};

export const useProgramBuilder = () => {
  const {
    programDetailData,
    setOpenFormAddCourse,
    isEditCourse,
    setIsEditCourse,
  } = initialProgramStoreState();

  const defaultValues: Course = {
    modules: [
      {
        introduction: "",
        lessons: [],
      },
    ],
    program_id: "",
    release_date: "",
    title: "",
    trainer_id: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<Course>({ defaultValues });
  const queryClient = useQueryClient();
  const [modules, setModules] = useState<Module[]>(defaultValues.modules);

  useEffect(() => {
    setValue("program_id", programDetailData?.id!);
  }, [programDetailData?.id, setValue]);

  // Save new course
  const { mutate: saveData } = useWrite<any, any>(
    SCHOOL_DASHBORAD_ENDPOINT.CREATE_COURSE,
    "post",
    () => {
      message.success("Add new course successfully");

      setValue("modules", []);
      setValue("title", "");
      setValue("release_date", "");
      setValue("trainer_id", "");
      setValue("program_id", "");
      setModules([{ introduction: "", lessons: [] }]);

      queryClient.invalidateQueries(DETAIL_PROGRAM_QUERY_NAME);

      message.open({
        key: "add-course",
        content: "Course added successfully",
        type: "success",
      });

      setOpenFormAddCourse(false);
    },
    () =>
      message.open({
        key: "add-course",
        content: "Something went wrong",
        type: "error",
      })
  );

  const editData = useWriteV2<any, any>(
    // @ts-ignore
    (data) => {
      console.log("data object before mutation:", data);

      if (data?.id) {
        return SCHOOL_DASHBORAD_ENDPOINT.UPDATE_COURSE(data.id);
      } else {
        throw new Error("Data object does not have an id property");
      }
    },
    "patch",
    () => {
      queryClient.invalidateQueries(DETAIL_PROGRAM_QUERY_NAME);

      message.open({
        key: "edit-course-success",
        content: "Update course successfully",
        type: "success",
      });
    },
    () => {
      message.open({
        key: "edit-program-success",
        content: "Something went wrong",
        type: "error",
      });
    }
  );

  const onSubmit = (data: Course) => {
    if (isEditCourse) {
      message.open({
        key: "edit-course-success",
        content: "Loading...",
        type: "loading",
      });

      if (data?.trainer_id === "") {
        data.trainer_id = null;
      }

      if (data?.release_date === "") {
        delete data.release_date;
      }

      data.id = isEditCourse;
      editData.mutate(data);

      setIsEditCourse("");
    } else {
      message.open({
        key: "add-course",
        content: "Loading...",
        type: "loading",
      });

      if (data?.trainer_id === "") {
        data.trainer_id = null;
      }
      if (data?.release_date === "") {
        delete data.release_date;
      }

      saveData(data);
    }
  };

  const addModule = () => {
    setModules([...modules, { introduction: "", lessons: [] }]);
  };

  const removeModule = (index: number) => {
    const updatedModules = modules.filter((_, i) => i !== index);
    setModules(updatedModules);
    setValue("modules", updatedModules);
  };

  const addLesson = (index: number) => {
    const updatedModules = [...modules];
    updatedModules[index].lessons.push({
      id: Math.random(),
      title: "",
      program_skill_id: "",
    });
    setModules(updatedModules);
  };

  const removeLesson = (moduleIndex: number, lessonIndex: number) => {
    const updatedModules = [...modules];
    updatedModules[moduleIndex].lessons = updatedModules[
      moduleIndex
    ].lessons.filter((_, i) => i !== lessonIndex);
    setModules(updatedModules);
    setValue(
      `modules.${moduleIndex}.lessons`,
      updatedModules[moduleIndex].lessons
    );
  };

  // console.log('defaultValues.modules', defaultValues.modules)
  const handleLessonInputChange = (
    moduleIndex: number,
    lessonIndex: number,
    value: string,
    key: keyof Lesson
  ) => {
    const updatedModules = [...modules];

    if (key === "title" || key === "program_skill_id") {
      updatedModules[moduleIndex].lessons[lessonIndex][key] =
        value === undefined ? "" : (value as string);
      setModules(updatedModules);
      setValue(
        `modules.${moduleIndex}.lessons.${lessonIndex}.${key}`,
        value === undefined ? null : (value as string)
      );
    }
  };

  const handleModuleInputChange = (
    index: number,
    value: string,
    key: keyof Module
  ) => {
    const updatedModules = [...modules];
    if (key === "introduction") {
      updatedModules[index][key] = value as string;
      setModules(updatedModules);
      setValue(`modules.${index}.${key}`, value);
    }
  };

  // load data course to form edit
  useEffect(() => {
    console.log("run");
    if (isEditCourse) {
      const dataCourseToLoad = programDetailData?.courses?.find(
        (course) => course.id === isEditCourse
      );

      if (dataCourseToLoad) {
        setValue("title", dataCourseToLoad.title);
        setValue("release_date", dataCourseToLoad.release_date);
        setValue("trainer_id", dataCourseToLoad.trainer_id);
        setValue("program_id", dataCourseToLoad.program_id);
        setValue("modules", dataCourseToLoad.modules);
        setModules(dataCourseToLoad.modules || []);
      }
    }
  }, [isEditCourse, programDetailData?.courses, setValue]);

  // notify
  const notifyTrainer = useWriteV2<any, any>(
    // @ts-ignore
    (id) => {
      console.log("id object before mutation:", id);

      if (id) {
        return SCHOOL_DASHBORAD_ENDPOINT.NOTIFY_TRAINER(id);
      } else {
        throw new Error("Data object does not have an id property");
      }
    },
    "patch",
    () => {
      queryClient.invalidateQueries(DETAIL_PROGRAM_QUERY_NAME);

      message.open({
        key: "notify-trainer",
        content: "Notify trainer successfully",
        type: "success",
      });
    },
    () => {
      message.open({
        key: "notify-trainer",
        content: "Something went wrong",
        type: "error",
      });
    }
  );

  const handleNotifyTrainer = () => {
    message.open({
      key: "notify-trainer",
      content: "Please wait...",
      type: "loading",
    });
    notifyTrainer.mutate(programDetailData?.id);
  };

  const updatePublish = useWriteV2<any, any>(
    // @ts-ignore
    (id) => {
      if (id) {
        return SCHOOL_DASHBORAD_ENDPOINT.UPDATE_PUBLISH(id);
      } else {
        throw new Error("Data object does not have an id property");
      }
    },
    "patch",
    () => {
      queryClient.invalidateQueries(DETAIL_PROGRAM_QUERY_NAME);
      message.open({
        key: "update-publish-trainer",
        content: "Done!",
        type: "success",
      });
    },
    () => {
      message.open({
        key: "update-publish-trainer",
        content: "Something went wrong",
        type: "error",
      });
    }
  );

  const handlPublishUpdate = () => {
    message.open({
      key: "update-publish-trainer",
      content: "Please wait...",
      type: "loading",
    });
    updatePublish.mutate(programDetailData?.id);
  };

  return {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    onSubmit,

    addModule,
    removeModule,
    addLesson,
    removeLesson,
    handleLessonInputChange,
    handleModuleInputChange,
    modules,
    handleNotifyTrainer,
    handlPublishUpdate,
  };
};

export const useDeleteCourse = () => {
  const queryClient = useQueryClient();
  const openModal = initialPublicStoreState((state) => state.openModal);

  const removeCourse = useWriteV2<any, any>(
    ({ id }) => SCHOOL_DASHBORAD_ENDPOINT.DELETE_COURSE(id),
    "delete",
    () => {
      queryClient.invalidateQueries(DETAIL_PROGRAM_QUERY_NAME);
      message.open({
        key: "delete-course",
        content: "Course deleted successfully",
        type: "success",
      });
    },
    () => {
      message.open({
        key: "delete-course",
        content: "Something went wrong",
        type: "error",
      });
    }
  );

  const handleRemoveCourse = (id: string) => {
    removeCourse.mutate({ id });
  };

  const handleSubmit = async (id: string) => {
    message.open({
      key: "delete-course",
      content: "loading...",
      type: "loading",
    });
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulasi proses

    handleRemoveCourse(id);
  };

  const handleCancel = () => {
    console.log("Cancel button clicked!");
  };

  const showModal = (id: string) => {
    openModal(() => handleSubmit(id), handleCancel);
  };

  return {
    handleRemoveCourse,
    showModal,
  };
};
