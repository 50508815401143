import React from "react";
import { BasicTitleContainer } from "./BasicTitle.tsx";
import {
  CaretDownFilled,
  CaretUpFilled,
  CloseOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { BasicTwoBoxContainer } from "./BasicTwoBoxContainer.tsx";
import { BasicContainerRichEditor } from "./BasicContainerRichEditor.tsx";
import { Lesson } from "../../types/common.ts";
import { BasicContainer } from "./BasicContainer.tsx";
import { VideoUploadV2 } from "../../../../common/components/VideoUploadV2.tsx";
import { BitmovinPlayer } from "../../../../common/components/BitmovinPlayer.jsx";
import { Button, Tooltip, Upload } from "antd";
import { FieldArrayWithId } from "react-hook-form";

import { useLessonContainer } from "../../hooks/useLessonContainer.ts";

type LessonType = FieldArrayWithId<
  {
    glossary: string | undefined;
    introduction: string | undefined;
    recap: string | undefined;
    reflection: string | undefined;
    resources: string | undefined;
    title: string | undefined;
    lessons: Lesson[];
  },
  "lessons",
  "fid"
>;

interface Props {
  lesson: LessonType;
  totalLessons: number;
  module_id: string;
  moduleName: string;
  moveLessonUp: () => void;
  moveLessonDown: () => void;
  removeLessonHandler: (lesson_id: string) => void;
  videoStatusUpdateAfterUpload: (
    lesson_id: string,
    isError: boolean | undefined,
    deleted?: boolean
  ) => void;
  lessonIndex: number;
  deletePdfFileFromLesson: (lesson_id: string, pdf_url: string) => void;
  addPdfFileToLesson: (
    lesson_id: string,
    pdf_url: string,
    name: string
  ) => void;
}

const LessonContainer: React.FC<Props> = ({
  totalLessons,
  lesson,
  module_id,
  moduleName,
  moveLessonUp,
  moveLessonDown,
  removeLessonHandler,
  videoStatusUpdateAfterUpload,
  lessonIndex,
  addPdfFileToLesson,
  deletePdfFileFromLesson,
}) => {
  const {
    control,
    isCollapsed,
    setIsCollapsed,
    beforeFileUpload,
    encodeLessonVideo,
    errors,
    handleBlur,
    handleDeleteVideo,
    handleFileUpload,
    isLoading,
    removePdfFile,
    removePdf,
  } = useLessonContainer(
    lesson,
    module_id,
    moduleName,
    lessonIndex,
    videoStatusUpdateAfterUpload,
    addPdfFileToLesson,
    deletePdfFileFromLesson
  );

  return (
    <div className="mt-4">
      <BasicTitleContainer className=" !bg-[#D9D9D9]">
        <div className="flex items-center justify-between">
          <div className="">
            <div className="flex gap-x-4">
              <CaretDownFilled
                className={`${
                  isCollapsed ? "-rotate-90" : ""
                } text-[22px] hover:scale-125 duration-100 transition-transform`}
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
              <div className="flex">
                <CaretUpFilled
                  // onClick={question?.order === 1 ? undefined : handleMoveUp}
                  onClick={lesson?.order === 1 ? undefined : moveLessonUp}
                  disabled={lesson?.order === 1}
                  className={`${
                    lesson?.order === 1
                      ? "cursor-not-allowed text-gray-500"
                      : ""
                  }`}
                />
                <CaretDownFilled
                  disabled={lesson?.order === totalLessons}
                  onClick={
                    lesson?.order === totalLessons ? undefined : moveLessonDown
                  }
                  className={`${
                    lesson?.order === totalLessons
                      ? "cursor-not-allowed text-gray-500"
                      : ""
                  }`}
                />
              </div>
              <h3 className="">Lesson {lesson?.order}</h3>
            </div>
          </div>
          <DeleteOutlined
            className={
              totalLessons === 1
                ? "cursor-not-allowed text-gray-400 text-[22px]"
                : "text-backdrop text-[22px] hover:scale-125 duration-100 transition-transform"
            }
            onClick={() =>
              totalLessons === 1 ? null : removeLessonHandler(lesson?.id)
            }
            disabled={totalLessons === 1}
          />
        </div>
      </BasicTitleContainer>
      <BasicTwoBoxContainer
        className={`mt-4 transition-all delay-150 duration-300  ${
          isCollapsed ? "hidden h-0" : "block min-h-[400px]"
        }`}
        leftBox={
          <BasicContainer className="h-full p-6">
            {/* <div className='mb-1 flex items-center gap-x-4'></div> */}
            {lesson?.video?.status === "FINISHED" ? (
              <div className="h-[90%]">
                <BitmovinPlayer
                  dash={lesson?.video?.dash ?? ""}
                  hls={undefined}
                  poster={undefined}
                />
              </div>
            ) : (
              <VideoUploadV2
                azureContainerName={
                  process.env.REACT_APP_AZURE_VIDEO_STORAGE_CONTAINER_NAME ?? ""
                }
                encodingInProgress={lesson?.video?.status === "IN_PROGRESS"}
                fileName={lesson?.id}
                onSuccess={async () => {
                  await encodeLessonVideo.mutateAsync(
                    {
                      lesson_id: lesson?.id,
                      module_id: module_id,
                      video_file_name: lesson?.id,
                    },
                    {
                      onSuccess: () => {
                        videoStatusUpdateAfterUpload(lesson?.id, false, false);
                      },
                      onError: () => {
                        videoStatusUpdateAfterUpload(lesson?.id, true);
                      },
                    }
                  );
                }}
              />
            )}
            {lesson?.video?.status === "FINISHED" && (
              <Button type="text" className="mt-3" onClick={handleDeleteVideo}>
                Delete Video adsads
              </Button>
            )}
          </BasicContainer>
        }
        rightBox={
          <>
            <BasicContainerRichEditor
              className="h-full"
              name={`${moduleName}.lessons.${lessonIndex}.description`}
              control={control}
              richTextProps={{
                onBlur: (range, source, editor) =>
                  handleBlur(editor?.getHTML()),
              }}
              showSaving
              isSaving={isLoading}
              title="Description"
              showError
              isError={
                errors?.[moduleName]?.lessons?.[lessonIndex]?.description
              }
              errorMessage={
                errors?.[moduleName]?.lessons?.[lessonIndex]?.description
                  ? "Description must be at least 30 chars long"
                  : ""
              }
            >
              <div className="mt-4 flex justify-between w-full items-center">
                <h4>Lesson Resources</h4>
                <Upload
                  beforeUpload={(file) => beforeFileUpload(file)}
                  showUploadList={false}
                  customRequest={(options) => handleFileUpload(options?.file)}
                >
                  <Button loading={isLoading} icon={<UploadOutlined />}>
                    Attach Pdf
                  </Button>
                </Upload>
              </div>
              {lesson?.pdf && lesson?.pdf?.length > 0 && (
                <div className="flex flex-col gap-y-4 mt-2">
                  {lesson?.pdf?.map((pdf, index) => (
                    <div className="flex items-center gap-x-4">
                      <Tooltip title={pdf?.name}>
                        <a
                          href={pdf?.url}
                          download={`${lesson?.id}.pdf`}
                          className="text-primary truncate"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {pdf?.name}
                        </a>
                      </Tooltip>
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        loading={removePdf.isLoading}
                        onClick={() =>
                          removePdfFile(pdf?.url, lessonIndex, index)
                        }
                        className=""
                      />
                    </div>
                  ))}
                </div>
              )}
            </BasicContainerRichEditor>
          </>
        }
      />
    </div>
  );
};

export default LessonContainer;
