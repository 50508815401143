import { Button, Layout, Skeleton, Switch } from "antd";
import React, { useEffect } from "react";
import { CheckCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { CustomFooter } from "../../../common/layout/CustomFooter";
import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  initialEditorCourseProgramStoreState,
  initialFormCourseProgramStoreState,
} from "../stores/storeProgramCourseEditor";
import OverviewEditor from "./OverviewEditor";
import ModuleEditor from "./ModuleEditor";
import {
  useHookProgramCourseForm,
  useProgramTrainerFetchDetail,
} from "../hooks/useProgramCourse";
import { useParams } from "react-router-dom";
import AssessmentEditor from "./AssessmentEditor";
import { ContentMenuItem } from "../types/responseProgramCourse";
import ModalConfirm from "../../../common/components/ModalConfirm";
const { Sider, Content } = Layout;

interface LayoutProgramCourseEditorProps {}

const LayoutProgramCourseEditor: React.FC<
  LayoutProgramCourseEditorProps
> = () => {
  const [isExitClick, setIsExitClick] = React.useState(false);
  const [allFilled, setAllFilled] = React.useState(false);
  const { program_id } = useParams();

  useProgramTrainerFetchDetail(program_id!);

  const {
    moduleValidationState,
    assessmentValidationState,
    loadingIssionEmail,
  } = initialFormCourseProgramStoreState();

  const {
    activeContent,
    setActiveContent,
    programDetailData,
    loadingDetailPorgram,
    contentMenu,
  } = initialEditorCourseProgramStoreState();
  const {
    onSubmit,
    showModal,
    onSubmitIssuingEmail,
    confirmPublish,
    isDescriptionValid,
    addQuestion,
    formData,
  } = useHookProgramCourseForm();

  const renderContent = () => {
    switch (activeContent) {
      case "overview":
        return <OverviewEditor />;
      case "assessment":
        return <AssessmentEditor />;
      default:
        if (activeContent.startsWith("module-")) {
          return (
            <ModuleEditor moduleId={activeContent.replace("module-", "")} />
          );
        }
        return <OverviewEditor />;
    }
  };

  const isLive = programDetailData?.status === "live";

  const messageTitle = isExitClick
    ? "Are you sure you want to exit?"
    : `Are you sure you want to ${isLive ? "update" : "publish"}?`;

  const messageSubtitle = isExitClick
    ? "Please make sure you have saved your draft, or you will lose your progress."
    : isLive
    ? "The update will automatically go live for learners, and the previous data will be replaced."
    : "Once published, this program will go live and be visible to trainers and talents.";

  const confirmButtonText = isExitClick
    ? "Exit"
    : isLive
    ? "Update"
    : "Publish";

  useEffect(() => {
    const isModule = contentMenu?.filter((item) =>
      item.key.startsWith("module-")
    );
    const isModuleValid = isModule?.map(
      (item) => moduleValidationState[item.key.split("module-")[1]]
    );

    const isAllModuleValid = isModuleValid?.every((item) => item);
    const isAllFilled =
      isDescriptionValid && assessmentValidationState && isAllModuleValid;

    setAllFilled(isAllFilled);
  }, [
    moduleValidationState,
    assessmentValidationState,
    isDescriptionValid,
    contentMenu,
  ]);

  // setAllFilled(isDescriptionValid && assessmentValidationState)

  return (
    <AuthenticatedTemplate>
      <div className="h-screen !bg-[#F0F2F5] ">
        {/* HEADER */}
        <div className="w-full px-6 py-4 bg-secondaryBlue fixed z-50">
          <p className="text-xl font-bold">{programDetailData?.title}</p>
        </div>

        <Layout className="!bg-[#F0F2F5] ">
          <div className="h-full mt-12 flex w-full">
            <Sider
              width={293}
              className="h-screen overflow-y-auto mt-3 !bg-white-custom !fixed z-50"
            >
              <div className="pl-5 py-4">
                <h4 className="mb-3">Content</h4>

                <div className="space-y-">
                  {loadingDetailPorgram && !programDetailData ? (
                    <div className="">
                      {[...Array(3)].map((_, index) => (
                        <div className="p-3 " key={index}>
                          <Skeleton.Button active block />
                        </div>
                      ))}
                    </div>
                  ) : (
                    Array.isArray(contentMenu) &&
                    contentMenu.map((item: ContentMenuItem, index: number) => {
                      const isModule = item.key.startsWith("module-");
                      const moduleId = isModule
                        ? item.key.split("module-")[1]
                        : null;

                      const isModuleValid = moduleId
                        ? moduleValidationState[moduleId]
                        : false; // Get validation status for the module

                      const isDescription = item.key === "overview";

                      return (
                        <div className="border-b" key={index}>
                          <div
                            onClick={() => {
                              setActiveContent(item?.key);
                            }}
                            className={`${
                              activeContent === item.key
                                ? "bg-secondaryBlue border-r-4 border-backdrop"
                                : "border-r-4 border-transparent"
                            } px-3 py-2 hover:bg-secondaryBlue cursor-pointer transition-all flex items-center justify-between pr-3 relative`}
                          >
                            <p>{item?.label}</p>

                            {/* Show Check Icon for valid overview */}
                            {isDescription && isDescriptionValid && (
                              <CheckCircleOutlined className="absolute right-5" />
                            )}

                            {/* Show Check Icon for valid modules */}
                            {isModule && isModuleValid && (
                              <CheckCircleOutlined className="absolute right-5" />
                            )}

                            {/* Show Check Icon for valid assessment */}
                            {assessmentValidationState &&
                              item?.key === "assessment" && (
                                <CheckCircleOutlined className="absolute right-5" />
                              )}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>

                <div className="pr-4">
                  <h4 className="mt-5 mb-3">Settings</h4>
                  <div className="flex items-center justify-between">
                    <p>Allow learners to contact me</p>
                    <Switch
                      onChange={() =>
                        onSubmitIssuingEmail(programDetailData?.id!)
                      }
                      loading={loadingIssionEmail}
                      checked={programDetailData?.is_issuing_email}
                      disabled={loadingIssionEmail}
                      className={`${
                        programDetailData?.is_issuing_email
                          ? "bg-primary"
                          : "bg-gray-200"
                      }`}
                    />
                  </div>
                  <p className="text-primary">Mail to: trainer@skilledin.io</p>

                  <div className="flex items-center justify-between gap-x-4 mt-4">
                    <Button
                      loading={loadingDetailPorgram}
                      block
                      type="default"
                      disabled={loadingDetailPorgram || !allFilled}
                      onClick={() => confirmPublish(program_id || "")}
                    >
                      {programDetailData?.status === "live"
                        ? "Update"
                        : "Publish"}
                    </Button>
                    <Button
                      loading={loadingDetailPorgram}
                      block
                      type="primary"
                      disabled={loadingDetailPorgram}
                      className="bg-primary"
                      onClick={() => onSubmit(program_id!)}
                    >
                      Save Draft
                    </Button>
                  </div>
                </div>
              </div>

              <Button
                loading={loadingDetailPorgram}
                disabled={loadingDetailPorgram}
                className="cursor-pointer fixed  z-10 bottom-9 left-6 w-60 flex items-center justify-center bg-backdrop text-white"
                onClick={() => {
                  setIsExitClick(true);
                  showModal();
                }}
              >
                <LogoutOutlined className="" />
                exit course editor
              </Button>
            </Sider>

            {/* CONTENT */}
            <Layout className="ml-[293px] !bg-[#F0F2F5] !h-full mt-3 relative">
              <div className="p-4 !h-full !bg-[#F0F2F5]">
                <Content
                  className="h-full flex flex-col justify-between bg-[#F0F2F5] pb-10"
                  style={{
                    margin: 0,
                  }}
                >
                  {activeContent === "assessment" && (
                    <div className="sticky top-[3.8rem] z-50 bg-[#F0F2F5]">
                      <div className="py-2 px-4 bg-secondaryYellow shadow-custom-sm border rounded-xl flex items-center justify-between sticky top-10 z-20">
                        <h3 className="font-extrabold">
                          Total Questions: {formData?.assessments?.length} / 10
                        </h3>
                        <Button
                          onClick={addQuestion}
                          type="primary"
                          className="bg-primary"
                        >
                          New Question
                        </Button>
                      </div>
                    </div>
                  )}
                  {loadingDetailPorgram && !programDetailData ? (
                    <Skeleton active />
                  ) : (
                    renderContent()
                  )}
                </Content>
              </div>
              <div
                className="z-50 bottom-0 fixed"
                style={{ width: "calc(100% - 293px)" }}
              >
                <CustomFooter isLogged={true} />
              </div>
            </Layout>
          </div>
        </Layout>
      </div>

      <ModalConfirm
        messageTitle={messageTitle}
        messageSubtitle={messageSubtitle}
        confirmButtonText={confirmButtonText}
        cancelButtonText="Cancel"
      />
    </AuthenticatedTemplate>
  );
};

export default LayoutProgramCourseEditor;
