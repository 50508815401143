import React from "react";
import { HomeIcon } from "../../../assets/icon";
import { PageContent } from "../../../common/layout/PageContent";
import { PageHeader } from "../../../common/layout/PageHeader";
import SurveyConfigContent from "../components/SurveyConfigContent";
import { useParams } from "react-router-dom";

interface ConfigProgramSurveyProps {}

const ConfigProgramSurvey: React.FC<ConfigProgramSurveyProps> = () => {
  const { programId } = useParams<{ programId: string }>();

  const breadcrumbItems = [
    {
      path: "/",
      title: <HomeIcon className="text-gray-400" />,
    },
    {
      title: "Admin",
      className: "text-gray-400",
    },
    {
      title: "Dashboard",
      to: "/trainer-admin/school-dashboard/overview",
    },
    {
      title: "Program Curriculum",
      to: `/trainer-admin/school-dashboard/programs/${programId}`,
    },
    {
      title: "Pre- and Post- Survey",
    },
  ];

  return (
    <PageContent
      content={<SurveyConfigContent />}
      header={
        <PageHeader
          breadcrumbItems={breadcrumbItems}
          title="Configure Program Surveys"
          showBack
        />
      }
    />
  );
};

export default ConfigProgramSurvey;
