import {
  CaretDownFilled,
  CaretUpFilled,
  CloseOutlined,
  DeleteOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { Button, Input, Select, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHookSurveyConfigData } from "../hook/useSurveyConfig";
import { Controller } from "react-hook-form";
import TextArea from "antd/es/input/TextArea";
import { initialProgramStoreState } from "../store/programStore";
import { ProgramSkill } from "../types/programResponse";
import { useProgramFetchDetail } from "../hook/useProgram";
import { TypeQuestion } from "../types/surveyResponse";

interface SurveyConfigContentProps {}

const SurveyConfigContent: React.FC<SurveyConfigContentProps> = () => {
  const navigate = useNavigate();
  const { programId } = useParams();
  useProgramFetchDetail(programId!);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { programDetailData } = initialProgramStoreState();

  const [deletingIndex, setDeletingIndex] = useState<number | null>(null);

  const {
    control,
    handleSubmit,
    onSubmit,
    appendQuestion,
    questionFields,
    removeQuestion,
    getValues,
    setValue,
    errors,
    handleQuestionTypeChange,
    watchQuestions,
    reorderQuestion,
    isLoadingSurveyConfigData,
    surveyConfigData,
  } = useHookSurveyConfigData(programId!);

  const lastQuestionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isLoadingSurveyConfigData && lastQuestionRef.current) {
      if (isFirstLoad) {
        lastQuestionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }

    setTimeout(() => {
      setIsFirstLoad(false);
    }, 500);
  }, [questionFields.length, isFirstLoad, isLoadingSurveyConfigData]);

  const handleDelete = (index: number) => {
    setDeletingIndex(index);

    setTimeout(() => {
      removeQuestion(index);
      setDeletingIndex(null);
    }, 600);
  };

  // refs for questions and answers
  const questionRefs = useRef<any[]>([]);
  const answerRefs = useRef<any>({});

  // scroll to the first error field
  const scrollToError = () => {
    // check if there are question errors
    if (errors.questions) {
      for (let i = 0; i < questionFields.length; i++) {
        if (errors.questions[i]) {
          if (errors.questions[i]?.name) {
            questionRefs.current[i]?.scrollIntoView({});
            break;
          }

          // ff the answers have an error
          if (errors.questions[i]?.answers) {
            // @ts-ignore
            answerRefs.current[i]?.scrollIntoView({});
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    scrollToError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(errors)]);

  // submit handler
  const onSubmitCheck = (data: any) => {
    if (Object.keys(errors).length > 0) {
      scrollToError();
    } else {
      onSubmit(data);
    }
  };

  if (isLoadingSurveyConfigData) {
    return <div>loading...</div>;
  }

  return (
    <div className="rounded-xl border bg-white-custom px-4 py-6">
      <form onSubmit={handleSubmit(onSubmitCheck)}>
        <div className="flex items-center justify-between">
          <h3>Green Economy Reskilling</h3>
          <div className="flex items-center gap-x-4">
            <Button
              type="primary"
              className="bg-primary rounded"
              onClick={() =>
                navigate(
                  `/trainer-admin/school-dashboard/programs/survey-response/${programId}`
                )
              }
              disabled={(surveyConfigData?.questions?.length || 0) < 1}
            >
              Survey Responses
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              className="bg-primary rounded"
            >
              Set Survey
            </Button>
          </div>
        </div>

        {/* Switches for Pre-learning and Post-learning */}
        <div className="flex items-center gap-x-4 mt-3">
          <p>Use this survey for (one required):</p>
          <div className="flex items-center gap-x-2">
            <Controller
              name="is_pre_learning"
              control={control}
              rules={{
                validate: (value) => {
                  const isPostLearning = getValues("is_post_learning");
                  return (
                    value ||
                    isPostLearning ||
                    "At least one of Pre-learning or Post-learning must be selected."
                  );
                },
              }}
              render={({ field }) => (
                <>
                  <Switch
                    {...field}
                    checked={field.value}
                    className={`${field.value ? "bg-primary" : "bg-gray-200"}`}
                    onChange={(checked) => field.onChange(checked)}
                  />
                  <span>Pre-learning</span>
                </>
              )}
            />
          </div>
          <div className="flex items-center gap-x-2">
            <Controller
              name="is_post_learning"
              control={control}
              rules={{
                validate: (value) => {
                  const isPreLearning = getValues("is_pre_learning");
                  return (
                    value ||
                    isPreLearning ||
                    "At least one of Pre-learning or Post-learning must be selected."
                  );
                },
              }}
              render={({ field }) => (
                <>
                  <Switch
                    {...field}
                    checked={field.value}
                    className={`${field.value ? "bg-primary" : "bg-gray-200"}`}
                    onChange={(checked) => field.onChange(checked)}
                  />
                  <span>Post-learning</span>
                </>
              )}
            />
          </div>
        </div>

        {/* Show error message */}
        {errors?.is_pre_learning && errors?.is_post_learning && (
          <span className="error-message text-red-500">
            {errors?.is_pre_learning?.message ||
              errors?.is_post_learning?.message}
          </span>
        )}
        {/* {errors.is_post_learning && (
          <span className="error-message text-red-500">
            {errors.is_post_learning.message}
          </span>
        )} */}

        <div>
          <table className="table-auto w-full border-separate border-spacing-y-3 mt-2">
            <tbody>
              <tr className="">
                <td className="w-52">
                  <div className="text-base flex items-center gap-x-3">
                    <FieldTimeOutlined />
                    Estimated Time
                  </div>
                </td>
                <td className="w-[20px]">:</td>
                <td>
                  <Controller
                    name="time_estimate"
                    control={control}
                    rules={{
                      required: `Time estimate is required`,
                      validate: (value) =>
                        value > 0 || "Time estimate must be greater than 0",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={`w-44 ${
                          errors?.time_estimate ? "border-red-500" : ""
                        }`}
                        defaultValue={field.value}
                        onChange={(value) => field.onChange(value)}
                      >
                        <Select.Option value={5}>5 minutes</Select.Option>
                        <Select.Option value={10}>10 minutes</Select.Option>
                        <Select.Option value={15}>15 minutes</Select.Option>
                        <Select.Option value={20}>20 minutes</Select.Option>
                      </Select>
                    )}
                  />
                  {errors?.time_estimate && (
                    <span className="error-message text-red-500">
                      {errors.time_estimate?.message}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="w-52">
                  <div className="text-base flex items-center gap-x-3">
                    <QuestionOutlined />
                    Why take this survey?
                  </div>
                </td>
                <td className="w-[20px]">:</td>
                <td>
                  <Controller
                    name="survey_reason"
                    control={control}
                    rules={{ required: `Reason is required` }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className={`w-full ${
                          errors?.survey_reason ? "border-red-500" : ""
                        }`}
                        placeholder="write..."
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  {errors?.survey_reason && (
                    <span className="error-message text-red-500">
                      {errors.survey_reason?.message}
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* PART QUESTION */}
        <div className="mt-6 !bg-secondaryYellow top-0 sticky z-50 rounded-lg px-4 py-2 flex items-center justify-between">
          <h3>Total Questions: {questionFields.length}/10</h3>
          <Button
            type="primary"
            className="bg-primary rounded"
            onClick={() =>
              appendQuestion({
                id: "",
                name: "",
                skill_id: "",
                type: TypeQuestion.TRUE_OR_FALSE,
                answers: [""],
                skill: "",
              })
            }
          >
            New Question
          </Button>
        </div>

        <div>
          {questionFields.map((question, index) => {
            return (
              <div
                key={question.id}
                className={`space-y-6 new-question ${
                  deletingIndex === index ? "deleting-question" : ""
                }`}
              >
                <div className="mt-6 !bg-secondaryBlue top-12 sticky z-40 rounded-lg px-4 py-3 flex items-center justify-between">
                  <div className="flex items-center gap-x-3">
                    <div className="flex items-center">
                      <CaretUpFilled
                        onClick={() => reorderQuestion(index, index - 1)}
                        className={
                          index === 0 ? "text-gray-400" : "cursor-pointer"
                        }
                        disabled={index === 0}
                        style={{ pointerEvents: index === 0 ? "none" : "auto" }}
                      />
                      <CaretDownFilled
                        onClick={() => reorderQuestion(index, index + 1)}
                        className={
                          index === questionFields.length - 1
                            ? "text-gray-400"
                            : "cursor-pointer"
                        }
                        disabled={index === questionFields.length - 1}
                        style={{
                          pointerEvents:
                            index === questionFields.length - 1
                              ? "none"
                              : "auto",
                        }}
                      />
                    </div>
                    <h4>Question {index + 1}</h4>
                  </div>
                  <DeleteOutlined
                    className="text-lg"
                    onClick={() => handleDelete(index)}
                  />
                </div>

                <div className="flex items-center gap-x-4 mx-6">
                  <div className="flex items-center gap-x-2">
                    <p className="font-semibold">Question Type:</p>
                    <Controller
                      name={`questions.${index}.type`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="w-44"
                          {...field}
                          onChange={(value) =>
                            handleQuestionTypeChange(value, index)
                          } // Call handleQuestionTypeChange
                        >
                          <Select.Option value="multiple_choice">
                            Multiple Choice
                          </Select.Option>
                          <Select.Option value="single_choice">
                            Single Choice
                          </Select.Option>
                          <Select.Option value="true_or_false">
                            True or False
                          </Select.Option>
                        </Select>
                      )}
                    />
                  </div>
                  {programDetailData?.skills && (
                    <div className="flex items-center gap-x-2">
                      <p className="font-semibold">Choose Skill (optional):</p>
                      <Controller
                        name={`questions.${index}.skill_id`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            className="w-44"
                            {...field}
                            onChange={(value) => field.onChange(value)}
                          >
                            <Select.Option value="">None</Select.Option>
                            {programDetailData?.skills?.map(
                              (skill: ProgramSkill, indexSkill: number) => {
                                return (
                                  <Select.Option
                                    key={indexSkill}
                                    value={skill?.id}
                                  >
                                    {skill?.name}
                                  </Select.Option>
                                );
                              }
                            )}
                          </Select>
                        )}
                      />
                    </div>
                  )}
                </div>

                <div className=" px-6">
                  <Controller
                    name={`questions.${index}.name`}
                    control={control}
                    rules={{ required: `Question is required` }}
                    render={({ field }) => (
                      <TextArea
                        // @ts-ignore
                        ref={(el) => (questionRefs.current[index] = el)}
                        className={`p-4 border rounded-lg ${
                          errors?.questions?.[index]?.name
                            ? "border-red-500"
                            : ""
                        }`}
                        placeholder="Type your question..."
                        // allowClear
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.questions?.[index]?.name && (
                    <span className="error-message text-red-500">
                      {errors.questions[index].name.message}
                    </span>
                  )}

                  {watchQuestions &&
                    watchQuestions[index]?.type !==
                      TypeQuestion.TRUE_OR_FALSE && (
                      <>
                        <div
                          className="mt-5 p-4 border rounded-lg"
                          style={
                            errors?.questions?.[index]?.answers && {
                              border: "1px solid red",
                            }
                          }
                        >
                          <div className="mb-5 flex items-center justify-between">
                            <h4>Answers</h4>
                            <PlusOutlined
                              onClick={() => {
                                const currentAnswers =
                                  (getValues(
                                    `questions.${index}.answers`
                                  ) as string[]) || [];
                                const newAnswers = [...currentAnswers, ""];
                                setValue(
                                  `questions.${index}.answers`,
                                  newAnswers
                                );
                              }}
                            />
                          </div>

                          {/* Field Array for Answers */}
                          <div className={`new-question`}>
                            <Controller
                              name={`questions.${index}.answers`}
                              control={control}
                              rules={{
                                validate: (answers) =>
                                  answers.every(
                                    (answer: string) => answer.trim() !== ""
                                  ) || "Each answer is required", // Custom validation rule
                              }}
                              render={({ field }) => (
                                <div>
                                  {field.value?.map(
                                    (answer: string, answerIndex: number) => (
                                      <div
                                        key={answerIndex}
                                        className="flex items-center gap-x-2 mb-2"
                                        ref={(el) =>
                                          (answerRefs.current[index] = el)
                                        } // Set ref to each answer input
                                      >
                                        <Input
                                          className="w-full"
                                          placeholder="Type your answer..."
                                          value={answer}
                                          onChange={(e) => {
                                            const newAnswers = [...field.value];
                                            newAnswers[answerIndex] =
                                              e.target.value;
                                            field.onChange(newAnswers);
                                          }}
                                        />
                                        <CloseOutlined
                                          onClick={() => {
                                            const newAnswers =
                                              field.value.filter(
                                                (_: string, idx: number) =>
                                                  idx !== answerIndex
                                              );
                                            field.onChange(newAnswers);
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        {errors?.questions?.[index]?.answers && (
                          <span className="text-red-500">
                            {errors.questions[index].answers.message}
                          </span>
                        )}
                      </>
                    )}
                  <div
                    ref={
                      index === questionFields.length - 1
                        ? lastQuestionRef
                        : null
                    }
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </form>

      <style>{`
        .new-question {
          animation: slide-in 0.9s ease forwards;
        }

        @keyframes slide-in {
          from {
            transform: translateY(-50px); /* Start from above */
            opacity: 0;
          }
          to {
            transform: translateY(0); /* End in normal position */
            opacity: 1;
          }
        }

        .deleting-question {
          animation: slide-up-and-fade-out 0.3s ease forwards;
        }

        @keyframes slide-up-and-fade-out {
          from {
            transform: translateY(0); /* Start at current position */
            opacity: 1;
          }
          to {
            transform: translateY(-30px); /* Move upward */
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default SurveyConfigContent;
