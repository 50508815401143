import { useMutation } from "react-query"
import httpRequest from "../../../config/config"
import { message } from "antd"

type EncodeLessonVideoData = {
  lesson_id: string
  module_id: string
  video_file_name: string
}

const encodeLessonVideo = (data: EncodeLessonVideoData) => {
  return httpRequest.post("/school/my-courses/encode-lesson-video", data)
}

const deleteLessonVideo = (data: EncodeLessonVideoData) => {
  return httpRequest.post("/school/my-courses/remove-lesson-video", data)
}

export const useEncodeLessonVideo = () => {
  return useMutation((data: EncodeLessonVideoData) => encodeLessonVideo(data), {
    onSuccess: () => { },
    mutationKey: "encodeLessonVideo",
  })
}

export const useDeleteLessonVideo = () => {
  return useMutation((data: EncodeLessonVideoData) => deleteLessonVideo(data), {
    mutationKey: "deleteLessonVideo",
    onSuccess: () => {
      message.success(
        "Video deleted successfully. You'll see the changes soon, or you can refresh the page."
      )
    },
  })
}


// COURSE PROGRAM TRAINER
// Encode lesson video program with structured data (including video file name)
const encodeLessonVideoProgram = (data: EncodeLessonVideoData) => {
  return httpRequest.post(`/school/programs/trainer/${data.lesson_id}/vod/${data.video_file_name}`, data)
}

// Delete lesson video program by id and blob name
const deleteLessonVideoProgram = (id: string, blob_name: string) => {
  return httpRequest.delete(`/school/programs/trainer/${id}/vod/${blob_name}`)
}

// Hook for encoding a lesson video using `EncodeLessonVideoData`
export const useEncodeLessonVideoProgram = () => {
  return useMutation(
    (data: EncodeLessonVideoData) => encodeLessonVideoProgram(data),
    {
      onSuccess: () => {
        message.open({
          key: "upload-video-program",
          content: "Upload video successfully",
          type: "success",
        })
      },
      mutationKey: "encodeLessonVideoProgram",
    }
  )
}

// Hook for deleting a lesson video by id and blob name
export const useDeleteLessonVideoProgram = () => {
  return useMutation(
    ({ id, blob_name }: { id: string; blob_name: string }) => deleteLessonVideoProgram(id, blob_name),
    {
      onSuccess: () => {
        message.open({
          key: "delete-video-program",
          content: "Video deleted successfully. You'll see the changes soon, or you can refresh the page.",
          type: "success",
        })

      },
      mutationKey: "deleteLessonVideoProgram",
    }
  )
}
