import { Button, Empty, Select, Skeleton, Tag } from 'antd'
import React from 'react'
import { initialSurveyResponseStoreState } from '../store/surveyStore'
import dayjs from 'dayjs'

interface SurveyResponseContentProps {

}

const SurveyResponseContent: React.FC<SurveyResponseContentProps> = () => {
  const {
    surveyResponsesData,
    loadingSurveyResponsesData,
    queryParams,
    setQueryParams,
    selectOptions,
  } = initialSurveyResponseStoreState()

  const formatType = (type: string) => {
    switch (type) {
      case 'true_or_false':
        return 'True or False'
      case 'single_choice':
        return 'Single Choice'
      case 'multiple_choice':
        return 'Multiple Selection'
      default:
        return type
    }
  }

  console.log('selectOptions', selectOptions)

  return (
    <div>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <Button
            onClick={() => setQueryParams({
              type: 'pre',
              question_id: queryParams?.question_id
            })}
            type='primary'
            className={`rounded-l ${queryParams?.type === 'pre' ? 'bg-primary' : 'bg-white text-backdrop'}`}>
            Pre-Learning</Button>

          <Button
            onClick={() => setQueryParams({
              type: 'post',
              question_id: queryParams?.question_id
            })}
            type='primary'
            className={`rounded-r ${queryParams?.type === 'pre' ? 'bg-white text-backdrop' : 'bg-primary'}`}>
            Post-Learning
          </Button>
        </div>

        <div>
          <Select defaultValue="" className='!border-primary' style={{ width: 150 }} onChange={(value) => {
            setQueryParams({
              type: queryParams?.type,
              question_id: value ? value : ''
            })
          }}>
            <Select.Option value="">All Question</Select.Option>
            {selectOptions.map((option, index) => (
              <Select.Option key={index} value={option.value}>
                Question {index + 1}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      {/* Survey Responses */}
      {loadingSurveyResponsesData ? (<SurveySkeleton />) : (
        <div className='space-y-4 mt-5'>
          {surveyResponsesData && surveyResponsesData?.map((response, index) => (
            <div className='bg-white px-5 py-3 rounded'>
              <div className='flex items-center justify-between'>
                <p className='text-base font-semibold'>{index + 1}. {" "} {response?.name}</p>

                <Tag color='yellow' className='text-brown bg-secondaryYellow px-4 py-1 rounded-full font-semibold'>{formatType(response?.type)}</Tag>
              </div>

              {response?.responses?.length > 0 ? (
                <table className='w-full mt-3 border-separate border-spacing-y-3'>
                  <thead>
                    <tr className=''>
                      <th className='px-4 text-left p-2 w-[9%]'>Date</th>
                      <th className='px-4 text-left p-2 w-[9%]'>Time</th>
                      <th className='px-4 text-left p-2 w-[9%]'>Country</th>
                      <th className='px-4 text-left p-2 w-1/4'>Responses</th>
                    </tr>
                  </thead>

                  <tbody className=''>
                    {response?.responses?.map((res, index) => (
                      <tr key={index} className='bg-white !border !border-gray-300 !rounded-lg'
                      >
                        <td className='px-4 py-2 w-[9%] border-l border-t border-b border-gray-200 rounded-l-lg'>{dayjs(res?.created_at).format('DD MMM YYYY')}</td>
                        <td className='px-4 py-2 w-[9%] border-t border-b border-gray-200'>{dayjs(res?.created_at).format('h:mm A')}</td>
                        <td className='px-4 py-2 w-[9%] border-t border-b border-gray-200'>{res?.country || "-"}</td>
                        <td className='px-4 py-2 w-1/4 border-r border-t border-b border-gray-200 rounded-r-lg'>
                          <ul className='list-disc pl-4'>
                            {res?.answers.map((answer, answerIndex) => (
                              <li key={answerIndex}>{answer}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Empty description='No responses yet' />
              )}
            </div>
          ))}
        </div>
      )}

    </div>
  )
}

export default SurveyResponseContent


const SurveySkeleton: React.FC = () => {
  return (
    <div>
      {/* Survey Responses Skeleton */}
      <div className='space-y-4 mt-5'>
        {[...Array(3)].map((_, index) => (
          <div key={index} className='bg-white px-5 py-3 rounded'>
            <div className='flex gap-x-5 items-center justify-between'>
              <Skeleton.Input block active style={{ width: '100%', height: 24 }} />
              <Skeleton.Button active style={{ width: 80, height: 24 }} />
            </div>

            <div className='w-full mt-3'>
              <Skeleton.Input block active style={{ width: '100%', height: 24 }} />
              <div className='w-full mt-3'>
                {[...Array(2)].map((_, i) => (
                  <div key={i} className='flex items-center gap-2 mt-2'>
                    <Skeleton.Input active style={{ height: 24 }} />
                    <Skeleton.Input active style={{ height: 24 }} />
                    <Skeleton.Input active style={{ height: 24 }} />
                    <Skeleton.Input block active style={{ height: 24 }} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
