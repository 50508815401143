import { Navigate, Route, Routes } from "react-router-dom"
import Dashboard from "../modules/dashboard"
import Courses from "../modules/courses"
import Students from "../modules/students"
import Login from "../modules/auth/page/Login"
import ResetPassword from "../modules/auth/page/ResetPassword"
import Logout from "../modules/auth/page/Logout"
import NotFound from "../modules/auth/page/NotFound"
import { useMsal } from "@azure/msal-react"
import React from "react"
import PageLayout from "../common/layout/PageLayout"
import Onboarding from "../modules/onboarding"
import TrainerAdmin from "../modules/trainer-admin"
import { useAdmin } from "../common/hooks/useAdmin"
import CourseEditor from "../modules/courses/pages/CourseEditor.tsx"
import { WorkshopDetail } from "../modules/courses/pages/WorkshopDetail.tsx"
import ProgramCourseEditor from "../modules/courses/pages/LayoutProgramCourseEditor.tsx"

function Index() {
  const instance = useMsal()
  const activeAccount = instance?.accounts[0]
  // const activeAccount = true;
  function ProtectRoutes({ children }) {
    return activeAccount ? children : <Navigate to="/login" />
  }
  function PreventRoutes({ children }) {
    return activeAccount ? <Navigate to="/dashboard/" /> : children
  }
  const isAdmin = useAdmin()

  const routeAdmin = [
    <Route
      path="/trainer-admin/*"
      element={
        <ProtectRoutes>
          <PageLayout>
            <TrainerAdmin />
          </PageLayout>
        </ProtectRoutes>
      }
    />,
  ]
  const routeInstructor = []

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PreventRoutes>
            <Login />
          </PreventRoutes>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PreventRoutes>
            <ResetPassword />
          </PreventRoutes>
        }
      />
      <Route
        path="/"
        element={
          activeAccount ? (
            <ProtectRoutes>
              <PageLayout>
                <Dashboard />
              </PageLayout>
            </ProtectRoutes>
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/logout"
        element={
          <PreventRoutes>
            <Logout />
          </PreventRoutes>
        }
      />
      <Route
        path="/onboarding/*"
        element={
          <ProtectRoutes>
            <PageLayout isSidebar={false} isFooter={false} isOnboard={true}>
              <Onboarding />
            </PageLayout>
          </ProtectRoutes>
        }
      />
      <Route
        path="/dashboard/*"
        element={
          <ProtectRoutes>
            <PageLayout>
              <Dashboard />
            </PageLayout>
          </ProtectRoutes>
        }
      />
      ,
      <Route
        path="/course/editor/:skill_id/:level_id"
        element={
          <ProtectRoutes>
            <PageLayout isSidebar={false} isHeader={false} isFooter={false}>
              <CourseEditor />
            </PageLayout>
          </ProtectRoutes>
        }
      />
      ,
      <Route
        path="/program-course/editor/:program_id/"
        element={
          <ProtectRoutes>
            <PageLayout isSidebar={false} isHeader={false} isFooter={false}>
              <ProgramCourseEditor />
            </PageLayout>
          </ProtectRoutes>
        }
      />
      ,
      <Route
        path="/courses/*"
        element={
          <ProtectRoutes>
            <PageLayout>
              <Courses />
            </PageLayout>
          </ProtectRoutes>
        }
      />
      ,
      <Route
        path="/students/*"
        element={
          <ProtectRoutes>
            <PageLayout>
              <Students />
            </PageLayout>
          </ProtectRoutes>
        }
      />
      ,
      <Route
        path="/programs-and-workshops/:id"
        element={
          <ProtectRoutes>
            <PageLayout
              isSidebar={false}
              isHeader={false}
              isFooter={false}
              isBgColor={false}
            >
              <WorkshopDetail />
            </PageLayout>
          </ProtectRoutes>
        }
      />

      ,{isAdmin ? routeAdmin : routeInstructor}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Index
