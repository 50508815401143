import React from 'react'
import { HomeIcon } from '../../../assets/icon'
import { useParams } from 'react-router-dom'
import SurveyResponseContent from '../components/SurveyResponseContent'
import { PageHeader } from '../../../common/layout/PageHeader'
import { PageContent } from '../../../common/layout/PageContent'
import { useHookSurveyResponse } from '../hook/useSurveyResponse'
import { initialProgramStoreState } from '../store/programStore'
import { useProgramFetchDetail } from '../hook/useProgram'

interface ResponseProgramSurveyProps {

}

const ResponseProgramSurvey: React.FC<ResponseProgramSurveyProps> = () => {

  const { programId } = useParams<{ programId: string }>()

  useHookSurveyResponse(programId!)
  useProgramFetchDetail(programId!)

  const { programDetailData, loadingDetailPorgram } = initialProgramStoreState()

  const breadcrumbItems = [
    {
      path: "/",
      title: <HomeIcon className="text-gray-400" />
    },
    {
      title: "Admin",
      className: "text-gray-400",
    },
    {
      title: "Dashboard",
      to: "/trainer-admin/school-dashboard/overview",
    },
    {
      title: "Program Curriculum",
      to: `/trainer-admin/school-dashboard/programs/${programId}`,
    },
    {
      title: "Pre- and Post- Survey",
      to: `/trainer-admin/school-dashboard/programs/survey-config/${programId}`,
    },
    {
      title: "Survey Responses",
    }
  ]

  return (
    <PageContent
      content={<SurveyResponseContent />}
      header={
        <PageHeader
          breadcrumbItems={breadcrumbItems}
          title={`Survey Responses for ${programDetailData?.title}`}
          showBack
          isLoading={loadingDetailPorgram}
        />
      }
    />
  )
}

export default ResponseProgramSurvey
