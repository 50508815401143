import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Empty, Spin, Tag } from "antd"
import { ArrowDownOutlined } from "@ant-design/icons"
import { Workshop } from "../hooks/useWorkshop"
import dayjs from "dayjs"

interface Props {
  data: Workshop[]
  isLoading: boolean
  total: number
  setParams: React.Dispatch<
    React.SetStateAction<{
      page: number
      title: string
      limit: number
    }>
  >
}

const TablePrograms: React.FC<Props> = ({
  isLoading,
  data,
  total,
  setParams,
}) => {
  const navigate = useNavigate()

  const getItemColor = (status: string) => {
    switch (status) {
      case 'draft':
        return { backgroundColor: 'gray', color: 'white' }
      case 'in_progress':
        return { backgroundColor: '#FFEFA0', color: 'black' }
      case 'pre-launch':
        return { backgroundColor: '#D8EAC6', color: 'black' }
      case 'live':
        return { backgroundColor: '#86C769', color: 'white' }
      default:
        return { backgroundColor: 'gray', color: 'white' }
    }
  }

  const formatStatusText = (status: string) => {
    return status
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return (
    <table className="w-full mt-3">
      <thead className="grid grid-cols-12 mb-3">
        <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-4">
          Course Title
        </td>
        <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
          Release Date
        </td>
        <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-5">
          Status
        </td>
      </thead>
      <Spin spinning={isLoading}>
        {data.length > 0 ? (
          <>
            {data?.map((item, index) => (
              <tbody className="grid grid-cols-12" key={index}>
                <td className="font-medium  border-gray-300 py-2 px-5 text-backdrop col-span-4 flex items-center">
                  {item?.title}
                </td>
                <td className="font-medium  border-gray-300 py-2 px-5 text-backdrop col-span-3 flex items-center">
                  {item?.release_date
                    ? dayjs(item.release_date).format("DD MMM YYYY")
                    : item.mode}
                </td>

                <td className="font-medium  border-gray-300 py-2 px-5 text-backdrop col-span-5">
                  <div className="flex items-center justify-between">
                    <Tag
                      style={getItemColor(item.status)}
                      className="first-letter:capitalize py-0.5 px-3 border-none"
                    >
                      {formatStatusText(item.status)}
                    </Tag>
                    <Button
                      onClick={() =>
                        navigate(`/program-course/editor/${item?.id}`)
                      }
                      className="border-primary"
                    >
                      Edit
                    </Button>
                  </div>
                </td>
              </tbody>
            ))}
            {total > data.length && (
              <div className=" text-center mt-3">
                <ArrowDownOutlined
                  className=" cursor-pointer"
                  onClick={() =>
                    setParams((prev) => ({ ...prev, page: prev.page + 1 }))
                  }
                />
              </div>
            )}
          </>
        ) : (
          <div className="py-4 border text-center ">
            <Empty />
          </div>
        )}
      </Spin>
    </table>
  )
}

export default TablePrograms
