import React, { useState, useEffect } from "react"
import { SavingTag } from "../../../../common/components/SavingTag"
import { BasicContainer } from "../CourseEditor/BasicContainer"
import "./custom.style.css"
import { SimpleRichText } from "./SimpleRichEditor"
import { ReactQuillProps } from "react-quill"

interface Props {
  title?: string
  richTextClassName?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  richTextProps?: ReactQuillProps
  showSaving?: boolean
  isSaving?: boolean
  showError?: boolean
  errorMessage?: string
  isError?: boolean
  children?: React.ReactNode
  value?: string // To pass the controlled value
  onChange?: (value: string) => void // To handle changes
}

export const RichContainerEditor: React.FC<Props> = ({
  title,
  richTextClassName,
  disabled,
  showSaving,
  isSaving,
  className,
  showError,
  errorMessage,
  isError,
  children,
  placeholder,
  value = "", // Initialize the value prop with an empty string
  onChange, // onChange handler passed as a prop
  richTextProps,
}) => {
  const [localValue, setLocalValue] = useState(value) // Local state for the rich text editor

  // Sync local value with the value prop whenever it changes
  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const handleChange = (newValue: string) => {
    setLocalValue(newValue) // Update local state
    if (onChange) {
      onChange(newValue) // Call onChange prop if provided
    }
  }

  return (
    <BasicContainer className={className}>
      {showSaving ? (
        <div className="flex w-full items-center justify-between">
          <h3>{title}</h3>
          <SavingTag isSaving={isSaving ?? false} />
        </div>
      ) : (
        <h3>{title}</h3>
      )}

      <SimpleRichText
        value={localValue} // Use the local state value
        className={`mt-4 h-[208px] mb-4 font-default font-normal bg-white ${richTextClassName}`}
        placeholder={placeholder ?? "Input content"}
        readOnly={disabled ?? false}
        handleChange={handleChange} // Use local handler for onChange
        {...richTextProps}
      />

      {showError && isError && (
        <div className="mt-2 text-danger text-sm">{errorMessage}</div>
      )}

      {children}
    </BasicContainer>
  )
}
