import { useEffect, useState } from "react"
import { initialSurveyResponseStoreState } from "../store/surveyStore"
import { RESPONSES_SURVEY_CONFIG, SurveyResponseList } from "../types/surveyResponse"
import { useFetchDetail } from "../../../common/hooks/generic_rest"
import { SURVEY_CONFIG_ENDPOINT } from "../apis/surveyEndpoint"

export const useHookSurveyResponse = (programId: string) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const {
    queryParams,
    surveyResponsesData,
    setSurveyResponsesData,
    setLoadingSurveyResponsesData,
    setErrorSurveyResponsesData,
    setSelectOptions
  } = initialSurveyResponseStoreState()

  const {
    data: surveyData,
    isLoading: isLoadingSurveyResponsesData,
    isError: isErrorSurveyResponsesData,
    refetch: refetchDetailSurvey,
  } = useFetchDetail<SurveyResponseList>(
    `${SURVEY_CONFIG_ENDPOINT.RESPONSES(programId)}`,
    {
      queryParams,
      queryName: RESPONSES_SURVEY_CONFIG,
      showErrorMessage: true,
    }
  )

  useEffect(() => {
    if (surveyData) {
      setSurveyResponsesData(surveyData || [])
      setLoadingSurveyResponsesData(isLoadingSurveyResponsesData)
      setErrorSurveyResponsesData(isErrorSurveyResponsesData)
    }
  }, [
    surveyData,
    isLoadingSurveyResponsesData,
    isErrorSurveyResponsesData,
    setSurveyResponsesData,
    setLoadingSurveyResponsesData,
    setErrorSurveyResponsesData,
  ])

  useEffect(() => {
    setLoadingSurveyResponsesData(true)
    refetchDetailSurvey().then(() => {
      setLoadingSurveyResponsesData(false)
      setErrorSurveyResponsesData(isErrorSurveyResponsesData)
    })
  }, [queryParams, refetchDetailSurvey, setLoadingSurveyResponsesData, setErrorSurveyResponsesData, isErrorSurveyResponsesData])

  useEffect(() => {
    if (!isErrorSurveyResponsesData && isFirstLoad) {
      setSelectOptions(
        surveyResponsesData.map((survey) => ({
          label: survey.id,
          value: survey.id,
        }))
      )
      setTimeout(() => {
        setIsFirstLoad(false)
      }, 1000)
    }
  }, [surveyResponsesData, isErrorSurveyResponsesData, isFirstLoad, setSelectOptions])
}
