import { useEffect } from "react";
import { useFetchDetail, useWrite } from "../../../common/hooks/generic_rest";
import {
  DETAIL_SURVEY_CONFIG,
  SurveyResponse,
  TypeQuestion,
} from "../types/surveyResponse";
import { SURVEY_CONFIG_ENDPOINT } from "../apis/surveyEndpoint";
import { useFieldArray, useForm } from "react-hook-form";
import { message } from "antd";

export const useHookSurveyConfigData = (programId: string) => {
  const {
    data: surveyConfigData,
    isLoading: isLoadingSurveyConfigData,
    // isError: isErrorSurveyConfigData,
    isFetching,
    // refetch: refetchDetailSurvey,
  } = useFetchDetail<SurveyResponse>(
    `${SURVEY_CONFIG_ENDPOINT.DETAIL(programId)}`,
    {
      queryName: DETAIL_SURVEY_CONFIG,
      showErrorMessage: true,
    }
  );

  // define the form default values
  // const defaultValues = {
  //   is_post_learning: true,
  //   is_pre_learning: true,
  //   questions: [
  //     {
  //       answers: [""],
  //       id: "",
  //       name: "",
  //       skill_id: "",
  //       skill: "",
  //       type: TypeQuestion.TRUE_OR_FALSE,
  //     },
  //   ],
  //   survey_reason: "",
  //   time_estimate: 0,
  // };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<{
    is_post_learning: boolean;
    is_pre_learning: boolean;
    questions?: {
      answers: string[];
      id: string;
      name: string;
      skill_id: string;
      skill: string;
      type: TypeQuestion;
      order?: number;
      created_at?: string;
      updated_at?: string;
    }[];
    survey_reason: string;
    time_estimate: number;
  }>({
    // defaultValues: surveyConfigData
    //   ? {
    //       is_post_learning: surveyConfigData.is_post_learning,
    //       is_pre_learning: surveyConfigData.is_pre_learning,
    //       questions:
    //         surveyConfigData?.questions?.map((question) => ({
    //           ...question,
    //           skill: question.skill?.name || "",
    //         })) || undefined,
    //       survey_reason: surveyConfigData.survey_reason,
    //       time_estimate: surveyConfigData.time_estimate,
    //     }
    //   : defaultValues,
  });

  // use useFieldArray for dynamic fields (questions and answers)
  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
    replace,
    move,
  } = useFieldArray({
    control,
    name: "questions",
  });

  // const { fields: answerFields, remove: removeAnswer } = useFieldArray({
  //   control,
  //   // @ts-ignore
  //   name: "questions[0].answers",
  // });

  useEffect(() => {
    if (surveyConfigData && !isFetching) {
      // set other form values
      setValue("is_pre_learning", surveyConfigData.is_pre_learning);
      setValue("is_post_learning", surveyConfigData.is_post_learning);
      setValue("time_estimate", surveyConfigData.time_estimate);
      setValue("survey_reason", surveyConfigData.survey_reason);

      if (surveyConfigData.questions) {
        replace(
          surveyConfigData.questions.map((question) => ({
            id: question.id,
            name: question.name,
            skill_id: question.skill_id || "",
            skill: question.skill?.name || "",
            type: question.type,
            answers: question.answers || [""],
            order: question.order,
            created_at: question.created_at,
            updated_at: question.updated_at,
          }))
        );
      }
    }
  }, [
    surveyConfigData,
    setValue,
    replace,
    isLoadingSurveyConfigData,
    isFetching,
  ]);

  const watchQuestions = watch("questions");

  // handle question type change
  const handleQuestionTypeChange = (value: TypeQuestion, index: number) => {
    setValue(`questions.${index}.type`, value);

    // if type is not true_or_false, ensure at least 2 answers
    if (value !== TypeQuestion.TRUE_OR_FALSE) {
      const currentAnswers = getValues(`questions.${index}.answers`);
      if (!currentAnswers || currentAnswers.length < 2) {
        setValue(`questions.${index}.answers`, ["", ""]); // Set 2 default empty answers
      }
    } else {
      // if switching to true_or_false, clear the answers
      setValue(`questions.${index}.answers`, []);
    }

    // trigger validation/render manually
    trigger(`questions.${index}.type` as const); // This will force a re-render after setValue
    trigger(`questions.${index}.answers` as const); // This will refresh answers input
  };

  // useEffect to detect changes in question types explicitly
  useEffect(() => {
    if (watchQuestions) {
      watchQuestions.forEach((question, index) => {
        // if question type changes, ensure correct answer fields are displayed
        if (question.type === TypeQuestion.TRUE_OR_FALSE) {
          setValue(`questions.${index}.answers`, []);
        } else {
          const currentAnswers = getValues(`questions.${index}.answers`);
          if (!currentAnswers || currentAnswers.length < 2) {
            setValue(`questions.${index}.answers`, ["", ""]);
          }
        }
      });
    }
  }, [watchQuestions, setValue, getValues]);

  // function to reorder questions by index
  const reorderQuestion = (fromIndex: number, toIndex: number) => {
    const totalQuestions = questionFields.length;

    // ensure the move is within bounds
    if (toIndex < 0 || toIndex >= totalQuestions) {
      return; // Don't move if out of bounds
    }

    move(fromIndex, toIndex); // move question using useFieldArray's move function
  };

  // Save changes
  const { mutate: saveData } = useWrite<any, any>(
    SURVEY_CONFIG_ENDPOINT.SAVE(programId),
    "post",
    () => {
      message.open({
        key: "change-survey-config-success",
        content: "Save Changes Success",
        type: "success",
      });
    },
    () => message.error("An error occurred")
  );

  const onSubmit = (data: any) => {
    message.open({
      key: "change-survey-config-success",
      content: "Loading...",
      type: "loading",
    });
    saveData(data);
  };

  return {
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
    questionFields,
    // answerFields,
    appendQuestion,
    removeQuestion,
    // removeAnswer,
    onSubmit,
    handleQuestionTypeChange,
    watchQuestions,
    reorderQuestion,
    isLoadingSurveyConfigData,
    surveyConfigData,
  };
};
