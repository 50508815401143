import React from "react";
import { CourseEditorLayout } from "./CourseEditorLayout.tsx";
import { BasicTitle } from "./BasicTitle.tsx";
import { QuestionContainer } from "./QuestionContainer.tsx";
import { EditorSidebar } from "./EditorSidebar.tsx";
import {
  useFetchCourseLevelAssessmentList,
  useUpdateCourseLevelAssessment,
  useAddCourseLevelAssessment,
  useDeleteCourseLevelAssessment,
} from "../../hooks/courseEditor.ts";
import { message } from "antd";
import { useCourseEditorStore } from "../../stores/useCourseEditorStore.ts";
import { useQueryClient } from "react-query";
import { useFieldArray, useFormContext } from "react-hook-form";
import { QuestionData } from "../../types/common.ts";

interface Props {
  publishCourse: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  saveChanges: () => Promise<any>;
}

export const CourseEditorAssessment: React.FC<Props> = ({
  publishCourse,
  saveChanges,
}) => {
  const { skill, level, setErrorInTab, setCheckErrors } = useCourseEditorStore(
    (state) => state
  );

  const queryClient = useQueryClient();
  const assessmentListQuery = useFetchCourseLevelAssessmentList(
    skill?.id,
    level?.id
  );
  const {
    control,
    trigger,
    getValues,
    formState: { errors, isDirty },
  } = useFormContext();

  const { fields: assessmentList, replace }: { fields: any; replace } =
    useFieldArray({
      control,
      name: "assessment.questions",
      keyName: "fid",
    });

  const updateAssessment = useUpdateCourseLevelAssessment();
  const addAssessment = useAddCourseLevelAssessment();
  const deleteAssessment = useDeleteCourseLevelAssessment();

  const errorKeys = Object.keys(errors?.assessment ?? {});

  React.useEffect(() => {
    const handleTrigger = async () => {
      return await trigger();
    };
    const hasError = Object.keys(errors?.assessment ?? {}).length > 0;
    if (hasError) {
      setErrorInTab("assessment", true);
    } else {
      if (isDirty) {
        const allErrors = handleTrigger();
        const errorKeys = Object.keys(allErrors);

        setErrorInTab("assessment", errorKeys?.length > 0);
      } else {
        setErrorInTab("assessment", null);
      }
    }
    setCheckErrors(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorKeys?.length, isDirty, setCheckErrors]);

  const deleteAssessmentHandler = (assessment_id: string) => {
    message.loading({
      content: "Deleting...",
      key: "deleteMessage",
      duration: 0,
    });
    if (skill?.id && level?.id) {
      deleteAssessment.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          assessment_id,
        },
        {
          onSuccess: async () => {
            const a: any = await queryClient.fetchQuery([
              "course-level-assessment-list",
              skill?.id,
              level?.id,
            ]);
            replace([...a?.data?.data]);
            message.success({
              content: "Deleted successfully!",
              key: "deleteMessage",
              duration: 2,
            });
            await trigger();
          },
          // On error
          onError: () => {
            // Update the message to show error
            message.error({
              content: "Error deleting!",
              key: "deleteMessage",
              duration: 2,
            });
          },
        }
      );
    }
  };

  /**
   * Function accepts an question object. Function checks object's order, gets object previous to current object as well. Make copies of both of these, with their new orders. update them one by one, and when second one is successful refetch and new
   */
  const moveUpHandler = (question: QuestionData) => {
    const assessmentList = getValues("assessment.questions");
    if (question.order === 1) {
      return;
    }
    message.open({
      content: "Moving question up...",
      key: "moveUpMessage",
      duration: 0,
    });
    const findElementBefore = assessmentList?.find(
      (item) => item.order === question.order - 1
    );

    const copyOfElementBefore = {
      ...findElementBefore,
      answers: findElementBefore?.answers?.map((items) => ({ ...items })) ?? [],
      order: question.order,
    };
    const copyOfElement = {
      ...question,
      answers: question?.answers?.map((items) => ({ ...items })),
      order: question.order - 1,
    };
    const newArr = [...assessmentList];
    newArr[question.order - 1] = copyOfElement;
    newArr[question.order - 2] = copyOfElementBefore;

    //  update both in server
    updateAssessment.mutateAsync(
      {
        course_id: skill?.id ?? "",
        level_id: level?.id ?? "",
        assessment_id: copyOfElement?.id ?? "",
        formData: copyOfElement,
      },
      {
        onError(error, variables, context) {},
        onSuccess: async (data) => {
          updateAssessment.mutate(
            {
              course_id: skill?.id ?? "",
              level_id: level?.id ?? "",
              assessment_id: copyOfElementBefore.id,
              formData: copyOfElementBefore,
            },
            {
              onError(error, variables, context) {
                message.error({
                  content: "Error moving question up!",
                  key: "moveUpMessage",
                  duration: 2,
                });
              },
              onSuccess: async (data) => {
                await queryClient.fetchQuery([
                  "course-level-assessment-list",
                  skill?.id,
                  level?.id,
                ]);
                replace(newArr);
                message.open({
                  content: "Question moved up!",
                  key: "moveUpMessage",
                  duration: 2,
                  type: "success",
                });
              },
            }
          );
        },
      }
    );
  };

  const moveDownHandler = (question: QuestionData) => {
    const assessmentList = getValues("assessment.questions");
    if (question.order === assessmentList?.length) {
      return;
    }

    const findElementAfter = assessmentList?.find(
      (item) => item.order === question.order + 1
    );

    const copyOfElementAfter = {
      ...findElementAfter,
      answers: findElementAfter?.answers?.map((items) => ({ ...items })) ?? [],
      order: question.order,
    };
    const copyOfElement = {
      ...question,
      answers: question?.answers?.map((items) => ({ ...items })),
      order: question.order + 1,
    };
    const newArr = [...assessmentList];
    newArr[question.order - 1] = copyOfElementAfter;
    newArr[question.order] = copyOfElement;

    //  update both in server

    updateAssessment.mutateAsync(
      {
        course_id: skill?.id ?? "",
        level_id: level?.id ?? "",
        assessment_id: findElementAfter?.id ?? "",
        formData: copyOfElementAfter,
      },
      {
        onError(error, variables, context) {},
        onSuccess: async (data) => {
          updateAssessment.mutate(
            {
              course_id: skill?.id ?? "",
              level_id: level?.id ?? "",
              assessment_id: copyOfElement.id,
              formData: copyOfElement,
            },
            {
              onError(error, variables, context) {},
              onSuccess: async (data) => {
                await queryClient.fetchQuery([
                  "course-level-assessment-list",
                  skill?.id,
                  level?.id,
                ]);
                replace(newArr);
                message.success("Question moved down!");
              },
            }
          );
        },
      }
    );
  };

  const addAnswer = (assessment_id: string) => {
    const assessmentList = getValues("assessment.questions");
    message.loading({
      content: "Adding answer...",
      key: "addAnswerMessage",
      duration: 0,
    });
    const findAnswer = assessmentList?.find(
      (item) => item.id === assessment_id
    );

    const newAnswers = findAnswer?.answers
      ? [...findAnswer.answers, { answer: "", is_correct_answer: false }]
      : [{ answer: "", is_correct_answer: false }];

    // update to server
    if (skill?.id && level?.id) {
      updateAssessment.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          assessment_id: assessment_id,
          formData: {
            ...findAnswer,
            answers: newAnswers,
          },
        },
        {
          onError(error, variables, context) {
            message.error({
              content: "Error adding answer!",
              key: "addAnswerMessage",
              duration: 2,
            });
          },
          onSuccess: async () => {
            // const a: any = await queryClient.fetchQuery([
            //   "course-level-assessment-list",
            //   skill?.id,
            //   level?.id,
            // ]);
            // replace([newAnswers]);

            if (assessment_id) {
              replace(
                assessmentList?.map((assessment: any) => {
                  if (assessment.id === assessment_id) {
                    return {
                      ...assessment,
                      answers: [
                        ...assessment.answers,
                        { answer: "", is_correct_answer: false },
                      ],
                    };
                  }

                  return assessment;
                })
              );
            }
            message.success({
              content: "Answer added!",
              key: "addAnswerMessage",
              duration: 2,
            });
          },
        }
      );
    }
  };

  const deleteAnswer = (assessment_id: string, answerIndex: number) => {
    message.loading({
      content: "Deleting answer...",
      key: "deleteAnswerMessage",
      duration: 0,
    });
    const findAnswer = assessmentList?.find(
      (item) => item.id === assessment_id
    );

    const newAnswers = findAnswer?.answers
      ? [...findAnswer?.answers?.filter((item, index) => index !== answerIndex)]
      : [{ answer: "", is_correct_answer: false }];

    // update to server
    if (skill?.id && level?.id) {
      updateAssessment.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          assessment_id: assessment_id,
          formData: {
            ...findAnswer,
            answers: newAnswers,
          },
        },
        {
          onError(error, variables, context) {
            message.error({
              content: "Error deleting answer!",
              key: "deleteAnswerMessage",
              duration: 2,
            });
          },
          onSuccess: async () => {
            await queryClient.fetchQuery([
              "course-level-assessment-list",
              skill?.id,
              level?.id,
            ]);
            if (assessment_id) {
              replace(
                assessmentList?.map((assessment: any) => {
                  if (assessment.id === assessment_id) {
                    return {
                      ...assessment,
                      answers: newAnswers,
                    };
                  }

                  return assessment;
                })
              );
            }
            message.success({
              content: "Answer deleted!",
              key: "deleteAnswerMessage",
              duration: 2,
            });
          },
        }
      );
    }
  };

  const toggleIsTimed = (index: number, value: boolean) => {
    // optimistic update
    const currentTime = assessmentList[index].timer;
    const newIsTimed = value ? 0.5 : 0;
    const newArr = [...assessmentList];
    newArr[index] = { ...newArr[index], timer: newIsTimed };
    replace(newArr);
    // update to server
    if (skill?.id && level?.id) {
      updateAssessment.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          assessment_id: assessmentList[index].id,
          formData: {
            ...assessmentList[index],
            timer: newIsTimed,
          },
        },
        {
          onError(error, variables, context) {
            //   if this results in an error remove the optimistic update i.e. change in order
            const newArr = [...assessmentList];
            newArr[index].timer = currentTime;
            replace(newArr);
          },
          onSuccess: () => {
            message.success(
              `Question will ${value ? "be timed" : "not be timed"}!`
            );
          },
        }
      );
    }
  };
  const setTime = (questionId: string, value: number) => {
    const index = assessmentList?.findIndex((item) => item.id === questionId);
    const newArr = [...assessmentList];
    newArr[index] = { ...newArr[index], timer: value };
    replace(newArr);
    if (skill?.id && level?.id) {
      updateAssessment.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          assessment_id: assessmentList[index].id,
          formData: {
            ...assessmentList[index],
            timer: value,
          },
        },
        {
          onError(error, variables, context) {
            //   if this results in an error remove the optimistic update i.e. change in order
            const newArr = [...assessmentList];
            newArr[index].timer = assessmentList[index]?.timer;
            replace(newArr);
          },
          onSuccess: () => {
            message.success(`Timer set to ${value} mins!`);
          },
        }
      );
    }
  };

  return (
    <CourseEditorLayout
      isLoading={assessmentListQuery.isLoading}
      leftRightSidebar={
        <EditorSidebar
          type="assessment"
          skill_id={skill?.id ?? ""}
          publishCourse={publishCourse}
          saveChanges={saveChanges}
        />
      }
      mainContent={
        <>
          <BasicTitle
            title={`Total Questions ${assessmentList?.length ?? 0}/${10}`}
            className="mt-6 !bg-secondaryYellow top-14 sticky z-50"
            titleSize="h2"
            withButton
            buttonTitle="New Question"
            buttonProps={{
              loading: addAssessment.isLoading,
              onClick: () => {
                const assessmentList = getValues("assessment.questions");
                if (skill?.id && level?.id) {
                  addAssessment.mutate(
                    {
                      course_id: skill?.id,
                      level_id: level?.id,
                      formData: {
                        question: ``,
                        answers: [
                          {
                            answer: "",
                            is_correct_answer: false,
                          },
                          {
                            answer: "",
                            is_correct_answer: true,
                          },
                        ],
                        timer: 0,
                        order: assessmentList?.length + 1,
                      },
                    },
                    {
                      onSuccess: (data) => {
                        replace([...assessmentList, data.data.data]);
                      },
                    }
                  );
                }
              },
            }}
          />
          {assessmentList?.map((question, index) => (
            <QuestionContainer
              key={question?.fid}
              question={question}
              questionIndex={index}
              handleDelete={() => deleteAssessmentHandler(question.id)}
              handleMoveUp={() =>
                moveUpHandler(getValues("assessment.questions")[index])
              }
              handleMoveDown={() =>
                moveDownHandler(getValues("assessment.questions")[index])
              }
              totalQuestions={assessmentList?.length ?? 0}
              addAnswer={addAnswer}
              deleteAnswer={deleteAnswer}
              handleToggleIsTimed={(value: boolean) =>
                toggleIsTimed(index, value)
              }
              setTime={setTime}
              replaceAssessmentList={replace}
            />
          ))}
        </>
      }
    />
  );
};
