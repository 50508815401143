import React from "react"

interface BasicContainerProps {
  className?: string
  children: React.ReactNode | React.ReactElement
}

export const BasicContainer: React.FC<BasicContainerProps> = ({
  className = "",
  children,
}) => {
  return (
    <div
      className={`py-3 px-[17px] rounded-[11px] bg-backgroundBg  border  ${className}`}>
      {children}
    </div>
  )
}
