import { create } from "zustand"
import { SurveyResponse, SurveyResponseList } from "../types/surveyResponse"

export interface SurveyStoreState {
  surveyConfigData: SurveyResponse | undefined | null
  setSurveyConfigData: (data: SurveyResponse | null) => void
  loadingSurveyConfig: boolean
  setLoadingSurveyConfig: (loading: boolean) => void
  errorSurveyConfig: boolean
  setErrorSurveyConfig: (error: boolean) => void
}

export const initialSurveyStoreState = (surveyProgramId: string) => create<SurveyStoreState>(
  persist(
    (set) => ({
      surveyConfigData: null,
      setSurveyConfigData: (data: SurveyResponse | null) => set({ surveyConfigData: data }),
      loadingSurveyConfig: false,
      setLoadingSurveyConfig: (loading: boolean) => set({ loadingSurveyConfig: loading }),
      errorSurveyConfig: false,
      setErrorSurveyConfig: (error: boolean) => set({ errorSurveyConfig: error }),
    }),
    {
      name: `survey-store-${surveyProgramId}`,
      getStorage: () => localStorage,
    }
  )
)


export interface QueryParams {
  type: string
  question_id: string
}

export interface SelectOption {
  label: string
  value: string
}

export interface SurveyResponseStoreState {
  surveyResponsesData: SurveyResponseList[]
  setSurveyResponsesData: (data: SurveyResponseList[]) => void
  loadingSurveyResponsesData: boolean
  setLoadingSurveyResponsesData: (loading: boolean) => void
  errorSurveyResponsesData: boolean
  setErrorSurveyResponsesData: (error: boolean) => void
  queryParams: QueryParams
  setQueryParams: (newQueryParams: QueryParams) => void
  selectOptions: SelectOption[]
  setSelectOptions: (options: SelectOption[]) => void
}

export const initialSurveyResponseStoreState = create<SurveyResponseStoreState>((set) => ({
  surveyResponsesData: [],
  setSurveyResponsesData: (data: SurveyResponseList[]) => set({ surveyResponsesData: data }),
  loadingSurveyResponsesData: false,
  setLoadingSurveyResponsesData: (loading: boolean) => set({ loadingSurveyResponsesData: loading }),
  errorSurveyResponsesData: false,
  setErrorSurveyResponsesData: (error: boolean) => set({ errorSurveyResponsesData: error }),
  queryParams: {
    type: "pre",
    question_id: "",
  },
  setQueryParams: (newQueryParams: QueryParams) => set((state) => ({
    queryParams: { ...state.queryParams, ...newQueryParams },
  })),
  selectOptions: [],
  setSelectOptions: (options: SelectOption[]) => set({ selectOptions: options }),
}))
