import { AxiosResponse } from "axios";
import httpRequest from "../../../config/config";
import {
  ApiResponse,
  PaginatedResponse,
} from "../../../common/types/ApiResponse";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  ContentMenuItem,
  DETAIL_PROGRAM_TRAINER_QUERY_NAME,
  LIST_PROGRAM_TRAINER_QUERY_NAME,
  ProgramAssessment,
  ProgramCourseResponse,
  ProgramModule,
} from "../types/responseProgramCourse";
import {
  initialEditorCourseProgramStoreState,
  initialFormCourseProgramStoreState,
} from "../stores/storeProgramCourseEditor";
import { useFetchDetail, useWriteV2 } from "../../../common/hooks/generic_rest";
import { PROGRAM_TRAINER_ENDPOINT } from "../apis/endpointProgram";
// @ts-ignore
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { initialPublicStoreState } from "../../../common/hooks/InitPublicStore";
import {
  useDeleteLessonVideoProgram,
  useEncodeLessonVideoProgram,
} from "./useLessonVideo";
import { RcFile } from "antd/es/upload";
import { BeforeUploadFileType } from "rc-upload/lib/interface";
import dayjs from "dayjs";

export const useProgramCourse = () => {
  const [dataList, setDataList] = useState<ProgramCourseResponse[]>([]);
  const [params, setParams] = useState({
    page: 1,
    title: "",
    limit: 10,
  });
  const [total, setTotal] = useState<number>(0);

  const { data, isLoading } = useQuery(
    [LIST_PROGRAM_TRAINER_QUERY_NAME, params],
    () =>
      httpRequest.get<AxiosResponse<PaginatedResponse<ProgramCourseResponse>>>(
        "/school/programs/trainer",
        { params }
      )
  );

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data?.data?.list || []);
        setTotal(data?.data.data.total_data || 0);
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])]);
        setTotal(data?.data.data.total_data || 0);
      }
    }
  }, [
    data?.data.data.list,
    data?.data.data.total_data,
    dataList,
    isLoading,
    params.page,
  ]);

  return {
    dataList,
    isLoading,
    total,
    params,
    setParams,
  };
};

export const useProgramTrainerFetchDetail = (id: string) => {
  const {
    setProgramDetailData,
    setLoadingDetailProgram,
    setErrorDetailProgram,
    setContentMenu,
  } = initialEditorCourseProgramStoreState();

  const { setFormData } = initialFormCourseProgramStoreState();

  const {
    data: detailProgramData,
    isLoading: isDetailLoadingProgram,
    isError: isDetailErrirDetailProgram,
    // refetch: refetchDetailProgram,
  } = useFetchDetail<ProgramCourseResponse>(
    `${PROGRAM_TRAINER_ENDPOINT.DETAIL_PROGRAM(id)}`,
    {
      queryName: DETAIL_PROGRAM_TRAINER_QUERY_NAME,
      showErrorMessage: true,
    }
  );

  //

  const createContentMenu = (
    response: ProgramCourseResponse
  ): ContentMenuItem[] => {
    const baseMenu: ContentMenuItem[] = [
      { label: "Overview", key: "overview", is_filled: false },
      { label: "Assessment", key: "assessment", is_filled: false },
    ];

    const modulesMenu: ContentMenuItem[] =
      response?.modules?.map((module: ProgramModule, index: number) => ({
        label: `Module ${index + 1}`,
        key: `module-${module?.id}`,
        is_filled: false,
      })) || [];

    return [...baseMenu.slice(0, 1), ...modulesMenu, ...baseMenu.slice(1)];
  };

  useEffect(() => {
    setLoadingDetailProgram(true);

    if (detailProgramData) {
      setProgramDetailData(detailProgramData);
      const menu = createContentMenu(detailProgramData);
      setContentMenu(menu);

      setTimeout(() => {
        setLoadingDetailProgram(false);
      }, 1500);

      setFormData({
        id: detailProgramData?.id || "",
        description: detailProgramData?.description || "",
        modules: detailProgramData?.modules || [],
        assessments: detailProgramData?.assessments || [],
      });

      setErrorDetailProgram(isDetailErrirDetailProgram);
    }
  }, [
    detailProgramData,
    isDetailErrirDetailProgram,
    setProgramDetailData,
    setLoadingDetailProgram,
    setErrorDetailProgram,
    setContentMenu,
    isDetailLoadingProgram,
    setFormData,
  ]);
};

export const useHookProgramCourseForm = (moduleId?: string) => {
  const {
    formData,
    setFormData,
    setModuleValidationState,
    isCheckValid,
    setIsCheckValid,
    setAssessmentValidationState,
    setLoadingIssuingEmail,
    setAllFilled,
  } = initialFormCourseProgramStoreState();

  const queryClient = useQueryClient();

  const {
    programDetailData,
    loadingDetailPorgram,
    setProgramDetailData,
    setLoadingDetailProgram,
  } = initialEditorCourseProgramStoreState();

  const addQuestion = () => {
    const newAssessments: ProgramAssessment[] = [
      ...(formData.assessments || []), // Gunakan array kosong jika assessments null
      {
        id: Date.now().toString(),
        question: "",
        timer: 0,
        answers: [
          {
            id: Date.now().toPrecision().toString() + "_1",
            answer: "",
            is_correct_answer: true,
          },
          {
            id: Date.now().toPrecision().toString() + "_2",
            answer: "",
            is_correct_answer: false,
          },
        ],
      },
    ];
    setFormData({ ...formData, assessments: newAssessments });

    // Validate all assessments after adding a question
    validateAllAssessments(newAssessments);
  };

  const deleteQuestion = (questionId: string) => {
    const newAssessments = formData.assessments!.filter(
      (assessment) => assessment.id !== questionId
    );
    setFormData({ ...formData, assessments: newAssessments });

    // Validate all assessments after deleting a question
    validateAllAssessments(newAssessments);
  };

  const addAnswer = (questionId: string) => {
    const newAssessments = formData.assessments!.map((assessment) => {
      if (assessment.id === questionId) {
        return {
          ...assessment,
          answers: [
            ...assessment.answers,
            { id: Date.now().toString(), answer: "", is_correct_answer: false },
          ],
        };
      }
      return assessment;
    });
    setFormData({ ...formData, assessments: newAssessments });

    // Validate all assessments after adding an answer
    validateAllAssessments(newAssessments);
  };

  const deleteAnswer = (questionId: string, answerId: string) => {
    if (
      formData.assessments!.find((assessment) => assessment.id === questionId)
        ?.answers.length === 2
    ) {
      message.warning("You must have at least two answers");
      return;
    }

    const newAssessments = formData.assessments!.map((assessment) => {
      if (assessment.id === questionId) {
        return {
          ...assessment,
          answers: assessment.answers.filter(
            (answer) => answer.id !== answerId
          ),
        };
      }
      return assessment;
    });
    setFormData({ ...formData, assessments: newAssessments });

    // Validate all assessments after deleting an answer
    validateAllAssessments(newAssessments);
  };

  function parseHTMLtoPlainText(htmlString: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const plainText = doc.body.textContent;

    return plainText;
  }

  // HANDLE CHANGE OVERVIEW
  const handleChangeOverview = (newDescription: string) => {
    setFormData({
      ...formData,
      description: newDescription,
    });
  };

  // VALIDATE FILED FORMDATA
  const parserDesc = parseHTMLtoPlainText(formData.description);

  // CALIDATE OVERVIEW
  const isDescriptionValid = parserDesc!.trim().length >= 1;
  //

  // VALIDATE MODULE
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateModule = (moduleId: string) => {
    const module = formData.modules.find((mod) => mod.id === moduleId);

    if (!module) {
      setModuleValidationState(moduleId, false); // If the module doesn't exist, it's invalid
      return false;
    }

    // Check if all lessons in the module have media
    const areLessonsVideoUploaded = module.lessons.every((lesson) => {
      // Lesson is valid if media exists and is either FINISHED or IN_PROGRESS
      return (
        lesson.media &&
        (lesson.media.status === "FINISHED" ||
          lesson.media.status === "IN_PROGRESS")
      );
    });

    // The module is valid if both the description and lessons media are valid
    const isValid = areLessonsVideoUploaded;

    // Update the validation state in Zustand
    setModuleValidationState(moduleId, isValid);

    return isValid;
  };

  // HANDLE CHANGE MODULE DESCRIPTION
  const handleChangeModuleDescription = (
    moduleId: string,
    newDescription: string
  ) => {
    const updatedModules = formData.modules.map((module) => {
      if (module.id === moduleId) {
        return {
          ...module,
          description: newDescription,
        };
      }
      return module;
    });

    setFormData({
      ...formData,
      modules: updatedModules,
    });

    // Validate the updated module
    validateModule(moduleId);
  };

  // HANDLE CHANGE LESSON MATERIAL
  const handleChangeModuleLessonMaterial = (
    moduleId: string,
    lessonId: string,
    newMaterial: string
  ) => {
    const updatedModules = formData.modules.map((module) => {
      if (module.id === moduleId) {
        return {
          ...module,
          lessons: module.lessons.map((lesson) => {
            if (lesson.id === lessonId) {
              return {
                ...lesson,
                material: newMaterial,
              };
            }
            return lesson;
          }),
        };
      }
      return module;
    });

    setFormData({
      ...formData,
      modules: updatedModules,
    });

    validateModule(moduleId);
  };

  // VALIDATE ALL ASSESSMENTS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateAllAssessments = (newAssessments: ProgramAssessment[]) => {
    // Ensure there's at least one assessment
    if (newAssessments.length === 0) {
      setAssessmentValidationState(false);
      return false;
    }
    // Check each assessment for validity
    const isValid = newAssessments.every((assessment) => {
      // Check if the question has at least 1 characters
      const parserQuestion = parseHTMLtoPlainText(assessment.question);
      const isQuestionValid = parserQuestion!.trim().length >= 1;

      // Check if there are at least 1 answers
      const hasAtLeastTwoAnswers = assessment.answers.length >= 1;

      // Check if all answers have at least 1 characters
      const areAnswersValid = assessment.answers.every((answer) => {
        const parserAnswer = parseHTMLtoPlainText(answer.answer);
        return parserAnswer!.trim().length >= 1;
      });

      // Check if there is exactly one correct answer
      const correctAnswersCount = assessment.answers.filter(
        (answer) => answer.is_correct_answer
      ).length;
      const hasValidCorrectAnswer = correctAnswersCount === 1;

      // Return true if all validation checks pass for the assessment
      return (
        isQuestionValid &&
        hasAtLeastTwoAnswers &&
        areAnswersValid &&
        hasValidCorrectAnswer
      );
    });

    setAssessmentValidationState(isValid);

    return isValid;
  };

  // LOAD VALIDATE
  useEffect(() => {
    if (!loadingDetailPorgram && formData.id !== "" && !isCheckValid) {
      let isModuleValid = false;
      formData.modules.forEach((module) => {
        if (module.id) {
          // Ensure module.id is not empty
          setTimeout(() => {
            isModuleValid = validateModule(module.id); // Validate the module by its correct ID
            setModuleValidationState(module.id, isModuleValid); // Update Zustand with the correct ID
          }, 1000);
        }
      });

      if (formData.assessments) {
        validateAllAssessments(formData.assessments);
      }

      setAllFilled(isDescriptionValid);

      setIsCheckValid(true); // Mark form as CheckValidized
    }
  }, [
    formData,
    formData.modules,
    isCheckValid,
    isDescriptionValid,
    loadingDetailPorgram,
    setAllFilled,
    setIsCheckValid,
    setModuleValidationState,
    validateAllAssessments,
    validateModule,
  ]);

  // HANDLE CHANGE ASSESSMENT QUESTION (for a specific question)
  const handleChangeAssessmentQuestion = (
    assessmentId: string,
    newQuestion: string
  ) => {
    const updatedAssessments =
      formData.assessments! &&
      formData.assessments!.map((assessment) => {
        if (assessment.id === assessmentId) {
          return {
            ...assessment,
            question: newQuestion,
          };
        }
        return assessment;
      });

    // Update the formData with the updated assessments
    setFormData({
      ...formData,
      assessments: updatedAssessments,
    });

    // Validate all assessments after the update
    validateAllAssessments(updatedAssessments!);
  };

  // HANDLE CHANGE ASSESSMENT ANSWER TEXT (by index)
  const handleChangeAssessmentAnswer = (
    assessmentId: string,
    answerIndex: number,
    newAnswer: string
  ) => {
    const updatedAssessments = formData.assessments!.map((assessment) => {
      if (assessment.id === assessmentId) {
        return {
          ...assessment,
          answers: assessment.answers.map((answer, idx) => {
            if (idx === answerIndex) {
              return {
                ...answer,
                answer: newAnswer, // Update the answer text
              };
            }
            return answer;
          }),
        };
      }
      return assessment;
    });

    // Update the formData with the updated assessments
    setFormData({
      ...formData,
      assessments: updatedAssessments,
    });

    // Validate all assessments after the update
    validateAllAssessments(updatedAssessments!);
  };

  const handleChangeAssessmentCorrectAnswer = (
    assessmentId: string,
    correctAnswerIndex: number
  ) => {
    const updatedAssessments = formData.assessments!.map((assessment) => {
      if (assessment.id === assessmentId) {
        return {
          ...assessment,
          answers: assessment.answers.map((answer, idx) => ({
            ...answer,
            is_correct_answer: idx === correctAnswerIndex, // Set only one answer as correct
          })),
        };
      }
      return assessment;
    });

    // Update the formData with the updated assessments
    setFormData({
      ...formData,
      assessments: updatedAssessments,
    });

    // Validate all assessments after the update
    validateAllAssessments(updatedAssessments!);
  };

  const updateTimer = (questionId: string, timer: number) => {
    const newAssessments = formData.assessments!.map((assessment) => {
      if (assessment.id === questionId) {
        return {
          ...assessment,
          timer,
        };
      }
      return assessment;
    });

    setFormData({ ...formData, assessments: newAssessments });
  };

  const reorderQuestion = (fromId: string, toId: string) => {
    const newAssessments = [...formData.assessments!];
    const fromIndex = newAssessments.findIndex(
      (assessment) => assessment.id === fromId
    );
    const toIndex = newAssessments.findIndex(
      (assessment) => assessment.id === toId
    );

    if (fromIndex !== -1 && toIndex !== -1) {
      const [movedQuestion] = newAssessments.splice(fromIndex, 1);
      newAssessments.splice(toIndex, 0, movedQuestion);
      setFormData({ ...formData, assessments: newAssessments });
    }
  };

  const editData = useWriteV2<any, any>(
    // @ts-ignore
    (data) => {
      if (data?.id) {
        return PROGRAM_TRAINER_ENDPOINT.UPDATE_PROGRAM(data.id);
      } else {
        throw new Error("Data object does not have an id property");
      }
    },
    "patch",
    () => {
      message.open({
        key: "edit-course-success",
        content: "Update course successfully",
        type: "success",
      });
    }
  );

  const editDataAndSubmit = useWriteV2<any, any>(
    // @ts-ignore
    (data) => {
      if (data?.id) {
        return PROGRAM_TRAINER_ENDPOINT.UPDATE_PROGRAM(data.id);
      } else {
        throw new Error("Data object does not have an id property");
      }
    },
    "patch"
  );

  const onSubmit = (program_id: string) => {
    setLoadingDetailProgram(true);
    message.open({
      key: "edit-course-success",
      content: "Loading...",
      type: "loading",
    });
    setTimeout(() => {
      setLoadingDetailProgram(false);
    }, 1000);

    formData.id = program_id;

    if (formData?.assessments?.length === 0) {
      formData.assessments = null;
    }

    editData.mutate(formData);
  };

  const navigate = useNavigate();
  const openModal = initialPublicStoreState((state) => state.openModal);

  const handleBack = () => {
    navigate(-1);
    setFormData({
      id: "",
      description: "",
      modules: [],
      assessments: null,
    });
    setIsCheckValid(false);
  };

  const showModal = () => {
    openModal(() => Promise.resolve(handleBack()));
  };

  // ISSUING EMAIL
  const issuingEmail = useWriteV2<any, any>(
    // @ts-ignore
    (data) => {
      if (data?.id) {
        return PROGRAM_TRAINER_ENDPOINT.ISSUING_EMAIL(
          data.id,
          data?.issuing_email
        );
      } else {
        throw new Error("Data object does not have an id property");
      }
    },
    "patch",
    () => {
      if (programDetailData?.id) {
        setProgramDetailData({
          ...programDetailData,
          is_issuing_email: !programDetailData.is_issuing_email,
        });
      } else {
        throw new Error("Program detail data does not have an id property");
      }
      setLoadingIssuingEmail(false);
      message.open({
        key: "issuing-course-success",
        content: "Update course successfully",
        type: "success",
      });
    }
  );

  const onSubmitIssuingEmail = (program_id: string) => {
    setLoadingIssuingEmail(true);
    message.open({
      key: "issuing-course-success",
      content: "Loading...",
      type: "loading",
    });

    const dataToIssuing = {
      id: program_id,
      issuing_email: programDetailData?.is_issuing_email ? false : true,
    };

    issuingEmail.mutate(dataToIssuing);
  };

  // PUBLISH COURSE
  const publishCourse = useWriteV2<any, any>(
    // @ts-ignore
    (data) => {
      if (data?.id) {
        return PROGRAM_TRAINER_ENDPOINT.PUBLISH(data.id);
      } else {
        throw new Error("Data object does not have an id property");
      }
    },
    "patch",
    () => {
      queryClient.refetchQueries(DETAIL_PROGRAM_TRAINER_QUERY_NAME);
      message.open({
        key: "publish-course-success",
        content: "Update course successfully",
        type: "success",
      });
    }
  );

  const handlePublishCourse = async (program_id: string) => {
    setLoadingDetailProgram(true);
    message.open({
      key: "publish-course-success",
      content: "Loading...",
      type: "loading",
    });
    setTimeout(() => {
      setLoadingDetailProgram(false);
    }, 1000);

    formData.id = program_id;

    if (formData?.assessments?.length === 0) {
      formData.assessments = null;
    }

    await editDataAndSubmit.mutateAsync(formData);

    if (formData?.assessments?.length === 0) {
      message.error("You must have at least one question");
      return;
    }

    publishCourse.mutate(formData);
  };

  const confirmPublish = (id: string) => {
    openModal(() => Promise.resolve(handlePublishCourse(id)));
  };

  // media and document handle
  const encodeLessonVideo = useEncodeLessonVideoProgram();
  const deleteLessonVideo = useDeleteLessonVideoProgram();

  const truncateMiddle = (
    str: string,
    startChars: number,
    endChars: number,
    maxLength: number
  ): string => {
    if (str.length <= maxLength) return str;
    const start = str.slice(0, startChars);
    const end = str.slice(-endChars);
    return `${start}...${end}`;
  };

  const videoStatusUpdateAfterUpload = (
    lessonId: string,
    isError: boolean = false,
    deleted?: boolean
  ) => {
    if (deleted) {
      setFormData({
        ...formData,
        id: programDetailData?.id ?? "",
        modules:
          formData?.modules.map((module) => {
            if (module.id === moduleId) {
              return {
                ...module,
                lessons: module.lessons.map((lesson) => {
                  if (lesson.id === lessonId) {
                    return {
                      ...lesson,
                      media: undefined,
                    };
                  }
                  return lesson;
                }),
              };
            }
            return module;
          }) || [],

        description: formData?.description ?? "No description", // Tambahkan fallback jika tidak ada
        assessments: formData?.assessments ?? [], // Fallback jika assessments undefined
      });
    } else {
      setFormData({
        ...formData,
        id: programDetailData?.id ?? "",
        modules:
          formData?.modules.map((module) => {
            if (module.id === moduleId) {
              return {
                ...module,
                lessons: module.lessons.map((lesson) => {
                  if (lesson.id === lessonId) {
                    return {
                      ...lesson,
                      media: {
                        ...lesson.media,
                        status: "IN_PROGRESS",
                        dash: "",
                      },
                    };
                  }
                  return lesson;
                }),
              };
            }
            return module;
          }) || [],
        description: formData?.description ?? "No description",
        assessments: formData?.assessments ?? [],
      });
    }
  };

  const beforeFileUpload = (file: RcFile) => {
    const isPdf = file?.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF files!");
      return false;
    }
    return true;
  };

  const updateCourseLevelModuleLesson = (
    lesson_id: string | undefined | null,
    formData: any
  ): Promise<ApiResponse<any>> => {
    const data = new FormData();
    if (formData?.description)
      data.append("description", formData?.description);
    if (formData?.order) data.append("order", (formData?.order).toString());
    if (formData.file) {
      data.append("file", formData.file);
    }
    return httpRequest.post(`/school/programs/trainer/${lesson_id}/docs`, data);
  };

  const useUpdateCourseProgramModuleLesson = () => {
    const handleFetchError = (error: string) => {
      message.error(
        `An error occurred while updating course level module lesson: ${error}`
      );
    };
    return useMutation(
      ({ id, formData }: { id: string | undefined | null; formData: any }) =>
        updateCourseLevelModuleLesson(id, formData),
      {
        onError: handleFetchError,
      }
    );
  };

  const { mutateAsync: updateLesson, isLoading } =
    useUpdateCourseProgramModuleLesson();

  const handleFileUpload = async (
    file: Exclude<BeforeUploadFileType, File | boolean> | RcFile,
    lessonId: string
  ) => {
    // @ts-ignore
    if (file.size > 500 * 1024) {
      message.error("File size must be less than 500KB");
      return;
    }

    // @ts-ignore
    if (file.type !== "application/pdf") {
      message.error("Only PDF files are allowed");
      return;
    }

    await updateLesson(
      {
        id: lessonId,
        formData: {
          file: file as RcFile,
        },
      },
      {
        onSuccess: (data: any) => {
          message.success("File uploaded successfully");
          if (data?.data?.data) {
            if (formData?.id) {
              setFormData({
                ...formData,
                modules: formData.modules.map((module) => {
                  if (module.id === moduleId) {
                    return {
                      ...module,
                      lessons: module.lessons.map((lesson) => {
                        if (lesson.id === lessonId) {
                          return {
                            ...lesson,
                            documents: [
                              ...(lesson?.documents ?? []), // Pastikan documents adalah array
                              {
                                url: data?.data?.data?.documents?.[
                                  data?.data?.data?.documents?.length - 1
                                ]?.url,
                                name:
                                  data?.data?.data?.documents?.[
                                    data?.data?.data?.documents?.length - 1
                                  ]?.name ?? "",
                                file_name:
                                  data?.data?.data?.documents?.[
                                    data?.data?.data?.documents?.length - 1
                                  ]?.file_name ?? "",
                                created_at: dayjs().toISOString(),
                              },
                            ],
                          };
                        }
                        return lesson;
                      }),
                    };
                  }
                  return module;
                }),
              });
            } else {
              console.error("ID is missing in formData");
            }
          }
        },
        onError: () => {
          message.error(
            "There was a problem in updating the lesson. Please try again."
          );
        },
      }
    );
  };

  // REMOVE PDF
  const removePdfAPI = (
    lesson_id: string | undefined | null,
    blob_name: string | undefined | null
  ): Promise<ApiResponse<any>> => {
    return httpRequest.delete(
      `/school/programs/trainer/${lesson_id}/docs/${blob_name}`,
      { data: formData }
    );
  };

  const useRemovePdf = () => {
    const handleFetchError = (error: string) => {
      message.error(`An error occurred while removing pdf: ${error}`);
    };
    return useMutation(
      ({ lesson_id, blob_name }: { lesson_id: string; blob_name: string }) =>
        removePdfAPI(lesson_id, blob_name),
      {
        onError: handleFetchError,
      }
    );
  };

  const removePdf = useRemovePdf();

  const removePdfFile = async (
    lessonId: string,
    blob_name: string,
    pdf_url: string
  ) => {
    await removePdf.mutateAsync(
      {
        lesson_id: lessonId,
        blob_name: blob_name,
      },
      {
        onSuccess: async (data: any) => {
          message.success("Successfully removed file");
          if (formData?.id) {
            setFormData({
              ...formData,
              modules: formData.modules.map((module) => {
                if (module.id === moduleId) {
                  return {
                    ...module,
                    lessons: module.lessons.map((lesson) => {
                      if (lesson.id === lessonId) {
                        return {
                          ...lesson,
                          documents: lesson?.documents.filter(
                            (doc) => doc.url !== pdf_url
                          ),
                        };
                      }
                      return lesson;
                    }),
                  };
                }
                return module;
              }),
            });
          } else {
            console.error("ID is missing in formData");
          }
        },
        onError: () => {
          message.error(
            "There was a problem in removing the file. Please try again."
          );
        },
      }
    );
  };

  return {
    formData,
    addQuestion,
    deleteQuestion,
    addAnswer,
    deleteAnswer,
    updateTimer,

    handleChangeOverview,
    isDescriptionValid,

    handleChangeModuleDescription,
    handleChangeModuleLessonMaterial,
    handleChangeAssessmentQuestion,
    handleChangeAssessmentAnswer,
    handleChangeAssessmentCorrectAnswer,
    reorderQuestion,

    deleteLessonVideo,
    encodeLessonVideo,
    beforeFileUpload,
    handleFileUpload,
    removePdfFile,
    removePdf,
    videoStatusUpdateAfterUpload,
    truncateMiddle,
    isLoading,

    onSubmit,
    showModal,
    onSubmitIssuingEmail,
    confirmPublish,
  };
};
