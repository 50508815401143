export function formatNumber(x: number, y = 2): string {
  if (x >= 1000) {
    let val = Math.ceil((x / 1000) * 100) / 100;
    let formattedValue = val.toFixed(y).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    return formattedValue.replace(/\.00$/, "") + "k";
  }
  if (x === 0) {
    return x.toFixed();
  }
  return (Math.ceil(x * 100) / 100).toFixed(y);
}

export function formatNumberWithThousandSeparator(num: number): string {
  return num.toLocaleString("en-US");
}
