import React from "react";
import { Breadcrumbs } from "../../../common/components/Breadcumbs.jsx";
import TableCourses from "../components/TableCourses.jsx";
import { useMyCourses } from "../hooks/useFetchMyCourses.js";
import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input, Select, Spin } from "antd";
import { useWorkshopList } from "../hooks/useWorkshop.ts";
import TableWorkshops from "../components/TableWorkshops.tsx";
import { debounce } from "../../../common/helper/debounce.js";
import { HomeIcon } from "../../../assets/icon";
import TablePrograms from "../components/TablePrograms.tsx";
import { useProgramCourse } from "../hooks/useProgramCourse.ts";

const ListCoursesV2 = () => {
  const [selectedTable, setSelectedTable] = React.useState("");

  const items = [
    {
      title: (
        <div className="mt-1">
          <HomeIcon />
        </div>
      ),
      path: "/",
    },
    {
      path: "/courses",
      title: "Skills I teach",
    },
  ];

  const { listMyCourses, isLoading, setSearch } = useMyCourses();
  const workshop = useWorkshopList();
  const program = useProgramCourse();

  const renderTable = (table) => {
    const isSkillEmpty =
      !listMyCourses?.list || listMyCourses?.list.length === 0;
    const isWorkshopEmpty =
      !workshop.dataList || workshop.dataList.length === 0;
    const isProgramEmpty = !program.dataList || program.dataList.length === 0;

    if (isSkillEmpty && isWorkshopEmpty && isProgramEmpty) {
      return <Empty description="No data available" />;
    }

    switch (table) {
      case "skill":
        if (isSkillEmpty) return null;
        return (
          <div className="mx-5 px-5 py-5 my-5 bg-[#FAFAFA] rounded-lg shadow-md">
            <h2>Skills</h2>
            <p className="my-3">
              Curriculum needs to be agreed on with Skilledin
            </p>
            <TableCourses isLoading={isLoading} listMyCourses={listMyCourses} />
          </div>
        );
      case "workshop":
        if (isWorkshopEmpty) return null;
        return (
          <div className="mx-5 px-5 py-5 my-5 bg-[#FAFAFA] rounded-lg shadow-md">
            <h2 className="">Workshops</h2>
            <TableWorkshops
              isLoading={workshop.isLoading}
              data={workshop.dataList}
              total={workshop.total}
              setParams={workshop.setParams}
            />
          </div>
        );
      case "program":
        if (isProgramEmpty) return null;
        return (
          <div className="mx-5 px-5 py-5 my-5 bg-[#FAFAFA] rounded-lg shadow-md">
            <h2 className="">Programs</h2>
            <TablePrograms
              isLoading={program.isLoading}
              data={program.dataList}
              total={program.total}
              setParams={program.setParams}
            />
          </div>
        );
      default:
        return (
          <>
            {renderTable("skill")}
            {renderTable("workshop")}
            {renderTable("program")}
          </>
        );
    }
  };

  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop">My Courses</h1>
      </div>

      <div className="mx-5 p-5 my-5 bg-white rounded-lg shadow-md">
        <div className="flex gap-x-12">
          <div
            className="flex gap-3 w-full"
            style={{ borderBottom: "1px solid #D0DDE5" }}
          >
            <SearchOutlined />
            <Input
              bordered={false}
              placeholder="Search"
              defaultValue={workshop.params.title}
              onChange={debounce((val) => {
                workshop.setParams((prev) => ({
                  ...prev,
                  title: val?.target.value,
                  page: 1,
                  limit: 10,
                }));
                program.setParams((prev) => ({
                  ...prev,
                  search: val?.target.value,
                  page: 1,
                  limit: 10,
                }));
                setSearch(val?.target.value);
              }, 500)}
            />
          </div>

          <Select
            defaultValue="Select table"
            style={{ width: 120 }}
            onChange={(value) => setSelectedTable(value)}
            placeholder="Select table"
            options={[
              { label: "All", value: "" },
              { label: "Skills", value: "skill" },
              { label: "Workshops", value: "workshop" },
              { label: "Programs", value: "program" },
            ]}
            className="!w-96"
          />
        </div>
      </div>

      <div className="mx-5 px-3 py-5 my-5 bg-white rounded-lg shadow-md">
        <Spin spinning={isLoading && program.isLoading && workshop?.isLoading}>
          {renderTable(selectedTable)}
        </Spin>
      </div>
    </div>
  );
};

export default ListCoursesV2;
