import React, { useState } from "react";
import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";
import { Breadcrumbs } from "../../../common/components/Breadcumbs.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDetailMyCourses } from "../hooks/useFetchDetailMyCourses.ts";
import CustomHeader from "../../../common/layout/CustomHeader.jsx";
import { ProficiencyLevelsTable } from "../components/SkillDetailView/ProficiencyLevelsTable.tsx";
import { Button, Skeleton, message } from "antd";
import { VideoUploadV2 } from "../../../common/components/VideoUploadV2.tsx";
import {
  useUploadIntroVideo,
  useDeleteIntroVideo,
  useUpdateIntroVideo,
} from "../hooks/useFetchDetailMyCourses.ts";
import { useQueryClient } from "react-query";
import { BitmovinPlayer } from "../../../common/components/BitmovinPlayer.jsx";
import { HomeIcon } from "../../../assets/icon";

const DetailCourses_v3 = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const { data, isLoading } = useFetchDetailMyCourses(id);
  const detailMyCourses = data?.data?.data;
  const uploadIntroVideo = useUploadIntroVideo();
  const deleteIntroVideo = useDeleteIntroVideo();
  const updateIntroVideo = useUpdateIntroVideo();

  const items = [
    {
      title: (
        <div className={"mt-1"}>
          <HomeIcon />
        </div>
      ),
      path: "/",
    },
    {
      path: "/courses",
      title: "Skills I teach",
    },
    {
      path: "/course-name",
      title: detailMyCourses?.name,
    },
  ];

  const [state, setState] = useState(false);
  return (
    <>
      <div className="mx-7 px-6">
        <div className="ml-[-16.5rem]">
          <CustomHeader isOnBoarding={false} />
        </div>
      </div>
      <div className="px-6 py-2 bg-white mb-4">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <div className="flex items-center gap-4 mb-3 mt-7">
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            className="text-backdrop text-lg -mt-1"
          />
          {isLoading ? (
            <Skeleton active paragraph={{ rows: 1 }} title={false} />
          ) : (
            <h1 className="text-2xl font-bold text-backdrop -ml-1">
              {detailMyCourses?.name}
            </h1>
          )}
        </div>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 1 }} title={false} />
        ) : (
          <div className="ml-7 golden-subtitle">
            {detailMyCourses?.groups?.name}
          </div>
        )}
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 1 }} title={false} />
        ) : (
          <div className="ml-7">
            <p className="my-4 ">{detailMyCourses?.description}</p>
          </div>
        )}
      </div>

      <div className="mx-6 px-6 py-6 bg-white">
        <ProficiencyLevelsTable
          isLoading={isLoading}
          skillDetail={detailMyCourses}
        />
        <div className="grid grid-cols-12 py-3 px-[17px] mt-14 mb-28">
          <div className="col-span-6">
            <h2 className="">Introductory Video (optional)</h2>
            <div className="mt-7 mb-9">
              The video will appear on the Skill Page for users who didn’t yet
              buy any level of the skill. Increase sales by giving the users a
              sneak peak
            </div>
            <div>
              <div className="flex gap-x-[30px]  items-center justify-start">
                <CheckOutlined />
                <span className="text-backdrop">
                  Introduce trainers of this skill and their backgrounds
                </span>
              </div>
              <div className="flex gap-x-[30px] mt-[21px] items-center justify-start">
                <CheckOutlined />
                <span className="text-backdrop">
                  Briefly describe each of the 5 levels of proficiency of this
                  skill
                </span>
              </div>
              <div className="flex gap-x-[30px] mt-[21px] items-center justify-start">
                <CheckOutlined />
                <span className="text-backdrop">
                  When relevant, show an interesting lesson excerpt to spark
                  curiosity
                </span>
              </div>
            </div>
          </div>
          <div className="col-span-6">
            {detailMyCourses?.introduction_video?.dash ? (
              <>
                <div className="flex gap-1 items-center justify-end mt-1">
                  {false && (
                    <>
                      <Button
                        type="text"
                        onClick={async () =>
                          await updateIntroVideo.mutateAsync(
                            {
                              course_id: id ?? "",
                              video_file_name: detailMyCourses?.id ?? "",
                            },
                            {
                              onSuccess: () => {
                                queryClient.invalidateQueries([
                                  "detail-skill-and-courses",
                                  id,
                                ]);
                              },
                              onError: () => {
                                message.error(
                                  "Failed to update intro video. Please try again."
                                );
                              },
                            }
                          )
                        }
                      >
                        Replace
                      </Button>
                      |
                    </>
                  )}
                  <Button
                    type="text"
                    onClick={async () => {
                      await deleteIntroVideo.mutateAsync(
                        detailMyCourses?.id ?? "",
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries([
                              "detail-skill-and-courses",
                              id,
                            ]);
                          },
                          onError: () => {
                            message.error(
                              "Failed to delete intro video. Please try again."
                            );
                          },
                        }
                      );
                    }}
                  >
                    Remove
                  </Button>
                </div>
                <BitmovinPlayer
                  hls={undefined}
                  poster={undefined}
                  dash={detailMyCourses?.introduction_video?.dash ?? ""}
                />
              </>
            ) : (
              <VideoUploadV2
                className="!h-full"
                encodingInProgress={
                  detailMyCourses?.introduction_video?.status ===
                    "IN_PROGRESS" || state
                }
                azureContainerName={
                  process.env.REACT_APP_AZURE_VIDEO_STORAGE_CONTAINER_NAME ?? ""
                }
                fileName={detailMyCourses?.id}
                onSuccess={async () => {
                  await uploadIntroVideo.mutateAsync(
                    {
                      course_id: id ?? "",
                      video_file_name: detailMyCourses?.id ?? "",
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries([
                          "detail-skill-and-courses",
                          id,
                        ]);
                        setState(true);
                      },
                      onError: () => {
                        message.error(
                          "Failed to upload intro video. Please try again."
                        );
                      },
                    }
                  );
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailCourses_v3;
