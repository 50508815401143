import React from 'react'
import {  Tag } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import ModalConfirm from '../../../common/components/ModalConfirm.tsx'
import { ProgramCourseResponse, ProgramLesson, ProgramModule } from '../types/responseProgramCourse.ts'
import { initialEditorCourseProgramStoreState } from '../stores/storeProgramCourseEditor.ts'

interface CurriculumCardProps {
  course: ProgramCourseResponse
}

const CurriculumCard: React.FC<CurriculumCardProps> = ({ course }) => {

  const { openCollapseCourse, setOpenCollapseCourse } = initialEditorCourseProgramStoreState()


  // calculate total lessons and skills in course
  let totalLessons = 0
  let totalSkills = 0

  course?.modules?.forEach(module => {
    module?.lessons?.forEach(lesson => {
      if (lesson?.title !== '') {
        totalLessons += 1
      }
      if (lesson.title) {
        totalSkills += 1
      }
    })
  })

  return (
    <div key={course?.id} className="col-span-1 rounded-xl shadow-custom-sm border bg-white hover:border-primary">
      <div className='p-4 space-y-3'>
        <p className='!tracking-widest text-xs text-[#966F02]'>COURSE</p>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-x-4'>


            <Tag className='!bg-white !border-backdrop !text-black rounded-lg py-1 px-3'>{course?.modules?.length || 0} modules</Tag>
            <Tag className='!bg-white !border-backdrop !text-black rounded-lg py-1 px-3'>{totalLessons || 0} lessons</Tag>
            <Tag className='!bg-white !border-backdrop !text-black rounded-lg py-1 px-3'>{totalSkills || 0} skills</Tag>
          </div>


        </div>


        <div className='flex items-center justify-between gap-x-10'>
          <div className='flex items-center gap-x-3 w-full'>

            <Collapse
              title={
                <div className='py-2'>
                  {course?.modules?.length > 0 &&
                    <div className='flex items-center gap-x-3'>
                      <DownOutlined className={`${openCollapseCourse === course?.id ? "rotate-180" : "rotate-0"}  transition-all`} />
                      <p className='text-lg'>{course?.title}</p>
                    </div>
                  }
                </div>
              }
              id={course?.id!}
              isOpen={openCollapseCourse}
              setIsOpen={(id: string) => {
                if (openCollapseCourse === id) {
                  setOpenCollapseCourse("")
                } else {
                  setOpenCollapseCourse(id!)
                }
              }
              }
            >
              {course?.modules?.map((module: ProgramModule, indexModule: number) => {

                return (
                  <div className='space-y-3 my-4' key={indexModule}>
                    <div className='w-full rounded-xl shadow-custom-sm border bg-white p-4'>
                      <h4>{module?.introduction}</h4>

                      <div className="overflow-x-auto mt-4">
                        <table className="table-auto">
                          <tbody className="bg-white">
                            {module?.lessons?.map((lesson: ProgramLesson, indexLesson: number) => (
                              <tr key={lesson?.id}>
                                <td className="px-4 py-3.5">{indexLesson + 1}.</td>
                                <td className="px-4 py-3.5">
                                  {lesson?.title ?
                                    <span className='bg-primary-bg px-4 py-2 rounded-lg border-gray-200 border-spacing-52'>
                                      {lesson?.title}
                                    </span> : ""
                                  }
                                </td>
                                <td className="px-4 py-3.5">{lesson?.title}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Collapse>
          </div>
        </div>


      </div>
    </div >
  )
}

export default CurriculumCard


interface CollapseProps {
  children: React.ReactNode
  id: string
  title: React.ReactNode
  isOpen: string
  setIsOpen: (id: string) => void
}

function Collapse
  ({ children, id, title, isOpen, setIsOpen }: CollapseProps) {
  const toggle = () => setIsOpen(id)

  return (
    <div className="w-full">
      <button className="flex items-center justify-between w-full  text-left font-bold" >
        <p onClick={toggle}>
          {title}
        </p>


      </button>
      <div className={`!w-full overflow-hidden transition-all duration-300 px-2 ${isOpen !== id ? 'max-h-screen' : 'max-h-0'}`}>
        {children}
      </div>

      <ModalConfirm />
    </div>
  )
}
