import React from 'react'
import { CalenderIcon } from '../../../assets/icon'
import { Button, message, Tag, Upload } from 'antd'
import { RichContainerEditor } from '../components/ProgramCourseEditor/RichEditorCustom'
import { CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { initialEditorCourseProgramStoreState, initialFormCourseProgramStoreState } from '../stores/storeProgramCourseEditor'
import dayjs from 'dayjs'
import { BasicContainer } from '../components/CourseEditor/BasicContainer'
import { VideoUploadV2 } from '../../../common/components/VideoUploadV2'
import { BitmovinPlayer } from '../../../common/components'
import { ProgramDocument, ProgramLesson, ProgramModule } from '../types/responseProgramCourse'
import { useHookProgramCourseForm } from '../hooks/useProgramCourse'

interface ModuleEditorProps {
  moduleId: string
}

const ModuleEditor: React.FC<ModuleEditorProps> = ({ moduleId }) => {
  const {
    handleChangeModuleDescription,
    handleChangeModuleLessonMaterial,
    deleteLessonVideo,
    encodeLessonVideo,
    beforeFileUpload,
    handleFileUpload,
    removePdfFile,
    removePdf,
    videoStatusUpdateAfterUpload,
    truncateMiddle,
    isLoading,
  } = useHookProgramCourseForm(moduleId)

  const { programDetailData } = initialEditorCourseProgramStoreState()
  const { formData } = initialFormCourseProgramStoreState()

  const moduleData = formData?.modules.find((module: ProgramModule) => module.id === moduleId)

  return (
    <div className='p-4 bg-white space-y-7'>
      <Tag className='bg-secondaryYellow !text-brown px-2 py-1  border-none'>
        <div className='flex items-center gap-x-3'>
          <CalenderIcon className='!text-brown' />
          <p>Release Data: {dayjs(programDetailData?.release_date).format('DD MMM YYYY')}</p>
        </div>
      </Tag>

      <div className=''>
        <RichContainerEditor
          title="Description"
          placeholder="Write the description"
          showError={true}
          value={moduleData?.description}
          onChange={(e) => handleChangeModuleDescription(moduleId, e)}
        />
      </div>

      <div className='py-2 px-4 bg-secondaryBlue shadow-custom-sm border rounded-xl'>
        <h3 className='font-extrabold' style={{ fontWeight: 1900 }}>Lessons</h3>
      </div>

      {moduleData?.lessons.map((lesson: ProgramLesson, lessonIndex:
        number) => {
        const handleDeleteVideo = async () => {
          message.open({
            key: "delete-video-program",
            content: "Loading...",
            type: "loading",
          })
          if (lesson?.id && moduleId) {
            await deleteLessonVideo.mutateAsync(
              {
                id: lesson?.id,
                blob_name: lesson?.id,
              },
              {
                onSuccess: () => {
                  videoStatusUpdateAfterUpload(lesson?.id, false, true)
                },
                onError: () => {
                  videoStatusUpdateAfterUpload(lesson?.id, true, false)
                },
              }
            )
          }
        }

        return (
          <div key={lessonIndex}>
            <div className='py-2 px-4 bg-[#D9D9D9] shadow-custom-sm border rounded-xl'>
              <h3 className='font-extrabold'>{lesson?.title}</h3>
            </div>
            <div className='flex gap-4 p-4 w-full'>
              <div className='w-full space-y-3'>
                <BasicContainer className='h-full p-6'>
                  {lesson?.media?.status === "FINISHED" ? (
                    <div className='h-[87%] mb-3'>
                      <BitmovinPlayer
                        dash={lesson?.media?.dash ?? ""}
                        hls={undefined}
                        poster={undefined}
                      />
                    </div>
                  ) : (
                    <div className='!h-full'>
                      <VideoUploadV2
                        azureContainerName={
                          process.env.REACT_APP_AZURE_VIDEO_STORAGE_CONTAINER_NAME ?? ""
                        }
                        encodingInProgress={lesson?.media?.status === "IN_PROGRESS"}
                        fileName={lesson?.id}
                        onSuccess={async () => {
                          message.open({
                            key: "upload-video-program",
                            content: "Loading...",
                            type: "loading",
                          })
                          await encodeLessonVideo.mutateAsync(
                            {
                              lesson_id: lesson?.id,
                              module_id: moduleId,
                              video_file_name: lesson?.id,
                            },
                            {
                              onSuccess: () => {
                                videoStatusUpdateAfterUpload(lesson?.id, false, false)
                              },
                              onError: () => {
                                videoStatusUpdateAfterUpload(lesson?.id, true, false)
                              },
                            }
                          )
                        }}
                      />
                    </div>
                  )}
                  {lesson?.media?.status === "FINISHED" && (
                    <Button
                      type='text'
                      className=''
                      onClick={handleDeleteVideo}
                    >
                      Delete Video
                    </Button>
                  )}
                </BasicContainer>
              </div>

              <div className='w-full'>
                <RichContainerEditor
                  title="Description"
                  placeholder="Write the description"
                  showError={true}
                  value={lesson.material}
                  onChange={(e) => handleChangeModuleLessonMaterial(moduleId, lesson?.id, e)}
                  className=''
                  children={
                    <div className='space-y-4'>
                      <div className='flex items-center justify-between'>
                        <h5>Lesson Resources</h5>
                        <Upload
                          beforeUpload={(file) => beforeFileUpload(file)}
                          showUploadList={false}
                          customRequest={(options) => handleFileUpload(options?.file, lesson?.id)}>
                          <Button
                            disabled={lesson?.documents && lesson?.documents?.length === 3}
                            loading={isLoading}
                            icon={<UploadOutlined />}>
                            Attach Pdf
                          </Button>
                        </Upload>
                      </div>

                      {lesson?.documents?.map((doc: ProgramDocument, index: number) => {
                        const fileName = doc?.file_name || ''
                        const truncatedFileName = truncateMiddle(fileName, 20, 20, 30)

                        return (
                          <div key={index} className='flex items-center justify-between'>
                            <p className='truncate'>{truncatedFileName}</p>
                            <Button
                              type='text'
                              icon={<CloseOutlined />}
                              loading={removePdf.isLoading}
                              onClick={() => removePdfFile(lesson?.id, doc?.name, doc?.url)}
                              className=''
                            />
                          </div>
                        )
                      })}
                    </div>
                  }

                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ModuleEditor
