import React from "react";
import { Button, Divider, Progress, Select, Skeleton, Tooltip } from "antd";
import {
  BookIcon,
  LampIcon,
  MoonIcon,
  StarIcon,
  TeamIcon,
} from "../../../assets/icon";
import { useNavigate } from "react-router-dom";
import { useSchoolDashboard } from "../hook/useSchoolDashboard.ts";
import { useSchool } from "../../onboarding/hooks/useSchool.js";
import { ROUTES } from "../pages/SchoolDashboard.tsx";
import { TeamOutlined } from "@ant-design/icons";
import { formatNumberWithThousandSeparator } from "../../../utils/fotmatNumber.ts";
import { KeyParams, useAnalytic } from "../hook/useAnalytic.ts";

interface OverviewContentProps {}

const OverviewContent: React.FC<OverviewContentProps> = () => {
  const navigate = useNavigate();

  const { schoolDashboardStat, schoolDashboardStatLoading } =
    useSchoolDashboard();

  const { certificateData } = useSchool();

  const analytic = useAnalytic();
  const feelOptions: Record<number, string> = {
    1: "😴",
    2: "😕",
    3: "😕",
    4: "🥰",
    5: "🔥",
  };

  return (
    <div className="my-12 space-y-6">
      <div className="grid grid-cols-10 gap-x-7">
        <div
          className="col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16"
          onClick={(e) => navigate("/students")}
        >
          <TeamIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? (
              <Skeleton.Button size="small" className="!w-full" active />
            ) : (
              <h2 className="text-2xl">
                {schoolDashboardStat?.total_learners}
              </h2>
            )}
            <p>Students</p>
          </div>
        </div>
        <div
          className="col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16"
          onClick={(e) => navigate("/trainer-admin/instructors")}
        >
          <TeamIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? (
              <Skeleton.Button size="small" className="!w-full" active />
            ) : (
              <h2 className="text-2xl">
                {schoolDashboardStat?.total_trainers}
              </h2>
            )}
            <p>Instructors</p>
          </div>
        </div>
        <div
          className="col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16"
          onClick={(e) => navigate(ROUTES.GREEN_SKILLS)}
        >
          <LampIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? (
              <Skeleton.Button size="small" className="!w-full" active />
            ) : (
              <h2 className="text-2xl">{schoolDashboardStat?.total_courses}</h2>
            )}
            <p>Green Skills</p>
          </div>
        </div>
        <div
          className="col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16"
          onClick={(e) => navigate(ROUTES.WORKSHOPS)}
        >
          <StarIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? (
              <Skeleton.Button size="small" className="!w-full" active />
            ) : (
              <h2 className="text-2xl">
                {schoolDashboardStat?.total_workshops}
              </h2>
            )}
            <p>Workshops</p>
          </div>
        </div>
        <div
          className="col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16"
          onClick={(e) => navigate(ROUTES.PROGRAMS)}
        >
          <BookIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? (
              <Skeleton.Button size="small" className="!w-full" active />
            ) : (
              <h2 className="text-2xl">
                {schoolDashboardStat?.total_programs}
              </h2>
            )}
            <p>Programs</p>
          </div>
        </div>
      </div>
      <div className="shadow-custom-sm bg-white p-3 rounded-lg border">
        <div className="flex items-center justify-between space-y-4">
          <h3>Certificates of Completion</h3>
          <Button
            type="primary"
            className="bg-primary"
            onClick={() =>
              navigate("/trainer-admin/school-dashboard/overview/certif-editor")
            }
          >
            Settings
          </Button>
        </div>
        {certificateData?.data?.data?.owner_certificate &&
        certificateData?.data?.data?.owner_certificate_id ? (
          <p className="text-green-500">Active</p>
        ) : (
          <p className="text-red-500">Not Configured</p>
        )}
        <div className="flex items-center gap-x-2 leading-9">
          Applied to:
          <p>
            {schoolDashboardStat?.total_courses_issuing_certificate || "0"}{" "}
            Green Skills
          </p>
          <Divider type="vertical" />
          <p>
            {schoolDashboardStat?.total_programs_issuing_certificate || "0"}{" "}
            Programs
          </p>
        </div>
      </div>
      <div className="shadow-custom-sm bg-white p-3 rounded-lg border">
        <div className="flex justify-between mb-5">
          <h3>Analytics</h3>
          <div className="flex gap-5">
            <Select
              value={analytic.params.content}
              options={[
                { value: "all", label: "All Courses" },
                { value: "courses", label: "Green Skills" },
                { value: "workshops", label: "Workshops" },
                { value: "programs", label: "Programs" },
              ]}
              onSelect={(e) =>
                analytic.handleChangeParams(KeyParams.CONTENT, e)
              }
            />
            <Button
              onClick={() => {
                navigate(`analytic?content=${analytic.params.content}`);
              }}
            >
              View more
            </Button>
          </div>
        </div>
        {analytic.isLoading ? (
          <Skeleton paragraph={{ rows: 9 }} title={false} />
        ) : analytic.data ? (
          <div className=" grid grid-cols-8 gap-3">
            <div
              className=" rounded px-5 py-3 col-span-3 bg-[#FDFDFD]"
              style={{ border: "1px solid #D9D9D9" }}
            >
              <div className=" font-semibold mb-5">Learner Activity</div>
              <div className="flex gap-10">
                <Progress
                  percent={
                    Math.floor(
                      ((analytic.data.learner_activities.completed +
                        analytic.data.learner_activities.in_progress) /
                        analytic.data.learner_activities.total) *
                        100 *
                        100
                    ) / 100
                  }
                  success={{
                    percent:
                      (analytic.data.learner_activities.completed /
                        analytic.data.learner_activities.total) *
                      100,
                  }}
                  type="circle"
                  strokeColor="#ff4d4f"
                  size={[190, 20]}
                  format={(percent) => (
                    <div className=" text-base">
                      <strong>{`${percent}%`}</strong> <br />
                      Completed
                    </div>
                  )}
                />
                <div className=" flex flex-col justify-between">
                  <div
                    className="px-3"
                    style={{ borderLeft: "2px solid #86C769" }}
                  >
                    <div>{analytic.data.learner_activities.completed}</div>
                    <div>Completed</div>
                  </div>
                  <div
                    className="px-3"
                    style={{ borderLeft: "2px solid #EF7574" }}
                  >
                    <div>{analytic.data.learner_activities.in_progress}</div>
                    <div>In Progress</div>
                  </div>
                  <div
                    className="px-3"
                    style={{ borderLeft: "2px solid #E2EBFA" }}
                  >
                    <div>{analytic.data.learner_activities.not_started}</div>
                    <div>Not Started</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className=" grid grid-cols-2 gap-3 mb-3">
                <div
                  className=" rounded p-3 bg-[#FDFDFD]"
                  style={{ border: "1px solid #D9D9D9" }}
                >
                  <div className=" flex gap-3">
                    <TeamOutlined />
                    <div>Enrolled</div>
                  </div>
                  <div className=" text-2xl font-semibold">
                    {formatNumberWithThousandSeparator(
                      analytic.data.enrolled.total
                    )}
                  </div>
                </div>
                <div
                  className=" rounded p-3 bg-[#FDFDFD]"
                  style={{ border: "1px solid #D9D9D9" }}
                >
                  {analytic?.data?.nps?.total_responded > 0 ? (
                    <div>
                      <div>
                        <div>NPS :</div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <div>NPS :</div>
                      </div>
                    </div>
                  )}
                  {analytic?.data?.nps?.total_responded > 0 ? (
                    <div className="flex gap-1 items-end">
                      <div className=" text-2xl font-semibold">
                        {analytic?.data?.nps?.total_responded > 0
                          ? Math.round(analytic?.data?.nps?.score.score)
                          : "-"}
                      </div>
                      <div className=" leading-7 capitalize">
                        {`(${analytic?.data?.nps?.score?.status || "Unknown"})`}
                      </div>
                    </div>
                  ) : (
                    <div className=" text-2xl font-semibold">-</div>
                  )}
                </div>
              </div>
              <div
                className="p-3 rounded bg-[#FDFDFD]"
                style={{ border: "1px solid #D9D9D9" }}
              >
                <div>Average Feeling</div>
                {analytic?.data?.nps?.total_responded > 0 ? (
                  <div className=" flex gap-3 items-center justify-center my-3">
                    <div className=" text-4xl font-semibold">
                      {feelOptions[analytic.data?.nps?.avg_rating_feel || 1] ||
                        "-"}
                    </div>

                    <div className="">
                      <div className=" text-3xl">
                        {analytic?.data?.nps?.total_responded || "0"}
                      </div>
                      <div>responses</div>
                    </div>
                  </div>
                ) : (
                  <div className=" flex flex-col gap-3 items-center my-3">
                    <MoonIcon />
                    <div className="mt-2">No ratings yet</div>
                  </div>
                )}
                {analytic?.data?.nps?.total_responded > 0 && (
                  <div className="mt-5 flex justify-around">
                    {analytic.data.learner_feelings.map((item, i) => (
                      <Tooltip
                        placement="top"
                        title={item.response}
                        key={i}
                        overlayInnerStyle={{ textAlign: "center" }}
                      >
                        <div
                          className={`text-4xl font-semibold ${
                            feelOptions[
                              analytic.data?.nps?.avg_rating_feel || 1
                            ] === item.emoticon
                              ? ""
                              : "opacity-40"
                          }`}
                        >
                          {item.emoticon}
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className=" rounded px-3 py-3 col-span-2 bg-[#FDFDFD]"
              style={{ border: "1px solid #D9D9D9" }}
            >
              <div className=" font-semibold mb-3">Country</div>
              {analytic.country.total === 0 ? (
                <div className=" flex flex-col gap-5 items-center mt-10">
                  <MoonIcon />
                  <div>No data</div>
                </div>
              ) : (
                <>
                  {analytic.country.country?.map((item, i) => {
                    return (
                      <div
                        className="py-1 px-1.5 mb-1.5 rounded bg-[#FDFDFD] text-xs"
                        style={{ border: "1px solid #D9D9D9" }}
                        key={i}
                      >
                        <div className="mb-1">{item.name}</div>
                        <div className="flex gap-3 items-center">
                          <Progress
                            style={{ margin: 0 }}
                            percent={
                              (item.total / analytic.country.total) * 100
                            }
                            size={[130, 25]}
                            showInfo={false}
                            strokeColor={"#86C769"}
                          />
                          <div className=" w-full">
                            {`${item.total} learners`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="mt-2 flex gap-9">
                    <div>Other countries</div>
                    <div>{`${analytic.country.other_country} learners`}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className=" flex flex-col gap-5 items-center my-10">
            <MoonIcon />
            <div>No data</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewContent;
