import React from 'react'
import { CalenderIcon } from '../../../assets/icon/index.js'
import { Avatar, Button, message, Popover, Tag } from 'antd'
import { DeleteOutlined, DownOutlined, EditOutlined, InfoCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { initialProgramStoreState } from '../store/programStore.ts'
import { Course, Lesson, Module } from '../types/programResponse.ts'
import { useDeleteCourse } from '../hook/useProgram.ts'
import dayjs from 'dayjs'
import FormAddCourse from './FormAddCourse.tsx'
import ModalConfirm from '../../../common/components/ModalConfirm.tsx'

interface CourseCardProps {
  course: Course
}

const CourseCard: React.FC<CourseCardProps> = ({ course }) => {

  function getStatusColor(status: string) {
    switch (status) {
      case 'draft':
        return 'bg-gray-300 text-gray-800'
      case 'in_progress':
        return 'bg-yellow-300 text-yellow-800'
      case 'pre_launch':
        return 'bg-blue-300 text-blue-800'
      case 'live':
        return 'bg-green-300 text-green-800'
      default:
        return 'bg-gray-200 text-gray-600'
    }
  }

  const rewriteWords = (word: string): string => {
    return word
      .split('_')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ')
  }

  const { openCollapseCourse, setOpenCollapseCourse, isEditCourse } = initialProgramStoreState()


  // calculate total lessons and skills in course
  let totalLessons = 0
  let totalSkills = 0

  course?.modules?.forEach(module => {
    module?.lessons?.forEach(lesson => {
      if (lesson?.title !== '') {
        totalLessons += 1
      }
      if (lesson.program_skill) {
        totalSkills += 1
      }
    })
  })

  return (
    isEditCourse === course?.id ?
      <FormAddCourse />
      :
      <div key={course?.id} className="col-span-1 rounded-xl shadow-custom-sm border bg-white hover:border-primary">
        <div className='p-4 space-y-3'>
          <p className='!tracking-widest text-xs text-[#966F02]'>COURSE</p>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-x-4'>
              <CalenderIcon />
              <p className={` ${course?.release_date ? 'text-backdrop' : 'text-red-400'}`}>{course?.release_date ? dayjs(course?.release_date).format("DD MMM YYYY") : "release date not set"}</p>
              <Tag className='!bg-white !border-backdrop !text-black rounded-lg py-1 px-3'>{course?.modules?.length || 0} modules</Tag>
              <Tag className='!bg-white !border-backdrop !text-black rounded-lg py-1 px-3'>{totalLessons || 0} lessons</Tag>
              <Tag className='!bg-white !border-backdrop !text-black rounded-lg py-1 px-3'>{totalSkills || 0} skills</Tag>
            </div>

            <div className='flex items-center gap-x-10'>
              <div className='flex items-center gap-x-3'>
                <InfoCircleOutlined className={`${course?.trainer ? "hidden" : "text-red-400"} `} />
                <Avatar src={course?.trainer?.avatar} className={`border border-primary ${course?.trainer ? "" : "hidden"} `} />
                <p className={`${course?.trainer ? "text-backdrop" : "text-red-400"} `}>{course?.trainer?.display_name ? course?.trainer?.first_name + " " + course?.trainer?.last_name : "instructor not assigned"}</p>
              </div>
              <div>
                <Button type="default" className={`${getStatusColor(course?.status!)} rounded-lg border-none px-7`}>
                  {rewriteWords(course?.status!)}
                </Button>
              </div>
            </div>
          </div>


          <div className='flex items-center justify-between gap-x-10'>
            <div className='flex items-center gap-x-3 w-full'>

              <Collapse
                title={
                  <div className='py-2'>
                    {course?.modules?.length > 0 &&
                      <div className='flex items-center gap-x-3'>
                        <DownOutlined className={`${openCollapseCourse === course?.id ? "rotate-180" : "rotate-0"}  transition-all`} />
                        <p className='text-lg'>{course?.title}</p>
                      </div>
                    }
                  </div>
                }
                id={course?.id!}
                isOpen={openCollapseCourse}
                setIsOpen={(id: string) => {
                  if (openCollapseCourse === id) {
                    setOpenCollapseCourse("")
                  } else {
                    setOpenCollapseCourse(id!)
                  }
                }
                }
              >
                {course?.modules?.map((module: Module, indexModule: number) => {

                  return (
                    <div className='space-y-3 my-4' key={indexModule}>
                      <div className='w-full rounded-xl shadow-custom-sm border bg-white p-4'>
                        <h4>{module?.introduction}</h4>

                        <div className="overflow-x-auto mt-4">
                          <table className="table-auto">
                            <tbody className="bg-white">
                              {module?.lessons?.map((lesson: Lesson, indexLesson: number) => (
                                <tr key={lesson?.id}>
                                  <td className="px-4 py-3.5">{indexLesson + 1}.</td>
                                  <td className="px-4 py-3.5">
                                    {lesson?.program_skill?.name ?
                                      <span className='bg-primary-bg px-4 py-2 rounded-lg border-gray-200 border-spacing-52'>
                                        {lesson?.program_skill?.name}
                                      </span> : ""
                                    }
                                  </td>
                                  <td className="px-4 py-3.5">{lesson?.title}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Collapse>
            </div>
          </div>


        </div>
      </div >
  )
}

export default CourseCard


interface CollapseProps {
  children: React.ReactNode
  id: string
  title: React.ReactNode
  isOpen: string
  setIsOpen: (id: string) => void
}

function Collapse
  ({ children, id, title, isOpen, setIsOpen }: CollapseProps) {
  const toggle = () => setIsOpen(id)
  const { showModal } = useDeleteCourse()
  const { setIsEditCourse, isEditCourse } = initialProgramStoreState()

  return (
    <div className="w-full">
      <button className="flex items-center justify-between w-full  text-left font-bold" >
        <p onClick={toggle}>
          {title}
        </p>

        <Popover placement="leftBottom" content={
          <div className='flex flex-col'>
            <Button type="text" className="flex items-center gap-x-3" onClick={() => {
              if (isEditCourse !== "") {
                message.warning("Please save or cancel the current course edit before editing another course")
              } else {
                setIsEditCourse(id)
              }
            }}>
              <EditOutlined />
              Edit
            </Button>
            <Button type="text" className="flex items-center gap-x-3" onClick={() => showModal(id)}>
              <DeleteOutlined />
              Delete
            </Button>
          </div>
        }>
          <Button type="default" className="border-primary"><MoreOutlined className='rotate-90' /></Button>
        </Popover>
      </button>
      <div className={`!w-full overflow-hidden transition-all duration-300 px-2 ${isOpen === id ? 'max-h-screen' : 'max-h-0'}`}>
        {children}
      </div>

      <ModalConfirm />
    </div>
  )
}
