export const LIST_PROGRAM_TRAINER_QUERY_NAME: string = 'list_program_trainer'
export const DETAIL_PROGRAM_TRAINER_QUERY_NAME: string = 'detail_program_trainer'

export interface QueryParams {
  page: number
  limit: number
  total: number
  search: string
}


export interface ProgramCourseResponse {
  id: string
  title: string
  release_date: string
  program_id: string
  trainer_id: string
  school_id: string
  created_at: string
  updated_at: string
  modules: ProgramModule[]
  status: string
  description: string
  assessments: ProgramAssessment[]
  is_issuing_email: boolean
}

export interface ProgramModule {
  id: string
  introduction?: string
  trainer_id?: string
  school_id?: string
  program_course_id?: string
  program_id?: string
  created_at?: string
  updated_at?: string
  lessons: ProgramLesson[]
  description: string
  recap_previous_module: string
}

export interface ProgramLesson {
  id: string
  title?: string
  trainer_id?: string
  school_id?: string
  program_course_id?: string
  program_module_id?: string
  program_skill_id?: string
  program_id?: string
  created_at?: string
  updated_at?: string
  documents: ProgramDocument[] | []
  material: string
  media?: ProgramMedia
}

export interface ProgramMedia {
  status: string
  dash: string
  bitmovin_encoding_id?: string // Allow undefined here
  blob_folder_path?: string
  durations?: number
}

export interface ProgramDocument {
  url: string
  name: string
  file_name: string
  created_at: string
}

export interface ProgramAssessment {
  id: string
  program_course_id?: string
  question: string
  answers: ProgramAnswer[]
  order?: number
  timer: number | null
}

export interface ProgramAnswer {
  id: string
  answer: string
  is_correct_answer: boolean
}


export interface ProgramCourseFormData {
  id: string
  assessments: ProgramAssessment[] | null // Pastikan 'assessments' dideklarasikan di sini
  description: string
  modules: ProgramModule[]
}

export interface ContentMenuItem {
  label: string
  key: string
  is_filled: boolean
}
