import { PlusCircleFilled } from "@ant-design/icons"
import { Button, Empty, Skeleton } from "antd"
import React from "react"
import CourseCard from "./CourseCard.tsx"
import { initialProgramStoreState } from "../store/programStore.ts"
import FormAddCourse from "./FormAddCourse.tsx"
import { Course } from "../types/programResponse.ts"
import { useNavigate } from "react-router-dom"

interface ProgramDetailContentProps { }

const ProgramDetailContent: React.FC<ProgramDetailContentProps> = () => {
  const navigate = useNavigate()
  const {
    openFormAddCourse,
    setOpenFormAddCourse,
    programDetailData,
    loadingDetailPorgram,
    isEditCourse,
  } = initialProgramStoreState()

  return (
    <div className="">
      <h3 className="text-2xl">Program Curriculum</h3>

      <div className="mt-10 flex items-center gap-x-2">
        <Button
          type="primary"
          className="mt-4 bg-primary rounded-md flex items-center"
          disabled={
            openFormAddCourse || loadingDetailPorgram || isEditCourse !== ""
          }
          onClick={() => setOpenFormAddCourse(true)}
        >
          <PlusCircleFilled />
          Add Course
        </Button>
        <Button
          type="default"
          className="mt-4 bg-white rounded-md flex items-center"
          disabled={
            openFormAddCourse || loadingDetailPorgram || isEditCourse !== ""
          }
          onClick={() => navigate(`/trainer-admin/school-dashboard/programs/survey-config/${programDetailData?.id}`)}
        >
          Pre & Post Survey
        </Button>
      </div>

      <div className="mt-7">
        {openFormAddCourse && (
          <div>
            <FormAddCourse />
          </div>
        )}

        {loadingDetailPorgram ? (
          Array.from({ length: 4 }, (_: any, index: number) => (
            <div
              key={index}
              className="col-span-1 rounded-xl shadow-custom-sm border bg-white mb-4"
            >
              <div className="p-4 space-y-3">
                <Skeleton active />
              </div>
            </div>
          ))
        ) : programDetailData?.courses?.length === 0 ? (
          <div className="bg-white p-6 rounded-lg">
            <Empty description="Create your first course" />
          </div>
        ) : (
          programDetailData?.courses?.map((course: Course, index: number) => (
            <div className="my-4" key={index}>
              <CourseCard course={course} />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default ProgramDetailContent
