import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Empty, Spin, Tag } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";
import { Workshop } from "../hooks/useWorkshop";
import dayjs from "dayjs";

interface Props {
  data: Workshop[];
  isLoading: boolean;
  total: number;
  setParams: React.Dispatch<
    React.SetStateAction<{
      page: number;
      title: string;
      limit: number;
    }>
  >;
}

const TableWorkshops: React.FC<Props> = ({
  isLoading,
  data,
  total,
  setParams,
}) => {
  const navigate = useNavigate();

  return (
    <table className="w-full mt-3">
      <thead className="grid grid-cols-12 mb-3">
        <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-4">
          Skill Name
        </td>
        <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
          Date
        </td>
        <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-5">
          Status
        </td>
      </thead>
      <Spin spinning={isLoading}>
        {data.length > 0 ? (
          <>
            {data?.map((item, index) => (
              <tbody className="grid grid-cols-12" key={index}>
                <td className="font-medium  border-gray-300 py-2 px-5 text-backdrop col-span-4 flex items-center">
                  {item?.title}
                </td>
                <td className="font-medium  border-gray-300 py-2 px-5 text-backdrop col-span-3 flex items-center">
                  {item?.release_date
                    ? dayjs(item.release_date).format("DD MMM YYYY")
                    : item.mode}
                </td>

                <td className="font-medium  border-gray-300 py-2 px-5 text-backdrop col-span-5">
                  <div className="flex items-center justify-between">
                    <Tag
                      color={`${
                        item.status === "available"
                          ? "green"
                          : item.status === "scheduled"
                          ? "blue"
                          : "orange"
                      }`}
                      className=" first-letter:capitalize py-0.5 px-3"
                    >
                      {item.status}
                    </Tag>
                    <Button
                      onClick={() =>
                        navigate(`/programs-and-workshops/${item?.id}`)
                      }
                      className="border-primary"
                    >
                      Dashboard
                    </Button>
                  </div>
                </td>
              </tbody>
            ))}
            {total > data.length && (
              <div className=" text-center mt-3">
                <ArrowDownOutlined
                  className=" cursor-pointer"
                  onClick={() =>
                    setParams((prev) => ({ ...prev, page: prev.page + 1 }))
                  }
                />
              </div>
            )}
          </>
        ) : (
          <div className="py-4 border text-center ">
            <Empty />
          </div>
        )}
      </Spin>
    </table>
  );
};

export default TableWorkshops;
